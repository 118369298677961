import { createSlice } from "@reduxjs/toolkit";
import {
    getLabRemarks, getLabTestRemark, getAllLabRemarks, createLabTestRemark, updateLabTestRemark, deleteLabTestRemark,
    getLabSamples, getAllLabSamples, getLabTestSample, createLabTestSample, updateLabTestSample, deleteLabTestSample,
    getLabRecords, getLabRecord, createLabTests
} from "./thunk"


export const initialState = {
    labRemarks: [],
    allLabRemarks: [],
    labTestRemark: {},
    labSamples: [],
    allLabSample: [],
    labTestSample: {},
    labRecords: [],
    labRecord: {},
    error: null,
    success: null,
}


const LaboratorySlice = createSlice({
    name: 'laboratory',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        /*
        * labRemarks
        */
        builder.addCase(getLabRemarks.fulfilled, (state, action) => {
            state.labRemarks = action.payload.data
            state.createLabTestSuccess = false
            state.updateLabTestSuccess = false
            state.deleteLabTestSuccess = false
            state.error = null
        })
        builder.addCase(getLabRemarks.rejected, (state, action) => {
            state.labRemarks = []
            state.error = action.error.message || null
        })
        // Get all
        builder.addCase(getAllLabRemarks.fulfilled, (state, action) => {
            state.allLabRemarks = action.payload.data
            state.createLabTestSuccess = false
            state.updateLabTestSuccess = false
            state.deleteLabTestSuccess = false
            state.error = null
        })
        builder.addCase(getAllLabRemarks.rejected, (state, action) => {
            state.allLabRemarks = []
            state.error = action.error.message || null
        })

        // Get single
        builder.addCase(getLabTestRemark.fulfilled, (state, action) => {
            state.labTestRemark = action.payload.data
            state.error = null
        })
        builder.addCase(getLabTestRemark.rejected, (state, action) => {
            state.labTestRemark = {}
            state.error = action.error.message || null
        })

        // Create
        builder.addCase(createLabTestRemark.fulfilled, (state, action) => {
            state.labTestRemark = action.payload.data
            state.createLabTestSuccess = true
            state.error = null
        })
        builder.addCase(createLabTestRemark.rejected, (state, action) => {
            state.labTestRemark = {}
            state.createLabTestSuccess = false
            state.error = action.error.message || null
        })

        // Update
        builder.addCase(updateLabTestRemark.fulfilled, (state, action) => {
            state.labTestRemark = action.payload.data
            state.updateLabTestSuccess = true
            state.error = null
        })
        builder.addCase(updateLabTestRemark.rejected, (state, action) => {
            state.labTestRemark = {}
            state.updateLabTestSuccess = false
            state.error = action.error.message || null
        })

        // Delete
        builder.addCase(deleteLabTestRemark.fulfilled, (state, action) => {
            state.labTestRemark = action.payload.data
            state.deleteLabTestSuccess = true
            state.error = null
        })
        builder.addCase(deleteLabTestRemark.rejected, (state, action) => {
            state.labTestRemark = {}
            state.deleteLabTestSuccess = false
            state.error = action.error.message || null
        })

        /*
        * labSamples
        */
        builder.addCase(getLabSamples.fulfilled, (state, action) => {
            state.labSamples = action.payload.data
            state.createLabTestSuccess = false
            state.updateLabTestSuccess = false
            state.deleteLabTestSuccess = false
            state.error = null
        })
        builder.addCase(getLabSamples.rejected, (state, action) => {
            state.labSamples = []
            state.error = action.error.message || null
        })

        builder.addCase(getAllLabSamples.fulfilled, (state, action) => {
            state.allLabSample = action.payload.data
            state.createLabTestSuccess = false
            state.updateLabTestSuccess = false
            state.deleteLabTestSuccess = false
            state.error = null
        })
        builder.addCase(getAllLabSamples.rejected, (state, action) => {
            state.allLabSample = []
            state.error = action.error.message || null
        })

        // Get single
        builder.addCase(getLabTestSample.fulfilled, (state, action) => {
            state.labTestSample = action.payload.data
            state.error = null
        })
        builder.addCase(getLabTestSample.rejected, (state, action) => {
            state.labTestSample = {}
            state.error = action.error.message || null
        })

        // Create
        builder.addCase(createLabTestSample.fulfilled, (state, action) => {
            state.labTestSample = action.payload.data
            state.createLabTestSampleSuccess = true
            state.error = null
        })
        builder.addCase(createLabTestSample.rejected, (state, action) => {
            state.labTestSample = {}
            state.createLabTestSampleSuccess = false
            state.error = action.error.message || null
        })

        // Update
        builder.addCase(updateLabTestSample.fulfilled, (state, action) => {
            state.labTestSample = action.payload.data
            state.updateLabTestSampleSuccess = true
            state.error = null
        })
        builder.addCase(updateLabTestSample.rejected, (state, action) => {
            state.labTestSample = {}
            state.updateLabTestSampleSuccess = false
            state.error = action.error.message || null
        })

        // Delete
        builder.addCase(deleteLabTestSample.fulfilled, (state, action) => {
            state.labTestSample = action.payload.data
            state.deleteLabTestSampleSuccess = true
            state.error = null
        })
        builder.addCase(deleteLabTestSample.rejected, (state, action) => {
            state.labTestSample = {}
            state.deleteLabTestSampleSuccess = false
            state.error = action.error.message || null
        })

        /*
        * lab records
        */
        builder.addCase(getLabRecords.fulfilled, (state, action) => {
            state.labRecords = action.payload.data
            state.labRecord = []
            state.createLabTestSuccess = false
            state.updateLabTestSuccess = false
            state.deleteLabTestSuccess = false
            state.error = null
        })
        builder.addCase(getLabRecords.rejected, (state, action) => {
            state.labRecords = []
            state.error = action.error.message || null
        })
        // Get single
        builder.addCase(getLabRecord.fulfilled, (state, action) => {
            state.labRecord = action.payload.data
            state.error = null
        })
        builder.addCase(getLabRecord.rejected, (state, action) => {
            state.labRecord = {}
            state.error = action.error.message || null
        })
        // Create
        builder.addCase(createLabTests.fulfilled, (state, action) => {
            state.labRecord = action.payload.data
            state.createLabTestSuccess = true
            state.error = null
        })
        builder.addCase(createLabTests.rejected, (state, action) => {
            state.labRecord = {}
            state.createLabTestSuccess = false
            state.error = action.error.message || null
        })

    }
})

export default LaboratorySlice.reducer;