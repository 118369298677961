import React from 'react'
import './i18n'
import App from './App'
import rootReducer from './slice'
import { Provider } from "react-redux"
import ReactDOM from 'react-dom/client'
import storage from 'redux-persist/lib/storage'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root'))
const store = configureStore({ reducer: rootReducer, devTools: true})
// const persistConfig = { key: 'root', storage } // Add any other configuration options here
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// export const store = configureStore({ reducer: persistedReducer, devTools: true });

// export const persistor = persistStore(store);


root.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </React.Fragment>
    {/* </PersistGate> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
