import React, { useEffect, useState } from 'react'
import withRouter from '../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import Select from "react-select"
import makeAnimated from "react-select/animated"


function TestsRow(props) {
    const animatedComponents = makeAnimated()
    const [ selectedRemark, setSelectedRemark ] = useState(
        props.labRemarkOptions.find((element) => element.value === props.row.testRemark) || null
    )
    const [ selectedSample, setSelectedSample ] = useState(
        props.labSampleOptions.find((element) => element.value === props.row.sampleType) || null
    )
    const [name, setName] = useState(props.row.productName || '')
    const [range, setRange] = useState(props.row.testRange || '')
    const [result, setResult] = useState(props.row.testResults || '')

    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value === '0' || e.target.value <= 1) {
            setValue(1);
        }
    };

    useEffect(() => {
        let row = {
            id: props.row.id ? props.row.id : null,
            productName: name,
            sampleType: selectedSample && selectedSample.value || null,
            testRemark: selectedRemark && selectedRemark.value || null,
            testResults: result,
            testRange: range
        }

        props.updateRow(row, props.index);
    },[selectedSample, selectedRemark, range, result ])
    

    // const handleSave = () => {
    //     let row = {
    //         id: props.row.id ? props.row.id : null,
    //         productName: name,
    //         sampleType: selectedSample && selectedSample.value || null,
    //         testRemark: selectedRemark && selectedRemark.value || null,
    //         testResults: result,
    //         testRange: range
    //     }

    //     props.updateRow(row, props.index);
    // }


  return (
    < >
        <td>
            {name}
        </td>
        <td>
            <Select
                name='status'
                id='status-field'
                value={selectedSample}
                isMulti={false}
                isClearable={false}
                onChange={(e) => {
                    setSelectedSample(e);
                }}
                options={props.labSampleOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
            />
        </td>
        <td>
            <textarea
                className="form-control"
                rows={3}
                placeholder={props.t("type_here")}
                value={result}
                onChange={(e) => {setResult(e.target.value) }}
            />
        </td>
        <td>
            <textarea
                className="form-control"
                rows={3}
                placeholder={props.t("type_here")}
                value={range}
                onChange={(e) => {setRange(e.target.value) }}
            />
        </td>
        <td>
            <Select
                name='status'
                id='status-field'
                value={selectedRemark}
                isMulti={false}
                isClearable={false}
                onChange={(e) => {
                    setSelectedRemark(e);
                }}
                options={props.labRemarkOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
            />
        </td>
        {/* <td className="action">
            <i className="text-danger fa-solid fa-trash" onClick={() => {props.handleSave(props.row)}} title={props.t('delete')}></i>
        </td> */}
    </>
  )
}

export default withRouter(withTranslation()(TestsRow))
