import React from 'react'

const ActionButton = ({title, action, img}) => {

  return (
    <React.Fragment>
        <button className="print-exit mt-1" type="button" onClick={() => action()}> <img src={img} alt="" className="pb-1 me-2" />{title}</button>
    </React.Fragment>
  )
}

export default ActionButton