import { toast } from 'react-toastify';

import {
    getLabRemarks as getLabRemarksApi,
    getLabTestRemark as getLabTestRemarkApi,
    getAllLabRemarks as getAllLabRemarksApi,
    createLabTestRemark as createLabTestRemarkApi,
    updateLabTestRemark as updateLabTestRemarkApi,
    deleteLabTestRemark as deleteLabTestRemarkApi,
    
    getLabSamples as getLabSamplesApi,
    getAllLabSamples as getAllLabSamplesApi,
    getLabTestSample as getLabTestSampleApi,
    createLabTestSample as createLabTestSampleApi,
    updateLabTestSample as updateLabTestSampleApi,
    deleteLabTestSample as deleteLabTestSampleApi,

    getLabRecords as getLabRecordsApi,
    getLabRecord as getLabRecordApi,
    createLabTests as createLabTestsApi
} from "../../helpers/backend_helper"
import { createAsyncThunk } from '@reduxjs/toolkit';

/*
* Lab Remark
*/
export const getLabRemarks = createAsyncThunk("laboratory/getLabRemarks", async(config) => {
    try {
        const response = await getLabRemarksApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAllLabRemarks = createAsyncThunk("laboratory/getAllLabRemarks", async() => {
    try {
        const response = await getAllLabRemarksApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getLabTestRemark = createAsyncThunk("laboratory/getLabTestRemark", async(id) => {
    try {
        const response = await getLabTestRemarkApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createLabTestRemark = createAsyncThunk("laboratory/createLabTestRemark", async(config) => {
    try {
        const response = await createLabTestRemarkApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateLabTestRemark = createAsyncThunk("laboratory/updateLabTestRemark", async(config) => {
    try {
        const response = await updateLabTestRemarkApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteLabTestRemark = createAsyncThunk("laboratory/deleteLabTestRemark", async(config) => {
    try {
        const response = await deleteLabTestRemarkApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

/*
* Lab Sample
*/
export const getLabSamples = createAsyncThunk("laboratory/getLabSamples", async(config) => {
    try {
        const response = await getLabSamplesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAllLabSamples = createAsyncThunk("laboratory/getAllLabSamples", async() => {
    try {
        const response = await getAllLabSamplesApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getLabTestSample = createAsyncThunk("laboratory/getLabTestSample", async(id) => {
    try {
        const response = await getLabTestSampleApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createLabTestSample = createAsyncThunk("laboratory/createLabTestSample", async(config) => {
    try {
        const response = await createLabTestSampleApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error) 
    }
})

export const updateLabTestSample = createAsyncThunk("laboratory/updateLabTestSample", async(config) => {
    try {
        const response = await updateLabTestSampleApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteLabTestSample = createAsyncThunk("laboratory/deleteLabTestSample", async(config) => {
    try {
        const response = await deleteLabTestSampleApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Lab record

export const getLabRecords = createAsyncThunk("laboratory/getLabRecords", async(config) => {
    try {
        const response = await getLabRecordsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getLabRecord = createAsyncThunk("laboratory/getLabRecord", async(id) => {
    try {
        const response = await getLabRecordApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createLabTests = createAsyncThunk("laboratory/createLabTests", async(config) => {
    try {
        const response = await createLabTestsApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})