import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const Barcode = (props) => {
  return (
    <div>Barcode</div>
  )
}

export default withRouter(withTranslation()(Barcode))
