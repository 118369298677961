import React, { useEffect, useState } from 'react'
import withRouter from '../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Col, Collapse, Input, Label, Row } from 'reactstrap'

const CashDeskRow = (props) => {

    const [amount, setAmount] = useState(props.row.amount)
    const [rowTotal, setRowTotal] = useState()


    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value <= 1) {
            setValue(0);
        }
    };

    useEffect(() => {
        if (amount >= 0) {
            let object = {
                label: props.row.label,
                source: props.row.source,
                amount: amount,
            }
            props.updateRow(object)
        }
    }, [amount]);

  return (
    <>
        <Row className='m-2'>
            <Col xl={4} sm={6} style={{ alignContent: "center" }}>
                <Label> {props.row.label}<span className='text-danger'>*</span>: </Label>
            </Col>
            <Col xl={8} sm={6}>
                <Input
                    name="dob"
                    className="form-control" type="number"
                    placeholder={props.t("enter_amount")}
                    value={props.row.amount}
                    onChange={(e) => setAmount(Number(e.target.value))} onBlur={(e) => handleBlur(e, setAmount)} 
                    // value={selectedDate}
                    // validate={{ required: { value: true } }} 
                    // max={getTodayDate()} 
                    // autoComplete="off"
                    // disabled = {isValidate ? true : false}
                />
            </Col>
        </Row>
    </>
  )
}

export default withRouter(withTranslation()(CashDeskRow))
