import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
    getPatientLabRecords as onGetPatientLabRecords,
    getPatientAllVitals as onGetPatientAllVitals,
} from "../../../../slice/thunks"

const SeeLabDoctor = (props) => {
    const { id, name, ref, date } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const selectLayoutState = (state) => state.Doctors;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        patientVitalsHistory: outputs.patientVitalsHistory,
        patientsLabRecords: outputs.patientsLabRecords,
        error: outputs.error
      })
    )
    const { patientVitalsHistory, patientsLabRecords, error } = useSelector(OutputsProperties)
    const [ currentPatientH, setCurrentPatientH] = useState(null)

    const loadLabRecords = useCallback(() => {
        dispatch(onGetPatientLabRecords())
    })

    useEffect(() => {
        if (id) {
            loadLabRecords()
        } else {
            history('/doctor/doctor-panel-see-patient')
        }
    },[dispatch])

    useEffect(() => {
        if (patientsLabRecords && patientsLabRecords.page) {
            setCurrentPatientH(patientsLabRecords.items.find((element) => element.id === id))
        }
    },[patientsLabRecords])

  return (
    <div className="dashboard">
        {/* <!-- Start doctor-panel-screen-ten --> */}
        <div className="doctor-panel-screen-ten-sec">
            <div className="container pt-3">
                <div className="heading-sec">
                    <a href="javascript:history.back()">
                        <div className="patient-details-heading-sec pb-3">
                            <div className="left-arrow">
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                            <div className="heading align-middle">
                                <p className="mb-0">{props.t("lab_record")}</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="tabs-sec">
                    <ul className="nav nav-tabs nav-underline d-flex" id="myTab" role="tablist"
                        style={{ borderBottom: "none" }}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="vitals-history-tab" data-bs-toggle="tab"
                                data-bs-target="#vitals-history" type="button" role="tab"
                                aria-controls="vitals-history" aria-selected="true">
                                <i className="fa fa-user ms-1 me-2"></i> {props.t("lab_record_details")}
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="doctor-panel-screen-ten-main-sec pb-3">
                    <div className="row py-2">
                        {/* <!-- TABS Content --> */}
                        <div className="tab-content" id="myTabContent">

                            {/* <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                {props.t("lab_record_date")} &nbsp;&nbsp; {date}
                            </div> */}
                            <div className="tab-pane fade show active" id="vitals-history" role="tabpanel"
                                aria-labelledby="vitals-history-tab">
                                <div className="doctor-panel-vitals-history-sec">
                                    <div className="row row-one-sec">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-2">
                                            <span className="">{props.t("patient_id")} - </span><span
                                                className="patient-id-no">{ref}</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-9 col-md-9 col-12 mb-2">
                                            <span className="">{props.t("patient_name")} - </span><span
                                                className="patient-id-no">{name}</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                    </div>

                                    {currentPatientH && currentPatientH.labTests.map((row, index) =>{
                                        return <div className="row row-three-sec" key={index}>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 lab-test-name">
                                                    <span className="para-one">{props.t("test_name")}</span> : <span className="para-one">{row.productName}</span>
                                                </div>
                                                <hr/>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <span className="para-one">{props.t("sample_type")}</span> : <span className="para-one">{row.sampleType}</span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <span className="para-one">{props.t("test_range")}</span> : <span className="para-one">{props.testRange}</span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <span className="para-one">{props.t("test_remark")}</span> : <span className="para-one">{props.testRemark}</span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <span className="para-one">{props.t("test_results")}</span> : <span className="para-one">{row.testResults}</span>
                                                </div>
                                                <hr/>
                                            </div>
                                        })

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        {/* <!-- End doctor-panel-screen-ten --> */}
    </div>
  )
}

export default withRouter(withTranslation()(SeeLabDoctor))