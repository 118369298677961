import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import AdmitPatients from '../../Ipd/AdmitPatients'

function MainAdmitPatient(props) {
  return (
    <div className="" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <AdmitPatients isActive={props.isActive} />
    </div>
  )
}

export default withRouter(withTranslation()(MainAdmitPatient))
