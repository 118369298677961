
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Link, useLocation } from 'react-router-dom'
import withRouter from "../components/Common/withRouter"
import { createSelector } from 'reselect'
//toast notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//redux
import { useSelector, useDispatch } from "react-redux"
//action
import { refreshToken } from '../slice/thunks';
// import Components
import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { 
    logoutUser,
    checkOpenCashdesk as onCheckOpenCashdesk,
    getCashDesks as onGetCashDesks,
    getPaymentMode as onGetPaymentMode,
    openCashDesk as onOpenCashDesk,
    closeCashDesk as onCloseCashDesk,
} from '../slice/thunks'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import CashDeskRow from '../components/App/Sales/CashDeskRow'
import { withTranslation } from 'react-i18next';


const Layout = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Login;
    const selectSettingState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectSettingState,
        (setting) =>({
        cashDesk: setting.cashDesk,
        cashDesks: setting.cashDesks,
        paymentModes: setting.paymentModes,
        cashDeskOpen: setting.cashDeskOpen,
        openCashDesksuccess: setting.openCashDesksuccess,
        closeCashDesksuccess: setting.closeCashDesksuccess,
        error: setting.error
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutState,
        (outputs) =>({
            user: outputs.user,
        })
    )
    const { cashDesk, cashDesks, cashDeskOpen, paymentModes, openCashDesksuccess, closeCashDesksuccess, error } = useSelector(SettingsProperties)
    const { user } = useSelector(OutputsProperties)
    const permission = JSON.parse(localStorage.getItem("perm"));
    const role = JSON.parse(localStorage.getItem("role"));

    const [currentRole, setCurrentRole] = useState(null)
    const [currentPermission, setCurrentPermission] = useState(null)
    
    // CashDesk
    const [modal, setModal] = useState(false)
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ cashDeskList, setCashDeskList ] = useState(null)
    const [ selectedCashDesk, setSelectedCashDesk ] = useState(null)
    const [ isClose, setIsClose] = useState(false)
    // End CashDesk

    useEffect(() => {
        loadPaymentMode()
        loadCashDesks()
        checkCashDesk()
    }, [dispatch])

    useEffect(() => {
        if ((cashDeskOpen === false)) {
          toggle()
        }
    },[cashDeskOpen])

    useEffect(() =>{
        if (user && user.name) {
          setCurrentRole(user.role.roleName)
        } else if (role) {
          setCurrentRole(role)
          setCurrentPermission(permission[0])
        }
    },[user, role])

    // Cashdesk

    useEffect(() => {
        if (paymentModes && paymentModes.length > 0) {
            const payment_lists = paymentModes.filter((mode) => mode.value != 'PATIENT_CREDIT').map( mode => {
            return { label: mode.title, source: mode.value, amount: null }
            })
            setPaymentOption(payment_lists)
        }
    },[paymentModes])

    useEffect(() => {
        if (cashDesks && cashDesks.page > 0) {
            const cashdesk_lists = cashDesks.items.map( row => {
            return { label: row.cashDeskNumber, value: row.id }
            })
            setCashDeskList(cashdesk_lists)
        }
    },[cashDesks])
    
    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    })

    const loadCashDesks = useCallback(() => {
        dispatch(onGetCashDesks())
    })

    const checkCashDesk = useCallback(() => {
        dispatch(onCheckOpenCashdesk())
    })

    const onClickCloseCashDesk = useCallback(() => {
        setIsClose(true)
        toggle()
    })

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsClose(false);
            setSelectedCashDesk(null)
        } else {
            setModal(true);
        }
    }, [modal])

    const handleUpdateRow = (row) => {
        let cashDesk_items = [...paymentOption];
        const index = cashDesk_items.findIndex(objet => objet.source === row.source);
        if (index !== -1) {
        cashDesk_items[index] = { ...cashDesk_items[index], ...row };
        setPaymentOption(cashDesk_items);
        }
    }

    const openCashDesk = useCallback(() => {
        let config = {
        id: selectedCashDesk && selectedCashDesk.value,
        body: {cashDeskSessionActions: paymentOption}
        }

        console.log(config);
        
        dispatch(onOpenCashDesk(config))
    })

    const closeCashDesk = useCallback(() => {
        let config = {
            id: selectedCashDesk && selectedCashDesk.value,
            body: {cashDeskSessionActions: paymentOption}
        }

        console.log(config);
        
        dispatch(onCloseCashDesk(config))
    })

    useEffect(() => {
        if (openCashDesksuccess && !error) {
            setSelectedCashDesk(null)
            checkCashDesk()
            toggle()
        }
    },[openCashDesksuccess, cashDesk])

    useEffect(() => {        
        if (closeCashDesksuccess && !error) {
        setSelectedCashDesk(null)
        checkCashDesk()
        toggle()
        }
    },[closeCashDesksuccess, cashDesk])

    // End CashDesk

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(refreshToken(props.router.navigate));
        }, 25 * 60 * 1000); // Refresh every 30 minutes
    
        return () => clearInterval(intervalId);
    },[]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header clickClose={onClickCloseCashDesk} isCashier={currentRole && currentRole == 'CASHIER' ? true : false} />
                <section>
                    <Sidebar currentPermission={currentPermission} currentRole={currentRole} />
                    <div className="content">
                        {props.children}
                    </div>
                    <ToastContainer closeButton={false} limit={3} />
                </section>
                <Footer />
            </div>

            <Modal id='showModal' size='lg' isOpen={modal || false} backdrop={'static'} centered>
                <ModalHeader className="bg-light p-3" >
                    {isClose ? props.t("close_cash_desk") : props.t("open_cash_desk")}
                </ModalHeader>
                <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                }}>
                    <ModalBody>
                        <input type="hidden" id="id-field" />
                        <Col xl={12} sm={12}>
                            <div className="mb-3">
                            <Label
                                htmlFor="cashdesk-field"
                                className="form-label"
                            >
                                {props.t("select_cashDesk")}
                                <span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='cashDesk'
                                id='accountType-field'
                                value={selectedCashDesk}
                                isMulti={false}
                                isClearable={false}
                                autoComplete="off" 
                                // className='p-0'
                                onChange={(e) => {
                                    setSelectedCashDesk(e);
                                }}
                                options={cashDeskList}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                            />
                            </div>
                        </Col>
                        <div hidden={ selectedCashDesk && selectedCashDesk.value ? false : true}>
                            {paymentOption && paymentOption.map((row, index) => (
                            <div key={index}>
                                <CashDeskRow row={row} index={index} updateRow= {handleUpdateRow} />
                            </div>
                            ))}
                        </div>
                    </ModalBody>

                    <div className='p-3'>
                        <Row className="align-items-center">
                        <div className="col-sm">
                            <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                        </div>
                        <div className="col-sm-auto">
                            <div className="hstack gap-2 justify-content-end">
                                {isClose ?
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={selectedCashDesk && selectedCashDesk.value ? false : true}
                                        onClick={() => {closeCashDesk()}}
                                    >
                                        {props.t("close")}
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={selectedCashDesk && selectedCashDesk.value ? false : true}
                                        onClick={() => {openCashDesk()}}
                                    >
                                        {props.t("open")}
                                    </button>
                                }
                            </div>
                        </div>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.object,
}

export default withRouter(withTranslation()(Layout))
