import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import withRouter from '../../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { Col, Input, Label, Row , Card, Form, FormFeedback, Modal, ModalBody, ModalHeader} from 'reactstrap'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {
    getConsultationListDoctor as onGetConsultationListDoctor,
    markPatientConsultedDoctor as onMarkPatientConsulted,
    registerAppointment as onRegisterAppointment,
} from "../../../../slice/thunks"
import TableContainer from '../../../../components/Common/TableContainer'
import GlobalLoader from '../../../../components/Common/GlobalLoader'

function NewPatients(props) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Doctors;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        consultationPatientsList: outputs.consultationPatientsList,
        doctorAppointment: outputs.doctorAppointment,
        mark_patient_consulted_success: outputs.mark_patient_consulted_success,
        succes_create: outputs.succes_create,
        error: outputs.error
      })
    )
    const { consultationPatientsList, doctorAppointment, succes_create, mark_patient_consulted_success, error } = useSelector(OutputsProperties)
    const [status, setStatus] = useState({label: 'All', value: 'all'})
    const [searchInput, setSearchInput] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [ remark, setRemark ] = useState('')
    const [isConsult, setIsConsult] = useState(false)
    const fp = useRef(null);
    const [periods, setPeriods] = useState([]);

  const statusList = [
    {label: props.t("all"), value: 'all'},
    {label: props.t("yes"), value: 'yes' },
    {label: props.t("no"), value: 'no' },
  ]
  const [modal, setModal] = useState(false)
  const [currentPatient, setCurrentPatient] = useState(null)
  const [currentDate, setCurrentDate] = useState(null)

  const loadConsultationList = useCallback((pageNumber, pageSize, term) => {
      if (pageNumber && pageSize) {
          if (term) {
            if (periods.length > 0) {
                dispatch(onGetConsultationListDoctor({page: pageNumber, size: pageSize, fromDate: moment(periods[0]).format("YYYY-MM-DD"), toDate: moment(periods[1]).format("YYYY-MM-DD"), status: status.value, term: searchInput}))
            } else {
                dispatch(onGetConsultationListDoctor({page: pageNumber, size: pageSize, status: status.value, term: searchInput}))
            }
          }
          else {
            if (periods.length > 0) {
              dispatch(onGetConsultationListDoctor({page: pageNumber, size: pageSize, fromDate: moment(periods[0]).format("YYYY-MM-DD"), toDate: moment(periods[1]).format("YYYY-MM-DD"), status: status.value}))
            }else {
              dispatch(onGetConsultationListDoctor({page: pageNumber, size: pageSize, status: status.value}))
            }
          }
      } else {
          if (term) {
              dispatch(onGetConsultationListDoctor({page: 1, size: 10, status: status.value, term: searchInput}))
          }
          else {
              dispatch(onGetConsultationListDoctor({page: 1, size: 10, status: status.value}))
          }
      }
  })

  const searchPatients = useCallback((term, pageNumber, pageSize) => {
      if (pageNumber && pageSize) {
      //   dispatch(onSearchPatient({term: term, page: pageNumber, size: pageSize}))
      } else {
      //   dispatch(onSearchPatient({term: term, page: 1, size: 10}))
      }
  },[dispatch])

  const toggle = useCallback(() => {

    if (modal) {
      setModal(false);
      validation.resetForm()
      setIsConsult(false)
      setCurrentDate(null)
      setCurrentPatient(null)
    } else {
      setModal(true);
    }
  })

  useEffect(() =>{
      if (props.isActive) {
        loadConsultationList()
      }
  },[props.isActive])

  useEffect(() => {
    if (consultationPatientsList && consultationPatientsList.page) {
      setIsLoading(false)
    }
  },[consultationPatientsList])

  useEffect(() => {
    // if ((periods !== null && periods.length > 0) && (status !== null) && props.isActive) {
        if ((searchInput.length !== null) && (searchInput.length > 2) ) {
            if (periods.length > 0) {
                loadConsultationList(1, 10, moment(periods[0]).format("YYYY-MM-DD"), moment(periods[1]).format("YYYY-MM-DD"), status, searchInput)
            } else {
                loadConsultationList(1, 10, status, searchInput)
            }
        } else {
            if (periods.length > 0) {
                loadConsultationList(1, 10, moment(periods[0]).format("YYYY-MM-DD"), moment(periods[1]).format("YYYY-MM-DD"), status)
            } else {
                loadConsultationList(1, 10, status)
            }
        }
    // } else if (periods.length <= 0) {
    //     loadConsultationList()
    // }
  },[periods, status])

  useEffect(() =>{
    if (succes_create && !error) {
      validation.resetForm()
      setModal(false)
      setCurrentPatient(null)
      loadConsultationList()
      history("/doctor/doctor-panel/appointment")
    }
  },[succes_create, error, doctorAppointment])

  useEffect(() => {
    if (mark_patient_consulted_success && !error) {
        loadConsultationList()
        setCurrentPatient(null)
        setIsConsult(false)
        setRemark('')
        setModal(false)
    }
   },[mark_patient_consulted_success])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            patientRecordId: (currentPatient && currentPatient.patient && currentPatient.patient.patientRecord && currentPatient.patient.patientRecord.id) || '',
            appointmentDate: '',
        },
        validationSchema: Yup.object({
            appointmentDate: Yup.string().required(props.t("you_need_provide_date")),
        }),
        onSubmit: (values) => {
            const newAppointment = {
                patientRecordId: (currentPatient && currentPatient.patient && currentPatient.patient.patientRecord && currentPatient.patient.patientRecord.id) || '',
                appointmentDate: values.appointmentDate
            }
            console.log(("newAppointment: ", newAppointment));
            dispatch(onRegisterAppointment(newAppointment))
        },
    })

    function handleSelectStatus(selectedStatus) {
        setStatus(selectedStatus)
    }

    function handleSearch () {
        loadConsultationList(1, 10, searchInput)
    }

    const handleOpen = (user) => {
        if (user) { setCurrentPatient(user) }
        toggle()
    }

    const handleSelectConsultation = (arg) => {
        setCurrentPatient(arg)
        setIsConsult(true)
        toggle()
    }

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const setPatientConsulted = useCallback(() => {
        dispatch(onMarkPatientConsulted({ id: currentPatient.id, body: { remark: remark} }))
    })

    const columns = useMemo(
        () => [
            {
                header: props.t("patient_id"),
                accessorKey: "index",
                style: "button",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return <span>#{cellProps.row.original.patient.patientRef}</span>;
                },
            },
            {
                header: props.t("patients_name"),
                accessorKey: "firstName",
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <div className='d-flex align-items-center'>
                                <span >
                                    {(cell.row.original.patient.lastName != null ) ? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : cell.row.original.patient.firstName}
                                </span>
                        </div>
                }
            },
            {
                header: props.t("status"),
                accessorKey: "status",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        <span className='text-bold'>
                                {(cell.row.original.status == false )? props.t("not_consulted") : props.t("consulted")}
                        </span>
                    )
                }
            },
            {
                header: 'Consultation',
                accessorKey: "consultationPaid",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        <span className='text-bold'>
                            {(cell.row.original.consultationPaid == false )? 'Not Paid' : 'Paid'}
                        </span>
                    )
                }
            },
            {
            header: props.t("action"),
            style: "action d-flex justify-content-end me-5 px-5",
            cell: (cellProps) => {
                return (
                <td className="action d-flex justify-content-end me-2">
                    <Link to={`/patients/details/${cellProps.row.original.patient.id}/doctor`}>
                        <i className="fa fa-eye"></i>
                    </Link>
                    <div className="t-bl mx-2"></div>
                    <Link to={`/doctor/doctor-panel/see-vitals/${cellProps.row.original.patient.id}/${cellProps.row.original.patient.patientRef}/${(cellProps.row.original.patient.lastName != null ) ? cellProps.row.original.patient.firstName+" "+cellProps.row.original.patient.lastName : cellProps.row.original.patient.firstName}`}>
                        <p className="ms-1 ">
                            {props.t("see_vitals")}
                        </p>
                    </Link>
                    <div className="t-bl mx-2"></div>
                    <Link to="#"
                        onClick={() => { const userData = cellProps.row.original; handleOpen(userData); }}
                    >
                        <p className="ms-1">
                            {props.t("take_appointment")}
                        </p>
                    </Link>
                    {cellProps.row.original.status == false ?
                        <>
                            <div className="t-bl mx-2"></div>
                            <Link to="#"
                                onClick={() => { const appointmentData = cellProps.row.original; handleSelectConsultation(appointmentData); }}
                            >
                                <p className="ms-1">
                                    {props.t("mark_as_consulted")}
                                </p>
                            </Link>
                        </>
                        :
                        <></>
                    }
                </td>
                );
            },
            },
        ],
        [props]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content p-3 m-0 pt-4" id="new_patients">
                    <div className="container">
                    <Row>
                        <Col xl={4} sm={4} className="">
                            <div className='mb-2'>
                                <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                                <div className='d-flex align-items-center'>
                                    <Flatpickr
                                        className='flatpick-custom'
                                        id="inputGroupSelect01"
                                        ref={fp}
                                        placeholder={props.t("select_date_range")}
                                        defaultValue={periods}
                                        value={ periods }
                                        options={{
                                            altInput: true,
                                            altFormat: "d / m/ Y",
                                            maxDate: new Date(),
                                            mode: "range",
                                        }}
                                        onChange={(e) => {
                                            if (e.length > 1) {
                                                setPeriods(e)
                                            }
                                        }}
                                    />
                                    <button type="button" className="btn btn-light mx-1" onClick={() => { if (!fp?.current?.flatpickr) return; fp.current.flatpickr.clear(); setPeriods([]) }} >
                                        {props.t("clear")}
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("see_doctor")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={status}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={(e) => {
                                        handleSelectStatus(e);
                                    }}
                                    options={statusList}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("search_patient")}</Label><br/>
                                <div className="d-flex align-items-center">
                                    <div className="search-box me-2 mb-1 col-10">
                                        <Input
                                            className="custom-border form-control"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                    </div>
                                    <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                        <i className="fa fa-search p-0"></i>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="row pb-4">
                        <TableContainer
                            columns={columns}
                            data={((consultationPatientsList && consultationPatientsList.items) || [])}
                            isGlobalFilter={false}
                            customPageSize={10}
                            divClass="card table-card p-0"
                            tableClass="table"
                            tdClass="py-0 text-start"
                            theadClass="bg-gray"
                            tableSize={ consultationPatientsList && consultationPatientsList.size }
                            tablePage={ consultationPatientsList && consultationPatientsList.page }
                            tableTotal= { consultationPatientsList && consultationPatientsList.total_items }
                            tableTotalPage= { consultationPatientsList && consultationPatientsList.total_page }
                            onNextPage= {loadConsultationList}
                            onSearch= {loadConsultationList}
                            SearchPlaceholder={props.t("search_patient")}
                        />
                    </div>
                    </div>
                    <Modal id='showModal' size='md' isOpen={modal || false} backdrop={'static'} centered>
                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {isConsult ? props.t("mark_as_consulted") : props.t("create_appointment")}
                        </ModalHeader>
                        <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <ModalBody>
                                <input type="hidden" id="id-field" />
                                <Row>
                                    {!isConsult ?
                                        <Col xl={12} sm={12}>
                                            <div className='mb-2 mt-1'>
                                                <Label htmlFor="appointmentDate-field" className="form-label">{props.t("select_an_appointment_date")}<span className='text-danger'>*</span></Label><br/>
                                                <Input
                                                    name="appointmentDate" id='appointmentDate-field' className="form-control" type="date"
                                                    validate={{ required: { value: true } }} min={getTodayDate()} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.appointmentDate || ""}
                                                    autoComplete="off"
                                                    invalid={
                                                        validation.touched.appointmentDate && validation.errors.appointmentDate ? true : false
                                                    }
                                                />
                                                {validation.touched.appointmentDate && validation.errors.appointmentDate ? (
                                                    <FormFeedback type="invalid">{validation.errors.appointmentDate}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        :
                                        <Col>
                                            <div className='mb-2 mt-1'>
                                                <Label htmlFor="remark-field" className="form-label">
                                                    {props.t("doctoer_consultation_remark")}<span className='text-danger'>*</span>
                                                </Label>
                                                <br/>
                                                <textarea
                                                            name="remark"
                                                            id='remark-field'
                                                            className="form-control"
                                                            rows={5}
                                                            resize={false}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={(e) => setRemark(e.target.value)}
                                                            value={remark}
                                                        >
                                                    </textarea>
                                                {/* <Input
                                                    name="remark"
                                                    id='remark-field'
                                                    className="form-control"
                                                    type="text"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                    autoComplete="off"
                                                /> */}
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </ModalBody>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentDate(null) }}> {props.t("close")} </button>
                                        {!isConsult ?
                                            <button type="submit" className="btn btn-success"> {props.t("save")} </button>
                                            :
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => { setPatientConsulted()}}
                                                disabled={remark.length >0 ? false : true}
                                            >
                                                {props.t("save")}
                                            </button>
                                        }
                                    </div>
                                </div>
                                </Row>
                            </div>
                        </Form>

                    </Modal>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NewPatients))
