import { createSlice } from "@reduxjs/toolkit";
import {
    getPatients, getPatientTypes, getPatient, getRecordPatient, getRecordByPatient, getPatientForSale,
    addNewPatient, updatePatient, deletePatient, admitPatient, searchPatient,
    getPatientVitals, getPatientVitalsRecords, getPatientAllVitalsTable, addNewPatientVitals, updatePatientVitals,
    assignDoctor, getBedsByWard, assignBedPatient, getConsultationList, getHospitalisationList, leaveBed,
    releasePatient, changeDoctorToSee, getDoctorToSee,
    getOdpAppointments, getOdpAppointmentDetail, createOdpAppointment, updateOdpAppointment, deleteOdpAppointment,
    markOdpAppointmentAttended, markPatientConsulted
} from "./thunk"


export const initialState = {
    patients: [],
    patientTypes: [],
    patientHistory: [],
    patientRecordHistory: {},
    hospitalisationList: [],
    patientVitalsTable: [],
    patient: {},
    patientVitals: {},
    assignedDoctor: {},
    wardBed: {},
    assign_bed_patient: {},
    consultationList: {},
    doctor_to_see: {},

    odpAppointments: [],
    odpAppointment: {},
    error: null,
    success: null,
    admitPatient: null,
    patient_success: null,
    assign_bed_patient_success: null,
}


const OutputsSlice = createSlice({
    name: 'outputs',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        /*
        *  Patients
        */
        builder.addCase(getPatients.fulfilled, (state, action) => {
            state.error = null
            state.patient = {}
            state.patientVitals = {}
            state.admitPatient= null
            state.patient_success = null
            state.patients = action.payload.data
        })
        builder.addCase(getPatients.rejected, (state, action) => {
            state.patients = []
            state.error = action.error.message || null
        })

        builder.addCase(getPatientForSale.fulfilled, (state, action) => {
            state.error = null
            state.patient = {}
            state.patientVitals = {}
            state.admitPatient= null
            state.patient_success = null
            state.patients = action.payload.data
        })
        builder.addCase(getPatientForSale.rejected, (state, action) => {
            state.patients = []
            state.error = action.error.message || null
        })

        // get type
        builder.addCase(getPatientTypes.fulfilled, (state, action) => {
            state.patientTypes = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientTypes.rejected, (state, action) => {
            state.patientTypes = []
            state.error = action.error.message || null
        })
        // get one
        builder.addCase(getPatient.fulfilled, (state, action) => {
            state.patient = action.payload.data
            state.patient_success = null
            state.error = null
        })
        builder.addCase(getPatient.rejected, (state, action) => {
            state.patient = {}
            state.error = action.error.message || null
        })
        
        builder.addCase(getRecordPatient.fulfilled, (state, action) => {
            state.patientHistory = action.payload.data
            state.error = null
        })
        builder.addCase(getRecordPatient.rejected, (state, action) => {
            state.patientHistory = []
            state.error = action.error.message || null
        })

        builder.addCase(getRecordByPatient.fulfilled, (state, action) => {
            state.patientRecordHistory = action.payload.data
            state.release_patient_success = false
            state.error = null
        })
        builder.addCase(getRecordByPatient.rejected, (state, action) => {
            state.patientRecordHistory = {}
            state.error = action.error.message || null
        })

        // add
        builder.addCase(addNewPatient.fulfilled, (state, action) => {
            state.patient = action.payload.data
            if (state.patients.items && state.patients.items.length < 10) {
                state.patients.total_items = state.patients.total_items+1
                state.patients.items.push(action.payload.data)
            }
            state.patient_success = true
        })
        builder.addCase(addNewPatient.rejected, (state, action) => {
                state.error = action.error.message || null
                state.patient_success = false
        })


        // admit
        builder.addCase(admitPatient.fulfilled, (state, action) => {
            state.patient = action.payload.data
            state.admitPatient = true
        })
        builder.addCase(admitPatient.rejected, (state, action) => {
            state.error = action.error.message || null
            state.admitPatient = false
        })

        // search
        builder.addCase(searchPatient.fulfilled, (state, action) => {
            state.patients = action.payload.data
            state.error = null
        })
        builder.addCase(searchPatient.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patients = []
        })

        // update
        builder.addCase(updatePatient.fulfilled, (state, action) => {
            console.log(state.patients);
            if (state.patients && state.patients.length > 0) {
                state.patients.items = state.patients.items.map(patient =>
                    patient.id.toString() === action.payload.data.id.toString()
                    ? { ...patient, ...action.payload.data}
                    : patient
                )
            }
            state.patient = action.payload.data
            state.success = true
            state.patient_success = true
        })
        builder.addCase(updatePatient.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patient_success = false
            state.success = false
        })

        // delete
        builder.addCase(deletePatient.fulfilled, (state, action) => {
            state.patients.items = (state.patients.items || []).filter((patient) => patient.id.toString() !== action.meta.arg.id.toString())
            state.success = true
        })
        builder.addCase(deletePatient.rejected, (state, action) => {
            state.error = action.error.message || null
            state.success = false
        })

        /*
            Patient vital
        */
        builder.addCase(getPatientVitals.fulfilled, (state, action) => {
            state.patientVitals = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientVitals.rejected, (state, action) => {
            state.patientVitals = {}
            state.error = action.error.message || null
        })

        // get patient vitals from record
        builder.addCase(getPatientVitalsRecords.fulfilled, (state, action) => {
            state.patientVitals = action.payload.data
            state.patient_vital_success = false
            state.error = null
        })
        builder.addCase(getPatientVitalsRecords.rejected, (state, action) => {
            state.patientVitals = []
            state.error = action.error.message || null
        })

        // Get patient vitals
        builder.addCase(getPatientAllVitalsTable.fulfilled, (state, action) => {
            state.patientVitalsTable = action.payload.data
            state.patient_vital_success = false
            state.error = null
        })
        builder.addCase(getPatientAllVitalsTable.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        // add patient vitals
        builder.addCase(addNewPatientVitals.fulfilled, (state, action) => {
            state.error = null
            state.patientVitals = action.payload.data
            state.patient_vital_success = true
        })
        builder.addCase(addNewPatientVitals.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patient_vital_success = false
        })

        // update patient vitals
        builder.addCase(updatePatientVitals.fulfilled, (state, action) => {
            state.error = null
            state.patientVitals = action.payload.data
            state.patient_vital_success = true
        })
        builder.addCase(updatePatientVitals.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patient_vital_success = false
        })

        builder.addCase(assignDoctor.fulfilled, (state, action) => {
            state.assignedDoctor = action.payload.data
            state.assign_doctor_success = true
            state.error = null
        })
        builder.addCase(assignDoctor.rejected, (state, action) => {
            state.error = action.error.message || null
            state.assign_doctor_success = false
        })

        builder.addCase(getBedsByWard.fulfilled, (state, action) => {
            state.wardBed = action.payload.data
            state.error = null
        })
        builder.addCase(getBedsByWard.rejected, (state, action) => {
            state.wardBed = []
            state.error = action.error.message || null
        })

        builder.addCase(assignBedPatient.fulfilled, (state, action) => {
            state.error = null
            state.assign_bed_patient = action.payload.data
            state.assign_bed_patient_success = true
        })
        builder.addCase(assignBedPatient.rejected, (state, action) => {
            state.error = action.error.message || null
            state.assign_bed_patient_success = false
        })

        builder.addCase(getConsultationList.fulfilled, (state, action) => {
            state.consultationList = action.payload.data
            state.mark_patient_consulted_success = false
            state.error = null
        })
        builder.addCase(getConsultationList.rejected, (state, action) => {
            state.consultationList = []
            state.error = action.error.message || null
        })

        builder.addCase(getHospitalisationList.fulfilled, (state, action) => {
            state.hospitalisationList = action.payload.data
            state.bed_leave_success = false
        })
        builder.addCase(getHospitalisationList.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(leaveBed.fulfilled, (state, action) => {
            state.bed_leave_success = true
            state.error = null
        })
        builder.addCase(leaveBed.rejected, (state, action) => {
            state.bed_leave_success = false
            state.error = action.error.message || null
        })

        builder.addCase(releasePatient.fulfilled, (state, action) => {
            state.release_patient_success = true
            state.error = null
        })
        builder.addCase(releasePatient.rejected, (state, action) => {
            state.release_patient_success = false
            state.error = action.error.message || null
        })
        
        builder.addCase(changeDoctorToSee.fulfilled, (state, action) => {
            state.patient = action.payload.data
            state.doctor_changed_success = true
            state.error = null
        })
        builder.addCase(changeDoctorToSee.rejected, (state, action) => {
            state.doctor_changed_success = false
            state.error = action.error.message || null
        })

        
        builder.addCase(getDoctorToSee.fulfilled, (state, action) => {
            state.doctor_to_see = action.payload.data
            state.error = null
        })
        builder.addCase(getDoctorToSee.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        
        builder.addCase(getOdpAppointments.fulfilled, (state, action) => {
            state.odpAppointments = action.payload.data
            state.odpAppointment = {}
            state.updateOdpSuccess = false
            state.markOdpAppointmentSuccess = false
            state.error = null
        })
        builder.addCase(getOdpAppointments.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getOdpAppointmentDetail.fulfilled, (state, action) => {
            state.odpAppointment = action.payload.data
            state.error = null
        })
        builder.addCase(getOdpAppointmentDetail.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(createOdpAppointment.fulfilled, (state, action) => {
            state.odpAppointment = action.payload.data
            state.createOdpApointmentSuccess = true
            state.error = null
        })
        builder.addCase(createOdpAppointment.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createOdpApointmentSuccess = false
        })

        builder.addCase(updateOdpAppointment.fulfilled, (state, action) => {
            state.odpAppointment = action.payload.data
            state.error = null
            state.updateOdpSuccess = true
        })
        builder.addCase(updateOdpAppointment.rejected, (state, action) => {
            state.updateOdpSuccess = false
            state.error = action.error.message
        })

        builder.addCase(deleteOdpAppointment.fulfilled, (state, action) => {
            state.odpAppointment = action.payload.data
            state.deleteOdpSuccess = false
            state.error = null
        })
        builder.addCase(deleteOdpAppointment.rejected, (state, action) => {
            state.error = action.error.message || null
            state.deleteOdpSuccess = false
        })

        builder.addCase(markOdpAppointmentAttended.fulfilled, (state, action) => {
            state.odpAppointment = action.payload.data
            state.markOdpAppointmentSuccess = true
            state.error = null
        })
        builder.addCase(markOdpAppointmentAttended.rejected, (state, action) => {
            state.error = action.error.message || null
            state.markOdpAppointmentSuccess = false
        })

        builder.addCase(markPatientConsulted.fulfilled, (state, action) => {
            state.error = null
            state.mark_patient_consulted_success = true
        })
        builder.addCase(markPatientConsulted.rejected, (state, action) => {
            state.patients = []
            state.mark_patient_consulted_success = false
            state.error = action.error.message || null
        })

        

    }
})

export default OutputsSlice.reducer;