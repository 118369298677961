import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import { useSelector, useDispatch } from "react-redux"
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader, CardHeader, CardBody } from 'reactstrap'

import {
    loadWards as onLoadWards,
    getBedsByWard as onGetBedsByWard,
  } from "../../../slice/thunks"
import GlobalLoader from '../../../components/Common/GlobalLoader'

const WardBedStatus = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectSettingState = (state) => state.Settings
    const selectLayoutState = (state) => state.Outputs
    const SettingsProperties = createSelector(
        selectSettingState,
        (setting) =>({
          allwards: setting.allwards,
        })
      )
    const OutputsProperties = createSelector(
        selectLayoutState,
        (output) =>({
            wardBed: output.wardBed,
        })
    )
    const { allwards } = useSelector(SettingsProperties)
    const { wardBed } = useSelector(OutputsProperties)
    const [modal, setModal] = useState(false)
    const [wardsOptions, setWardsOptions] = useState([])
    const [selectedWards, setselectedWards] = useState(null)
    const [selectedBed, setselectedBed] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const loadWards = useCallback(() => {
        dispatch(onLoadWards())
    })

    const loadWardBeds = useCallback((wardId) => {
        dispatch(onGetBedsByWard({id: wardId}))
    })

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setselectedBed(null);
        } else {
          setModal(true);
        }
      }, [modal])

    useEffect(() =>{
        if (props.isActive) {
            loadWards()
        }
    },[props.isActive])

    useEffect(() =>{
    if (allwards && allwards.length > 0) {
        const wards_list = allwards.map( ward => {
        return { label: ward.wardsName, value: ward.id }
        })
        setWardsOptions(wards_list)
        setselectedWards(wards_list[0])
    }
    },[allwards])

    useEffect(() => {
        if (selectedWards && selectedWards.value) {
            loadWardBeds(selectedWards.value)
        }
    },[selectedWards])

    useEffect(() => {
        if (wardBed) {
          setIsLoading(false)
        }
      },[wardBed])

    const handleOpen = (bed) => {
        setselectedBed(bed)
        toggle()
    }
    
    function handleSelectWard(selectedWards) {
        setselectedWards(selectedWards)
    }

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="all-bed-status pb-5">
                    <div className="container">
                        <div className="card">
                            <CardHeader>
                                <Row className='mt-3'>
                                    <Col xl={6} sm={12}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor="wardId-field"
                                                className="form-label"
                                            >
                                                {props.t("wardsName")}
                                            </Label>
                                            <Select
                                                value={selectedWards}
                                                isMulti={false}
                                                isClearable={false}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleSelectWard(e);
                                                }}
                                                options={wardsOptions}
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className='pt-0'>
                                { wardBed && wardBed.length > 0 ? 
                                    <>
                                        <Row style={{ background: "rgba(232, 232, 232, 1)", height: "49px", margin:"0px" }}>
                                            <li className="choose d-flex justify-content-end">
                                                <div className="color-occupied"><a href="">{props.t("occupied")}<i
                                                            className="fa-solid fa-square"></i></a>
                                                </div>
                                                <div className="color-free"><a href="">{props.t("free")}<i
                                                            className="fa-solid fa-square"></i></a></div>
                                                <div className="color-outof-use"><a href="">{props.t("out_of_use")}<i
                                                            className="fa-solid fa-square"></i></a></div>
                                            </li>
                                        </Row>
                                        <Row className='mt-2'>
                                            {wardBed && wardBed.map(bed => {
                                                const isClickable = bed.bedStatus === "Occupied" || bed.bedStatus === "Free";
                                                return (
                                                    <Col lg={3} sm={6} className='mb-2' key={bed.bedNumber}>
                                                        <Card
                                                            className='align-items-center p-3'
                                                            style={{ cursor: isClickable ? "pointer" : "not-allowed" }}
                                                            onClick={() => isClickable && handleOpen(bed)}
                                                        >
                                                            <i className={
                                                                bed.bedStatus === "Occupied" ? "fa-solid fa-bed occupied" :
                                                                bed.bedStatus === "Free" ? "fa-solid fa-bed free-bed" :
                                                                "fa-solid fa-bed out-of-use"
                                                            }></i>
                                                            <p className="count-occupied">{bed.bedNumber}</p>
                                                        </Card>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </>
                                    : <>
                                        <div className='d-flex justify-content-center p-3'>
                                            <div className='d-flex align-items-center flex-column p-3'>
                                                <i className="fa-solid fa-triangle-exclamation global_empty"></i>
                                                <h4> {props.t("no_bed_found")}</h4>
                                            </div>
                                        </div>
                                    </>
                                }
                            </CardBody>

                            <Modal id="showModal" size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                                <ModalHeader className='bg-light p-3' toggle={toggle}>
                                    {props.t("bed_details")}
                                </ModalHeader>
                                <ModalBody>
                                    {selectedBed ?
                                        <Row>
                                            <Col xl={6} sm={12}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-label"
                                                    >
                                                        {props.t("bed_no")}
                                                    </Label>
                                                    <Input
                                                        disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={selectedBed.bedNumber || ""}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={6} sm={12}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-label"
                                                    >
                                                        {props.t("bedCharge")}
                                                    </Label>
                                                    <Input
                                                        disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={selectedBed.bedCharge || ""}
                                                    />
                                                </div>
                                            </Col>
                                            {selectedBed && selectedBed.wards ?
                                                <Col xl={6} sm={12}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-label"
                                                        >
                                                            {props.t("wardsName")}
                                                        </Label>
                                                        <Input
                                                            disabled
                                                            className="form-control"
                                                            type="text"
                                                            value={selectedBed.wards.wardsName || ""}
                                                        />
                                                    </div>
                                                </Col>
                                                : <></>
                                            }
                                            {selectedBed && selectedBed.patient ?
                                                <Col xl={6} sm={12}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-label"
                                                        >
                                                            {props.t("patient_name")}
                                                        </Label>
                                                        <Input
                                                            disabled
                                                            className="form-control"
                                                            placeholder={props.t("discount")}
                                                            type="text"
                                                            value={selectedBed.patient.patientName || ""}
                                                        />
                                                    </div>
                                                </Col>
                                                : <></>
                                            }
                                        </Row>
                                        : <></>
                                    }
                                </ModalBody>
                                <div className='p-3'>
                                    <div className="col-sm-auto">
                                        <div className="hstack gap-2 justify-content-end">
                                        <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}> {props.t("close")} </button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(WardBedStatus))
