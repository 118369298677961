import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  getPrintSetting as onGetPrintSetting,
  savePrintSetting as onSavePrintSetting
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import { Link } from 'react-router-dom'
import { Button, Modal, ModalHeader } from 'reactstrap'
import GeneralLabReports from '../../../components/Global/Invoices/LabReports/GeneralLabReports'
import MatrixLabReports from '../../../components/Global/Invoices/LabReports/MatrixLabReports'
import ThermalLabReports from '../../../components/Global/Invoices/LabReports/ThermalLabReports'
import GeneralCashier from '../../../components/Global/Invoices/Cashiers/GeneralCashier'
import MatrixCashier from '../../../components/Global/Invoices/Cashiers/MatrixCashier'
import ThermalCashier from '../../../components/Global/Invoices/Cashiers/ThermalCashier'
import GeneralDispensary from '../../../components/Global/Invoices/Dispensary/GeneralDispensary'
import MatrixDispensary from '../../../components/Global/Invoices/Dispensary/MatrixDispensary'
import ThermalDispensary from '../../../components/Global/Invoices/Dispensary/ThermalDispensary'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'


const Print = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      printSettings: setting.printSettings,
      savePrintSettingSuccess: setting.savePrintSettingSuccess,
      error: setting.error
    })
  )
  const { printSettings, savePrintSettingSuccess, error } = useSelector(SettingsProperties)
  const [modalCashier, setModalCashier] = useState(false)
  const [modalLab, setModalLab] = useState(false)
  const [modalDispensary, setModalDispensary] = useState(false)
  const [currentInvoiceCashier, setCurrentInvoiceCashier] = useState('')
  const [currentInvoiceLab, setCurrentInvoiceLab] = useState('')
  const [currentInvoiceDispensary, setCurrentInvoiceDispensary] = useState('')

  const loadPrintSettings = useCallback(() => {
      dispatch(onGetPrintSetting())
  })

  const toggleLab = useCallback(() => {
    if (modalLab) {
      setModalLab(false);
    } else {
      setModalLab(true);
    }
  }, [modalLab])

  const toggleDispensary = useCallback(() => {
    if (modalDispensary) {
      setModalDispensary(false);
    } else {
      setModalDispensary(true);
    }
  }, [modalDispensary])

  const toggleCashier = useCallback(() => {
    if (modalCashier) {
      setModalCashier(false);
    } else {
      setModalCashier(true);
    }
  }, [modalCashier])

  useEffect(() =>{
    loadPrintSettings()
  },[dispatch])

  useEffect(() => {
      if (printSettings && printSettings.printConfigs) {
        if (printSettings.printConfigs.cashierReceipt) setCurrentInvoiceCashier(printSettings.printConfigs.cashierReceipt)
        if (printSettings.printConfigs.labReports) setCurrentInvoiceLab(printSettings.printConfigs.labReports)
        if (printSettings.printConfigs.dispensaryReceipt) setCurrentInvoiceDispensary(printSettings.printConfigs.dispensaryReceipt)
      }
  },[printSettings])

  useEffect(() => {
    if (savePrintSettingSuccess && !error) {
      toggleLab()
    }
  },[savePrintSettingSuccess])


  const handleSave = () => {
    let newPrint = {
      printConfigs: {
        cashierReceipt: currentInvoiceCashier,
        labReports: currentInvoiceLab,
        dispensaryReceipt: currentInvoiceDispensary
      }
    }
    dispatch(onSavePrintSetting(newPrint))
  }

  const handleReset = () => {
    if (printSettings && printSettings.printConfigs) {
      if (printSettings.printConfigs.cashierReceipt) setCurrentInvoiceCashier(printSettings.printConfigs.cashierReceipt)
      if (printSettings.printConfigs.labReports) setCurrentInvoiceLab(printSettings.printConfigs.labReports)
      if (printSettings.printConfigs.dispensaryReceipt) setCurrentInvoiceDispensary(printSettings.printConfigs.dispensaryReceipt)
    }
  }
  

  return (
    <div className='px-3'>
        {/* <!-- Start settings-user-management-user Section --> */}
        <div className="settings-user-management-user-sec py-4 px-2">
            <div className="row list-patients d-flex  justify-content-between">
                <div className="col-lg-5">
                <BreadCrumb title={props.t("print")} pageTitle={props.t("settings")} />
                </div>
                <div className="col-sm-auto new-mode-btn">
                  {/* {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_BEDS")) ?
                    <SinpleActionButton title={props.t("create_new_bed")} img="../assets/images/user.png" action={handleOpen} />
                    :<></>
                  } */}
                </div>
            </div>
            <div className="container">
              <div className="card p-5" style={{ height: "max-content" }}>

                  {/* Cashier */}
                  <div className="row  pb-4">
                      <div className="col-lg-3 pt-3">
                          <p className="pb-1 mb-1" style={{ fontSize: "18px", fontWeight: "600", color: "#64748B" }}>
                            {props.t("cash_receipt")}</p>
                          <button className="pb-1"
                            style={{ background: "linear-gradient(166.59deg, #2563EB 3.59%, #153885 90.92%)", width: "148px", height: "25px" ,borderRadius: "3px", border: "transparent", color: "white" }}
                          >
                            {props.t("customize_setting")}
                          </button>
                      </div>
                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="general"
                            className="category-link"
                            onClick={() => { setModalCashier("General"); setCurrentInvoiceCashier("General")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceCashier =="General" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("general")}</p>
                          </Link>
                      </div>
                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="dot-cashier-matrix"
                            className="category-link"
                            onClick={() => { setModalCashier("DotMatrix"); setCurrentInvoiceCashier("DotMatrix")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each.png" 
                                    className="img-fluid" 
                                    style={ currentInvoiceCashier =="DotMatrix" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("dot_matrix")}</p>
                          </Link>
                      </div>
                      
                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="thermal"
                            className="category-link"
                            onClick={() => { setModalCashier("Thermal"); setCurrentInvoiceCashier("Thermal")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceCashier =="Thermal" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("thermal")}</p>
                          </Link>
                      </div>

                  </div>

                  {/* Lab Records */}
                  <div className="row  pb-4">
                      <div className="col-lg-3 pt-3">
                          <p className="pb-1 mb-1" style={{ fontSize: "18px", fontWeight: "600", color: "#64748B" }}>
                            {props.t("lab_reports")}
                          </p>
                          <button className="pb-1"
                            style={{ background: "linear-gradient(166.59deg, #2563EB 3.59%, #153885 90.92%)", width: "148px", height: "25px", borderRadius: "3px", border: "transparent",color: "white" }}
                          >
                            {props.t("customize_setting")}
                          </button>
                      </div>

                      <div className="col-lg-3">
                          <Link to="#" 
                            id="gen-lab-rep-print" 
                            className="category-link"
                            onClick={() => { setModalLab("General"); setCurrentInvoiceLab("General")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each-2.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceLab =="General" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("general")}</p>
                          </Link>
                      </div>

                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="invoice-modal" 
                            className="category-link"
                            onClick={() => { setModalLab("DotMatrix"); setCurrentInvoiceLab("DotMatrix")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each-2.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceLab =="DotMatrix" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("dot_matrix")}</p>
                          </Link>
                      </div>

                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="thermal" 
                            className="category-link"
                            onClick={() => { setModalLab("Thermal"); setCurrentInvoiceLab("Thermal")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each-2.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceLab =="Thermal" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("thermal")}</p>
                          </Link>
                      </div>

                  </div>

                  {/* Dispensary */}
                  <div className="row  pb-4">
                      <div className="col-lg-3 pt-3">
                          <p className="pb-1 mb-1" style={{ fontSize: "18px", fontWeight: "600", color: "#64748B" }}>
                              {props.t("dispensary_receipt")}
                          </p>
                          <button className="pb-1"
                            style={{ background: "linear-gradient(166.59deg, #2563EB 3.59%, #153885 90.92%)", width: "148px", height: "25px", borderRadius: "3px", border: "transparent", color: "white" }}>
                                {props.t("customize_setting")}
                          </button>
                      </div>
                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="general" 
                            className="category-link"
                            onClick={() => { setModalDispensary("General"); setCurrentInvoiceDispensary("General")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each-3.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceDispensary =="General" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("general")}</p>
                          </Link>
                      </div>
                      <div className="col-lg-3">
                          <Link 
                            to="#" 
                            id="dot-matrix" 
                            className="category-link"
                            onClick={() => { setModalDispensary("DotMatrix"); setCurrentInvoiceDispensary("DotMatrix")}}
                          >
                              <div className="image-container">
                                  <img 
                                    src="../assets/images/print-each-3.png" 
                                    className="img-fluid"
                                    style={ currentInvoiceDispensary =="DotMatrix" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt="" 
                                  />
                              </div>
                              <p className="text-center">{props.t("dot_matrix")}</p>
                          </Link>
                      </div>
                      <div className="col-lg-3">
                          <Link 
                            to="#"
                            id="thermal" 
                            className="category-link"
                            onClick={() => { setModalDispensary("Thermal"); setCurrentInvoiceDispensary("Thermal")}}
                          >
                              <div className="image-container">
                                  <img
                                    src="../assets/images/print-each-3.png"
                                    className="img-fluid"
                                    style={ currentInvoiceDispensary =="Thermal" ? { width: "220px", height: "158px", border: "solid blue" } : { width: "220px", height: "158px"} } 
                                    alt=""
                                  />
                              </div>
                              <p className="text-center">{props.t("thermal")}</p>
                          </Link>
                      </div>

                  </div>

                  <div className='row d-flex flex-row justify-content-center'>
                    <div className='col-lg-2'>
                      <button type="button" onClick={() => handleReset()} className="btn btn-warning">
                          <i className="fa fa-save p-0"></i>
                          {" "+props.t("reset_setting")}
                      </button>
                    </div>
                    <div className='col-lg-2'>
                      <button type="button" onClick={() => handleSave()} className="btn btn-success">
                          <i className="fa fa-save p-0"></i>
                          {" "+props.t("save_setting")}
                      </button>
                    </div>
                    
                  </div>

              </div>
            </div>
      </div>

      {/* <!-- general cashier print --> */}
      <GeneralCashier isOpen={modalCashier == "General" ? true : false } toggleCashier={toggleCashier} />
      {/* <!-- cashier dot matrix --> */}
      <MatrixCashier isOpen={modalCashier == "DotMatrix" ? true : false } toggleCashier={toggleCashier} />
      {/* <!-- Cashier thermal --> */}
      <ThermalCashier isOpen={modalCashier == "Thermal" ? true : false } toggleCashier={toggleCashier} />


      {/* <!-- general lab reports print --> */}
      <GeneralLabReports isOpen={modalLab == "General" ? true : false } toggleLab={toggleLab} />
      {/* <!-- lab reports dot matrix --> */}
      <MatrixLabReports isOpen={modalLab == "DotMatrix" ? true : false } toggleLab={toggleLab} />
      {/* <!-- thermal lab reports print --> */}
      <ThermalLabReports isOpen={modalLab == "Thermal" ? true : false } toggleLab={toggleLab} />


      {/* <!-- general dispensary print --> */}
      <GeneralDispensary isOpen={modalDispensary == "General" ? true : false } toggleDispensary={toggleDispensary} />
      {/* <!-- DOT matrix dispensary --> */}
      <MatrixDispensary isOpen={modalDispensary == "DotMatrix" ? true : false } toggleDispensary={toggleDispensary} />
      {/* <!-- Therm Dispensary --> */}
      <ThermalDispensary isOpen={modalDispensary == "Thermal" ? true : false } toggleDispensary={toggleDispensary} />

    </div>
  )
}

export default withRouter(withTranslation()(Print))
