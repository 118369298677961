import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const RecordIncome = (props) => {
  return (
    <div>RecordIncome</div>
  )
}

export default withRouter(withTranslation()(RecordIncome))
