import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import withRouter from '../../../components/Common/withRouter'
import PatientRegistration from './PatientRegistration'
import PatientVital from './PatientVital'
import ConsultationList from './ConsultationList'
import PatientSeeDoctor from './PatientSeeDoctor'
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const NewPatient = (props) => {
    let { id, param, action } = useParams();

    const selectLayoutState = (state) => state.Outputs
    const OutputsProperties = createSelector(
        selectLayoutState,
        (output) =>({
            patientTypes: output.patientTypes,
            patient: output.patient,
            error: output.error
        })
    )
    const { patient } = useSelector(OutputsProperties)
    const [activeTab, setActiveTab] = useState('patient_registration-content')
    const handleTabChange = (target) => {
        setActiveTab(target);
    }

    useEffect(() => {
        if(param){
            handleTabChange(param)
        }
    },[param])

  return (
    <div className="patients-list-content">
        {/* <!-- Output patients Start --> */}
        <div className="patients-list py-4 px-2  ">
            <div className="container-fluid ">
                <div className="row list-patients d-flex align-items-center justify-content-between">
                    <div className="col-lg-4">
                        {id ?
                            <h6>{props.t("edit_patient")}</h6>
                            :
                            <h6>{props.t("create_new_patient")}</h6>
                        }
                    </div>
                </div>
                {/* <!-- Patient Tabs and tables --> */}
                <div className="row py-2">
                    <ul className="nav nav-tabs nav-underline px-3 d-flex justify-content-between" id="myTab" role="tablist">
                        <li className="nav-item me-3" role="presentation">
                            <button  onClick={() => {handleTabChange("patient_registration-content")}} disabled={activeTab === 'patient_registration-content' ? false: true } className={activeTab === 'patient_registration-content' ? "nav-link active": "nav-link"} id="patient-registration-tab" data-bs-toggle="tab" data-bs-target="#patient_registration-content" type="button" role="tab" aria-controls="patient-registration" aria-selected="true">
                                <i className="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("patient_registration")}
                            </button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button onClick={() => {handleTabChange("patient_vital-content")}} disabled={activeTab === 'patient_vital-content' ? false: true } className={activeTab === 'patient_vital-content' ? "nav-link active": "nav-link"} id="profile-tab" data-bs-toggle="tab" data-bs-target="#patient_vital-content" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                <i className="fa fa-user me-2" aria-hidden="true"></i>{props.t("patient_vital")}
                            </button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button onClick={() => {handleTabChange("see_doctor-content")}} disabled={activeTab === 'see_doctor-content' ? false: true } className={activeTab === 'see_doctor-content' ? "nav-link active": "nav-link"} id="patient-appointment-tab" data-bs-toggle="tab" data-bs-target="#see_doctor-content" type="button" role="tab" aria-controls="patient-appointment" aria-selected="false">
                                <i className="fa fa-user me-2" aria-hidden="true"></i>{props.t("see_doctor")}
                            </button>
                        </li>
                        <li className="nav-item me-3" role="presentation">
                            <button onClick={() => {handleTabChange("consultation_list-content")}} disabled={activeTab === 'consultation_list-content' ? false: true } className={activeTab === 'consultation_list-content' ? "nav-link active": "nav-link"} id="contact-tab" data-bs-toggle="tab" data-bs-target="#send_patient_to_doctor-content" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                <i className="fa fa-user me-2" aria-hidden="true"></i>{props.t("consultation_list")}
                            </button>
                        </li>
                    </ul>
                
                    {/* <!-- TABS Content --> */}
                    <div className="tab-content" id="myTabContent">
                        <div className={activeTab === 'patient_registration-content' ? "tab-pane fade show active" : "tab-pane fade"} id="patient_registration-content" role="tabpanel" aria-labelledby="patient-registration-tab">
                            {/* <!-- TABLE 1 : Patient registration --> */}
                            <PatientRegistration isActive={activeTab === 'patient_registration-content'} patient={patient} handleTabChange={handleTabChange}/>
                        </div>
                        <div className={activeTab === 'patient_vital-content' ? "tab-pane fade show active" : "tab-pane fade"} id="patient_vital-content" role="tabpanel" aria-labelledby="profile-tab">
                            {/* <!-- TABLE 2 : Patient Vitals --> */}
                            <PatientVital isActive={activeTab === 'patient_vital-content'} id={id} action={action} patient={patient} handleTabChange={handleTabChange} />
                        </div>
                        <div className={activeTab === 'see_doctor-content' ? "tab-pane fade show active" : "tab-pane fade"} id="see_doctor-content" role="tabpanel" aria-labelledby="patient-appointment-tab">
                            {/* <!-- TABLE 4 : See Doctor --> */}
                            <PatientSeeDoctor isActive={activeTab === 'see_doctor-content'} patient={patient} action={action} handleTabChange={handleTabChange} />
                        </div>
                        <div className={activeTab === 'consultation_list-content' ? "tab-pane fade show active" : "tab-pane fade"} id="consultation_list-content" role="tabpanel" aria-labelledby="contact-tab">
                            {/* <!-- TABLE 3 : Send patient to Doctor --> */}
                            <ConsultationList isActive={activeTab === 'consultation_list-content'} id={id} action={action} patient={patient} handleTabChange={handleTabChange} />
                        </div>  
                    </div>
                </div>
            </div>
            {/* <!-- Output Patients End --> */}
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(NewPatient))