import React, { useEffect, useState } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import HomeSalesList from './SalesCahier/HomeSalesList'
import SalePatientPayment from './SalePatientPayment'
import SalesOnHold from './SalesOnHold'
import Deposits from './Deposits'
import PatientAccount from './PatientAccount/PatientAccount'
import { useParams } from 'react-router-dom'

const AllSales = (props) => {
    const { section } = useParams()
    const [currentTab, setCurrentTab] = useState(1)

    useEffect(() => {
        setCurrentTab(parseInt(section) ? parseInt(section) : 1)
    },[section])

    return (
        <>
            <div class="container-fluide pb-2 px-4">

                {/* <!-- TABS  --> */}
                <ul class="nav nav-tabs" id="myTab" role="tablist" style={{ borderBottom: "1px solid #A2A1A833" }}>
                    <li class="nav-item" role="presentation" onClick={() => {setCurrentTab(1)}}>
                        <button class={ currentTab === 1 ? "nav-link active" : "nav-link"} id="home-tab" data-bs-toggle="tab"
                            type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                            <i class="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("home")}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => {setCurrentTab(2)}}>
                        <button class={ currentTab === 2 ? "nav-link active" : "nav-link"} id="billsOnHold-tab" data-bs-toggle="tab"
                            type="button" role="tab"
                            aria-controls="billsOnHold--tab-pane" aria-selected="false">
                            <i class="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("sales_on_hold")}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => {setCurrentTab(3)}}>
                        <button class={ currentTab === 3 ? "nav-link active" : "nav-link"} id="billsOnHold-tab" data-bs-toggle="tab"
                            type="button" role="tab"
                            aria-controls="billsOnHold--tab-pane" aria-selected="false">
                            <i class="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("patients_accounts")}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => {setCurrentTab(4)}}>
                        <button class={ currentTab === 4 ? "nav-link active" : "nav-link"} id="billsOnHold-tab" data-bs-toggle="tab"
                            data-bs-target="#payment-tab-pane" type="button" role="tab"
                            aria-controls="billsOnHold--tab-pane" aria-selected="false">
                            <i class="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("payments")}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => {setCurrentTab(5)}}>
                        <button class={ currentTab === 5 ? "nav-link active" : "nav-link"} id="deposits-bill" data-bs-toggle="tab" data-bs-target="#deposit-tab-pane"
                            type="button" role="tab" aria-controls="deposit-tab-pane" aria-selected="false">
                            <i class="fa fa-user ms-1 me-2" aria-hidden="true"></i>{props.t("deposits")}
                        </button>
                    </li> 
                </ul>

                {/* <!-- TABS CONTENT  --> */}
                <div class="tab-content" id="myTabContent">
                    {/* <!--- Home ---> */}
                    <div class={ currentTab === 1 ? "tab-pane fade show active" : "tab-pane fade" } id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0" >
                        <HomeSalesList isActive= {currentTab === 1 ? true : false}/>
                    </div>

                    <div class={ currentTab === 2 ? "tab-pane fade show active" : "tab-pane fade" } id="patientAccount-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0" >
                        <SalesOnHold isActive= {currentTab === 2 ? true : false}/>
                    </div>

                    <div class={ currentTab === 3 ? "tab-pane fade show active" : "tab-pane fade" } id="patientAccount-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0" >
                        <PatientAccount isActive= {currentTab === 3 ? true : false}/>
                    </div>

                    <div class={ currentTab === 4 ? "tab-pane fade show active" : "tab-pane fade" } id="payment-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0" >
                        <SalePatientPayment isActive= {currentTab === 4 ? true : false}/>
                    </div>

                    <div class={ currentTab === 5 ? "tab-pane fade show active" : "tab-pane fade" } id="payment-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0" >
                        <Deposits isActive= {currentTab === 5 ? true : false}/>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default withRouter(withTranslation()(AllSales))
