import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Auth/Login";
import Settings from "../pages/Settings";
import Profile from "../pages/Profile";
import Patients from "../pages/Patients";
import UserManagement from "../pages/Settings/UserManagement";
import SettingZone from "../pages/Settings/SettingZone";
import RecortExpend from "../pages/Settings/RecortExpend";
import RecordIncome from "../pages/Settings/RecordIncome";
import Wards from "../pages/Settings/Wards";
import Beds from "../pages/Settings/Beds";
import Barcode from "../pages/Settings/Barcode";
import Print from "../pages/Settings/Print";
import TaxRates from "../pages/Settings/TaxRates";
import Warehouse from "../pages/Settings/Warehouse";
import Modules from "../pages/Settings/Modules";
import CompanySettings from "../pages/Settings/CompanySettings";
import Accounting from "../pages/Settings/Accounting";
import PaymentsMode from "../pages/Settings/PaymentsMode";
import NewPatient from "../pages/Patients/NewPatient";
import Doctors from "../pages/Settings/Doctors";
import Ipd from "../pages/Ipd";
import PatientDetail from "../pages/Patients/AllPatients/PatientDetail";
import PatientHistory from "../pages/Patients/AllPatients/PatientHistory";
import HistoryDetails from "../pages/Patients/AllPatients/PatientHistory/HistoryDetails";
import Categories from "../pages/Settings/Categories";
import DoctorPanel from "../pages/Doctor/DoctorPanel";
import Appointments from "../pages/Doctor/DoctorPanel/Appointments";
import SeeVitals from "../pages/Doctor/DoctorPanel/NewPatients/seeVitals";
import ItemRecord from "../pages/Warehouse/ItemRecord";
import RecordSuppliers from "../pages/Warehouse/RecordSuppliers";
import MainPatientVitals from "../pages/Patients/AllPatients/MainPatientVitals";
import Samples from "../pages/Settings/LabSetting/Samples";
import Remarks from "../pages/Settings/LabSetting/Remarks";
import LabRecord from "../pages/Lab/LabRecord";
import SuppiersOrder from "../pages/Warehouse/SuppiersOrder";
import NewSupplerOrder from "../pages/Warehouse/SuppiersOrder/NewSuppliersOrder";
import StockReports from "../pages/Warehouse/StockReports";
import ProductDiscount from "../pages/Settings/ProductDiscount";
import NewTransfertOrder from "../pages/Warehouse/TransfertOrder/NewTransfertOrder";
import TransfertOrder from "../pages/Warehouse/TransfertOrder";
import PhTransfertOrder from "../pages/Pharmacy/PhTransfertOrder";
import PhNewTransfertOrder from "../pages/Pharmacy/PhTransfertOrder/PhNewTransfertOrder";
import PhStockReports from "../pages/Pharmacy/PhStockReports";
import AllSales from "../pages/Cashier/AllSales";
import LabRecordDetail from "../pages/Lab/LabRecordDetail";
import FinancialYear from "../pages/Settings/FinancialYear";
import PhInvoiceList from "../pages/Pharmacy/PhInvoiceList";
import PhOccupiedWards from "../pages/Pharmacy/PhOccupiedWards";
import PhInvoiceDetails from "../pages/Pharmacy/PhOccupiedWards/PhInvoiceDetails";
import PatientAccountDetail from "../pages/Cashier/AllSales/PatientAccount/PatientAccountDetail";
import Banking from "../pages/Accountant/Banking";
import { components } from "react-select";
import Requisitions from "../pages/Accountant/Requisitions";
import CreateRequisition from "../pages/Accountant/Requisitions/CreateRequisition";
import CashRequisition from "../pages/Cashier/CashRequisition";
import Cashdrop from "../pages/Cashier/Cashdrop";
import OtherRevenue from "../pages/Cashier/OtherRevenue";
import CashCreateRequisition from "../pages/Cashier/CashRequisition/CashCreateRequisition";
import CashDesk from "../pages/Settings/CashDesk";
import SeeLabDoctor from "../pages/Doctor/DoctorPanel/PatientFromLab/SeeLabDoctor";



const authProtectedRoutes = [
    { path: "/dashboard", component: <Dashboard/> },
    { path: "/profile", component: <Profile/> },
    { path: "/settings", component: <Settings/> },
    { path: "/patients/:param?", component: <Patients/> },
    { path: "/admit-patient", component: <Ipd/> },
    { path: "/patients/details/:id/:param", component: <PatientDetail/> },
    { path: "/patients/history/:id", component: <PatientHistory/> },
    { path: "/patients/history_details/:id", component: <HistoryDetails/> },
    { path: "/patients/create/:id?/:param?/:action?", component: <NewPatient/> },
    { path: "/patients/history/vitals/:id/:record?", component: <MainPatientVitals/> },
    { path: "/settings/user_management", component: <UserManagement/> },
    { path: "/settings/setting_zone", component: <SettingZone/> },
    { path: "/settings/record_expenditure", component: <RecortExpend/> },
    { path: "/settings/record_income", component: <RecordIncome/> },
    { path: "/settings/payments_mode", component: <PaymentsMode/> },
    { path: "/settings/wards", component: <Wards/> },
    { path: "/settings/beds", component: <Beds/> },
    { path: "/settings/productsDiscount", component: <ProductDiscount/> },
    { path: "/settings/barcode", component: <Barcode/> },
    { path: "/settings/print", component: <Print/> },
    { path: "/settings/taxe_rates", component: <TaxRates/> },
    { path: "/settings/warehouse-branchs", component: <Warehouse/> },
    { path: "/settings/modules", component: <Modules/> },
    { path: "/settings/company_settings", component: <CompanySettings/> },
    { path: "/settings/accounting", component: <Accounting/> },
    { path: "/settings/doctors", component: <Doctors/>},
    { path: "/settings/categories", component: <Categories/> },
    { path: "/doctor/doctor-panel-see-patient", component: <DoctorPanel/> },
    { path: "doctor/doctor-panel/appointment", component: <Appointments/> },
    { path: "/doctor/doctor-panel/see-vitals/:id/:ref/:name", component: <SeeVitals/> },
    { path: "/doctor/doctor-panel/see-lab-record/:id/:ref/:name", component: <SeeLabDoctor/> },
    { path: "/warehouse/item-record", component: <ItemRecord/> },
    { path: "/warehouse/record-supplier", component: <RecordSuppliers/> },
    { path: "/warehouse/purchase-supplier", component: <SuppiersOrder/> },
    { path: "/warehouse/purchase-supplier-order/:id?/:action?", component: <NewSupplerOrder /> },
    { path: "/warehouse/transfert-order", component: <TransfertOrder/> },
    { path: "/warehouse/transfert-order-new/:id?/:action?", component: <NewTransfertOrder /> },
    { path: "/pharmacy/invoices-list", component: <PhInvoiceList/> },
    { path: "/pharmacy/occupied_wards/details/:id?/:patientId?/:wardNo?", component: <PhInvoiceDetails/>},
    { path: "/pharmacy/occupied_wards", component: <PhOccupiedWards/> },
    { path: "/pharmacy/transfert-order", component: <PhTransfertOrder/> },
    { path: "/pharmacy/transfert-order-new/:id?/:action?", component: <PhNewTransfertOrder /> },
    { path: "/pharmacy/stock-reports", component: <PhStockReports /> },
    { path: "/warehouse/stock-reports", component: <StockReports /> },
    { path: "/settings/cash-desk", component: <CashDesk /> },
    { path: "/settings/samples", component: <Samples/> },
    { path: "/settings/remarks", component: <Remarks/> },
    { path: "/lab/lab-record", component: <LabRecord/> },
    { path: "/lab/lab-record/detail/:id?", component: <LabRecordDetail/> },
    { path: "/cashier/all-sales/:section?", component: <AllSales/> },
    { path: "/cashier/all-sales/patientAccount/:id?/:accountRef?/:balance?", component: <PatientAccountDetail /> },
    { path: "/settings/financial-year", component: <FinancialYear/> },
    { path: "/accounting/banking", component: <Banking /> },
    { path: "/accounting/requisitions", component: <Requisitions /> },
    { path: "/accounting/requisitions/create/:action?/:id?", component: <CreateRequisition /> },
    { path: "/cashier/requisitions", component: <CashRequisition/> },
    { path: "/cashier/requisitions/create/:action?/:id?", component: <CashCreateRequisition/> },
    { path: "/cashier/cashdrop", component: <Cashdrop/> },
    { path: "/cashier/other-revenue", component: <OtherRevenue /> },


    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/dashboard" />,
    },
    { path: "*", component: <Navigate to="/dashboard" /> },
]

const publicRoutes = [
    { path: "/login", component: <Login/> },
]

export { authProtectedRoutes, publicRoutes };