import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import * as Yup from "yup"
import { useFormik } from 'formik'
import { Col, Input, Label, Row, Form, FormFeedback, Modal, ModalBody, ModalHeader } from 'reactstrap'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {
    getConsultationList as onGetConsultationList,
    getAllDoctors as onGetAllDoctors,
    createOdpAppointment as onCreateOdpAppointment,
    markPatientConsultedDoctor as onMarkPatientConsulted
} from "../../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import TableContainer from '../../../components/Common/TableContainer'
import { Link } from 'react-router-dom'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const MainConsultList = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const outputLayoutState = (state) => state.Outputs
    const OutputsProperties = createSelector(
        outputLayoutState,
        (output) =>({
            consultationList: output.consultationList,
            odpAppointment: output.odpAppointment,
            createOdpApointmentSuccess: output.createOdpApointmentSuccess,
            mark_patient_consulted_success: output.mark_patient_consulted_success,
            error: output.error
        })
    )
    const { consultationList, createOdpApointmentSuccess, mark_patient_consulted_success, odpAppointment, error } = useSelector(OutputsProperties)
    const [status, setStatus] = useState({label: 'All', value: 'all'})
    const [searchInput, setSearchInput] = useState('')
    const [currentAppointment, setCurrentAppointment] = useState(null)
    const [modal, setModal] = useState(false)
    const [isConsult, setIsConsult] = useState(false)
    const [ remark, setRemark ] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const fp = useRef(null);
    const [periods, setPeriods] = useState([
        // moment().startOf("days").format("YYYY-MM-DD"),
        // moment().format("YYYY-MM-DD")
    ]);
    const statusList = [
        {label: props.t("all"), value: 'all'},
        {label: props.t("yes"), value: 'yes' },
        {label: props.t("no"), value: 'no' },
    ]

    const loadConsultationList = useCallback((pageNumber, pageSize, term) => {
        if (pageNumber && pageSize) {
            if (term) {
                if (periods.length > 0) {
                    dispatch(onGetConsultationList({page: pageNumber, size: pageSize, fromDate: moment(periods[0]).format("YYYY-MM-DD"), toDate: moment(periods[1]).format("YYYY-MM-DD"), status: status.value, term: searchInput}))
                } else {
                    dispatch(onGetConsultationList({page: pageNumber, size: pageSize, status: status.value, term: searchInput}))
                }
            }
            else {
                if (periods.length > 0) {
                    dispatch(onGetConsultationList({page: pageNumber, size: pageSize, fromDate: moment(periods[0]).format("YYYY-MM-DD"), toDate: moment(periods[1]).format("YYYY-MM-DD"), status: status.value}))
                }else {
                    dispatch(onGetConsultationList({page: pageNumber, size: pageSize, status: status.value}))
                }
            }
        } else {
            if (term) {
                dispatch(onGetConsultationList({page: 1, size: 10, status: status.value, term: searchInput}))
            }
            else {
                dispatch(onGetConsultationList({page: 1, size: 10, status: status.value}))
            }
        }
    })

    const setPatientConsulted = useCallback(() => {
        dispatch(onMarkPatientConsulted({ id: currentAppointment.id, body: { remark: remark} }))
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setIsConsult(false)
          setCurrentAppointment(null);
        } else {
          setModal(true);
        }
    }, [modal])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            doctorId: (currentAppointment && currentAppointment.doctor && currentAppointment.doctor.id) || '',
            appointmentDate: (currentAppointment && currentAppointment.appointmentDate) || '',
            patientRecordId: (currentAppointment && currentAppointment.patient && currentAppointment.patient.patientRecord && currentAppointment.patient.patientRecord.id) || '',
        },
        validationSchema: Yup.object({
            appointmentDate: Yup.string().required(props.t("you_need_provide_date")),
        }),
        onSubmit: (values) => {
            const updateAppointment = {
                appointmentDate: values.appointmentDate,
                doctorId: currentAppointment.doctor.id,
                patientRecordId: currentAppointment.patient.patientRecord.id
            }
            dispatch(onCreateOdpAppointment(updateAppointment))
        },
    })

    useEffect(() => {
        if (props.isActive) {
            loadConsultationList()
            onGetAllDoctors()
        }
    },[props.isActive])

    useEffect(() => {
        if (mark_patient_consulted_success && !error) {
            loadConsultationList()
            setCurrentAppointment(null)
            setIsConsult(false)
            setRemark('')
            toggle()
        }
    },[mark_patient_consulted_success])

    useEffect(() => {
        if (consultationList && consultationList.page) {
          setIsLoading(false)
        }
    },[consultationList])

    useEffect(() => {
        // if ((periods !== null && periods.length > 0) && (status !== null) && props.isActive) {
            if ((searchInput.length !== null) && (searchInput.length > 2) ) {
                if (periods.length > 0) {
                    loadConsultationList(1, 10, moment(periods[0]).format("YYYY-MM-DD"), moment(periods[1]).format("YYYY-MM-DD"), status, searchInput)
                } else {
                    loadConsultationList(1, 10, status, searchInput)
                }
            } else {
                if (periods.length > 0) {
                    loadConsultationList(1, 10, moment(periods[0]).format("YYYY-MM-DD"), moment(periods[1]).format("YYYY-MM-DD"), status)
                } else {
                    loadConsultationList(1, 10, status)
                }
            }
        // } else if (periods.length <= 0) {
        //     loadConsultationList()
        // }
    },[periods, status])

    useEffect(() =>{
        if (createOdpApointmentSuccess && !error) {
          validation.resetForm()
          setModal(false)
          setCurrentAppointment(null)
          loadConsultationList()
        }
    },[createOdpApointmentSuccess, error, odpAppointment])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleSelectAppointment = (arg) => {
        setCurrentAppointment(arg)
        toggle()
    }

    const handleSelectConsultation = (arg) => {
        console.log(arg);

        setCurrentAppointment(arg)
        setIsConsult(true)
        toggle()
    }


    function handleSelectStatus(selectedStatus) {
        setStatus(selectedStatus)
    }

    function handleSearch () {
        loadConsultationList(1, 10, searchInput)
    }

    const columns = useMemo(
        () => [
            {
                header: props.t("patient_id"),
                accessorKey: "patientRef",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.patient !== null )? "#"+cell.row.original.patient.patientRef : ''}</span>
                }
            },
            {
                header: props.t("patients_name"),
                accessorKey: "firstName",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.patient !== null )? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : ''} </span>
                }
            },
            {
                header: props.t("doctor_consultant"),
                accessorKey: "qweasd",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.doctor !== null )? "Dr."+cell.row.original.doctor.fullName : ''} </span>
                }
            },
            {
                header: props.t("date"),
                accessorKey: "consultedAt",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        <span>
                            { moment(cell.row.original.consultedAt).format('DD/MM/YYYY') }
                        </span>
                    )
                }
            },
            {
                header: props.t("status"),
                accessorKey: "status",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        // <div className="d-flex justify-content-end mr-5">
                            <span className='text-bold'>
                                {(cell.row.original.status == false )? props.t("not_consulted") : props.t("consulted")}
                            </span>
                        // </div>
                    )
                }
            },
            {
                header: 'Consultation',
                accessorKey: "consultationPaid",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        // <div className="d-flex justify-content-end mr-5">
                            <span className='text-bold'>
                                {(cell.row.original.consultationPaid == false )? 'Not Paid' : 'Paid'}
                            </span>
                        // </div>
                    )
                }
            },
            {
                header: props.t("action"),
                style: "text-start",
                cell: (cellProps) => {
                    return (
                        <div className="d-flex">
                            <ul className="list-inline hstack mb-0">
                                {!cellProps.row.original.status  ?
                                    <>
                                        <li className="list-inline-item edit" title={props.t("register_appointment")}>
                                            <>
                                                <Link to=""
                                                    onClick={() => { const appointmentData = cellProps.row.original; handleSelectAppointment(appointmentData); }}
                                                >
                                                    <p className="btn btn-light text-primary" style={{fontSize: "15px" }}>
                                                        <i className='fa fa-plus-square p-0' alt={props.t("register_appointment")} style={{fontSize: "15px" }}></i>
                                                    </p>
                                                </Link>
                                            </>
                                        </li>
                                        <li className="list-inline-item edit" title={props.t("mark_as_consulted")}>
                                            <>
                                                <Link to=""
                                                    onClick={() => { const appointmentData = cellProps.row.original; handleSelectConsultation(appointmentData); }}
                                                >
                                                    <p className="btn btn-light text-success" style={{fontSize: "15px" }}>
                                                        <i className='fa fa-check-square p-0' alt={props.t("mark_as_consulted")}></i>
                                                    </p>
                                                </Link>
                                            </>
                                        </li>
                                    </>
                                    :
                                    <></>
                                }
                            </ul>
                        </div>
                    )
                }
            }
        ],
        []
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
        :
            <>
                <div className="mt-3" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <Row>
                        <Col xl={4} sm={4} className="">
                            <div className='mb-2'>
                                <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                                <div className='d-flex align-items-center'>
                                    <Flatpickr
                                        className='flatpick-custom'
                                        id="inputGroupSelect01"
                                        ref={fp}
                                        placeholder={props.t("select_date_range")}
                                        defaultValue={periods}
                                        value={ periods }
                                        options={{
                                            altInput: true,
                                            altFormat: "d / m/ Y",
                                            maxDate: new Date(),
                                            mode: "range",
                                        }}
                                        onChange={(e) => {
                                            if (e.length > 1) {
                                                setPeriods(e)
                                            }
                                        }}
                                    />
                                    <button type="button" className="btn btn-light mx-1" onClick={() => { if (!fp?.current?.flatpickr) return; fp.current.flatpickr.clear(); setPeriods([]) }} >
                                        {props.t("clear")}
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("see_doctor")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={status}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={(e) => {
                                        handleSelectStatus(e);
                                    }}
                                    options={statusList}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("search_patient")}</Label><br/>
                                <div className="d-flex align-items-center">
                                    <div className="search-box me-2 mb-1 col-10">
                                        <Input
                                            className="custom-border form-control"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                    </div>
                                    <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                        <i className="fa fa-search p-0"></i>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="send-patient-to-doctor-main-sec pb-3">
                            <TableContainer
                                columns={columns}
                                data={((consultationList && consultationList.items) || [])}
                                isGlobalFilter={false}
                                customPageSize={10}
                                divClass="table-card-ward"
                                tableClass="align-middle"
                                theadClass="thead-light table-light"
                                tableSize={ consultationList && consultationList.size }
                                tablePage={ consultationList && consultationList.page }
                                tableTotal= { consultationList && consultationList.total_items }
                                tableTotalPage= { consultationList && consultationList.total_page }
                                onNextPage= {loadConsultationList}
                                onSearch= {loadConsultationList}
                                SearchPlaceholder={props.t("search")}
                            />
                    </div>
                    <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {isConsult ? props.t("mark_as_consulted") : props.t("create_appointment")}
                        </ModalHeader>
                        <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <ModalBody>
                                <input type="hidden" id="id-field" />
                                <Row>
                                    {!isConsult ?
                                        <Col xl={12} sm={12}>
                                            <div className='mb-2 mt-1'>
                                                <Label htmlFor="appointmentDate-field" className="form-label">{props.t("appointment_date")}<span className='text-danger'>*</span></Label><br/>
                                                <Input
                                                    name="appointmentDate" id='appointmentDate-field' className="form-control" type="date"
                                                    validate={{ required: { value: true } }} min={getTodayDate()} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.appointmentDate || ""}
                                                    autoComplete="off"
                                                    invalid={
                                                        validation.touched.appointmentDate && validation.errors.appointmentDate ? true : false
                                                    }
                                                />
                                                {validation.touched.appointmentDate && validation.errors.appointmentDate ? (
                                                    <FormFeedback type="invalid">{validation.errors.appointmentDate}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        :
                                        <Col>
                                            <div className='mb-2 mt-1'>
                                                <Label htmlFor="remark-field" className="form-label">{props.t("remark")}<span className='text-danger'>*</span></Label><br/>
                                                <Input
                                                    name="remark"
                                                    id='remark-field'
                                                    className="form-control"
                                                    type="text"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </ModalBody>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); setIsConsult(false); validation.resetForm() }}> {props.t("close")} </button>
                                    {!isConsult ?
                                        <button type="submit" className="btn btn-success"> {props.t("save")} </button>
                                    :
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => { setPatientConsulted()}}
                                            disabled={remark.length >0 ? false : true}
                                        >
                                            {props.t("save")}
                                        </button>
                                    }
                                    </div>
                                </div>
                                </Row>
                            </div>
                        </Form>

                    </Modal>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(MainConsultList))
