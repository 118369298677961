import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
    getCashDrops as onGetCashDrops,
    createCashDrop as onCreateCashDrop,
    getAllBankAccounts as onGetAllBankAccounts,
    getPaymentMode as onGetPaymentMode,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"

const Cashdrop = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        cashdrops: setting.cashdrops,
        cashdrop: setting.cashdrop,
        allBankingAccounts: setting.allBankingAccounts,
        paymentModes: setting.paymentModes,
        createCashDropSuccess: setting.createCashDropSuccess,
        error: setting.error
      })
    )
    const { cashdrops, cashdrop, allBankingAccounts, createCashDropSuccess, paymentModes, error } = useSelector(SettingsProperties)
    const [BankAccountOption, setBankAccountOption] = useState([])
    const [paymentModeOption, setPaymentModeOption] = useState([])
    const [selectedBankAccount, setSelectedBankAccount] = useState(null)
    const [selectedPaymentMode, setSelectedPaymentMode] = useState(null)
    const [currentCashdrop, setCurrentCashdrop] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [isView, setIsView] = useState(false)


    const loadCashDrop = useCallback(() => {
        dispatch(onGetCashDrops()) 
    })

    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    })

    const loadClassAccount = useCallback(() => {
        dispatch(onGetAllBankAccounts())
    })

    const toggle = useCallback(() => {
      if (modal) {
        setModal(false);
        validation.resetForm();
        setCurrentCashdrop(null);
      } else {
        setModal(true);
      }
    }, [modal])

    const validation = useFormik({
      enableReinitialize: true,
  
      initialValues: {
        id: (currentCashdrop && currentCashdrop.id) || '',
        amount: (currentCashdrop && currentCashdrop.amount) || '',
        bankAccountId: (currentCashdrop && currentCashdrop.bankAccountId) || '',
        description: (currentCashdrop && currentCashdrop.description) || '',
        transactionDate: (currentCashdrop && currentCashdrop.transactionDate) || '',
        paymentMode: (currentCashdrop && currentCashdrop.paymentMode) || '',
      },
      validationSchema: Yup.object({
        amount: Yup.string().required(props.t("you_need_provide_amount")),
      }),
      onSubmit: (values) => {
  
          const newForm = {
            amount: values.amount,
            description: values.description,
            transactionDate: values.transactionDate,
            bankAccountId: selectedBankAccount && selectedBankAccount.value,
            paymentMode: selectedPaymentMode && selectedPaymentMode.value,
          }
  
          console.log(newForm);
          
          dispatch(onCreateCashDrop(newForm))
      },
    })
  
    const handleOpen = () => {
      setIsView(false)
      toggle()
    }

    useEffect(() => {
      loadCashDrop()
      loadPaymentMode()
      loadClassAccount()
    },[dispatch])

    useEffect(() => {
      if (cashdrops && cashdrops.page >= 0) {
      setIsLoading(false)
      }
    },[cashdrops])
  
    useEffect(() => {
      if (paymentModes && paymentModes.length > 0) {
          const payment_lists = paymentModes.filter(mode => mode.value !== 'PATIENT_CREDIT').map(mode => ({
              label: mode.title,
              value: mode.value
          }));
          setPaymentModeOption(payment_lists);
      }
    }, [paymentModes]);
  
    useEffect(() =>{
      if (allBankingAccounts && allBankingAccounts.length > 0) {
        const allBankingAccounts_list = allBankingAccounts.map( row => {
          return { label: row.bankName, value: row.id }
        })
        setBankAccountOption(allBankingAccounts_list)
      }
    },[allBankingAccounts])

    useEffect(() => {
      if (createCashDropSuccess && !error) {
        loadCashDrop()
        toggle()
      }
    },[createCashDropSuccess])

    const handleSelectCashDrop = (arg) => {
      setCurrentCashdrop(arg)
      setSelectedBankAccount(arg.classAccountId && (BankAccountOption != null) ? BankAccountOption.find((element) => element.value === arg.classAccountId) : null)
      setSelectedPaymentMode(arg.paymentMode && (paymentModeOption != null) ? paymentModeOption.find((element) => element.value === arg.paymentMode) : null )
  
      setIsView(true)
      toggle()
    }

    const columns = useMemo(
      () => [
        {
          header: props.t("no"),
          style: "cat",
          enableColumnFilter: false,
          cell: (cellProps) => {
            return <span>
                {cashdrops.page == 1 ? (cellProps.row.index + 1) : (((cashdrops.page-1)*10)+cellProps.row.index + 1) }
            </span>;
          },
        },
        {
            header: props.t("transaction_date"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                    {moment(cell.row.original.transactionDate).format('DD / MM/ YYYY')}
                </span>;
            },
        },
        {
          header: props.t("transaction_ref"),
          accessorKey: "transactionRef",
          style: "cat",
          enableColumnFilter: false,
        },
        {
          header: props.t("bankName"),
          accessorKey: "bankName",
          style: "cat",
          enableColumnFilter: false,
        },
        {
          header: props.t("payment_mode"),
          accessorKey: "paymentMode",
          style: "cat",
          enableColumnFilter: false,
        },
        {
          header: props.t("reason"),
          accessorKey: "reason",
          style: "cat",
          enableColumnFilter: false,
          cell: (cell) => {
              const truncateText = (text, maxLength) => {
                  if (text.length > maxLength) {
                      return text.substring(0, maxLength) + '...';
                  }
                  return text;
              };
          
              return <span>{truncateText(cell.row.original.reason, 20)}</span>;
          }
        },
        {
          header: props.t("amount"),
          style: "type",
          enableColumnFilter: false,
          cell: (cell) => {
            return <span>{cell.row.original.amount}</span>
          }
        },
        // {
        //   header: props.t("action"),
        //   style: "action",
        //   cell: (cellProps) => {
        //     return (
        //       <div className="d-flex justify-content-center">
        //           <ul className="list-inline hstack mb-0">
        //               <li className="list-inline-item edit" title="Edit">
        //                   <Link
        //                   to="#"
        //                   className="text-primary d-inline-block edit-item-btn"
        //                   onClick={() => { const accountData = cellProps.row.original; handleSelectAccount(accountData); }}
        //                   >
        //                   <img src="../assets/images/edit.png" alt="" className="me-2"></img>
        //                   </Link>
        //               </li>
        //               <li className="list-inline-item" title="Remove">
        //                   <Link
        //                   to="#"
        //                   className="text-danger d-inline-block remove-item-btn"
        //                   onClick={() => { const accountData = cellProps.row.original; onClickDelete(accountData); }}
        //                   >
        //                   <img src="../assets/images/delete.png" alt=""></img>
        //                   </Link>
        //               </li>
        //           </ul>
        //       </div>
        //     );
        //   },
        // },
      ],
      [cashdrops, handleSelectCashDrop]
    );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <div className="patients-list-content">
              <div className="patients-list px-2 pb-5 ">
                  <div className="container-fluid ">
                      <div className="heading-parent-sec ">
                          <div className="heading-child-one-sec pt-4">
                              <h6>{props.t("cash_drops")}</h6>
                          </div>
                          <div className="heading-child-two-sec pt-4">
                              <div className="create-new-patient-btn-child-two">
                                <SinpleActionButton title={props.t("make_cash_drop")} img="../assets/images/user.png" action={handleOpen} />
                              </div>
                          </div>
                      </div>
                      <div className="card border-0 row p-2">
                        <div className="row">
                                <TableContainerWarehouse
                                columns={columns}
                                data={((cashdrops && cashdrops.items) || [])}
                                isGlobalFilter={false}
                                customPageSize={10}
                                divClass="card table-card table-warehouse table-responsive"
                                tableClass="table"
                                theadClass="thead-sec table-light"
                                tdClass="td"
                                tableSize={ cashdrops && cashdrops.size }
                                tablePage={ cashdrops && cashdrops.page }
                                tableTotal= { cashdrops && cashdrops.total_items }
                                tableTotalPage= { cashdrops && cashdrops.total_page }
                                onNextPage= {loadCashDrop}
                                onSearch= {loadCashDrop}
                                SearchPlaceholder={props.t("search_cash_rop")}
                                />
                        </div>
                      </div>
                      </div>
              </div>
          </div>

          <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {isView ? "" : props.t("make_cash_drop")}
            </ModalHeader>
            <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
              <ModalBody>
                <Row>
                  <Col xl={6} sm={6}>
                    <label htmlFor="">{props.t("amount")}<span className='text-danger'>*</span></label>
                    <Input 
                        className="p-3" 
                        type="number" 
                        id="amount" 
                        name="amount"
                        placeholder=""
                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        invalid={
                            validation.touched.amount && validation.errors.amount ? true : false
                        }
                        disabled= { isView ? true : false }
                    />
                    {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                    ) : null}
                  </Col>
                  <Col xl={6} sm={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="transactionDate-field"
                        className="form-label"
                      >
                        {props.t("transaction_date")}<span className='text-danger'>*</span>
                      </Label>
                      <Input
                        name="transactionDate"
                        id="transactionDate-field"
                        className="form-control"
                        autoComplete="off"
                        placeholder={props.t("date")}
                        type="date"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transactionDate || ""}
                        invalid={
                          validation.touched.transactionDate && validation.errors.transactionDate ? true : false
                        }
                      />
                      {validation.touched.transactionDate && validation.errors.transactionDate ? (
                        <FormFeedback type="invalid">{validation.errors.transactionDate}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={6} sm={6}>
                      <div className="mb-3">
                      <Label
                          htmlFor="wards-field"
                          className="form-label"
                      >
                          {props.t("chose_bank")}
                      </Label>
                      <Select
                          name='accountType'
                          id='accountType-field'
                          value={selectedBankAccount}
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off" 
                          // className='p-0'
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                              setSelectedBankAccount(e);
                          }}
                          options={BankAccountOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled= { isView ? true : false }
                      />
                      </div>
                  </Col>
                  <Col xl={6} sm={6}>
                      <div className="mb-3">
                      <Label
                          htmlFor="paymentMode-field"
                          className="form-label"
                      >
                          {props.t("payment_mode")}
                      </Label>
                      <Select
                          name='paymentMode'
                          id='paymentMode-field'
                          value={selectedPaymentMode}
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off" 
                          // className='p-0'
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                              setSelectedPaymentMode(e);
                          }}
                          options={paymentModeOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled= { isView ? true : false }
                      />
                      </div>
                  </Col>
                  <Col  xl={12} sm={12}>
                      <label for="">{props.t("description")}</label>
                      <textarea
                          className="p-3"
                          type="text"
                          id="description"
                          name="description"
                          placeholder=""
                          style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                          validate={{
                              required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                              validation.touched.description && validation.errors.description ? true : false
                          }
                          disabled= { isView ? true : false }
                      />
                      {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                  </Col>
                </Row>
              </ModalBody>
              <div className='p-3'>
                <Row className="align-items-center">
                  <div className="col-sm">
                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                  </div>
                  <div className="col-sm-auto">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentCashdrop(null); setIsView(false) }}> {props.t("close")} </button>
                      {!isView ?
                        <button type="submit" className="btn btn-success"> { props.t("save")} </button>
                        :
                        <></>
                      }
                    </div>
                  </div>
                </Row>
              </div>
            </Form>
          </Modal>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Cashdrop))
