import { toast } from 'react-toastify';

import {
    getPatients as getPatientsApi,
    getPatientTypes as getPatientTypesApi,
    getPatien as getPatientApi,
    getRecordPatient as getRecordPatientApi,
    getRecordByPatient as getRecordByPatientApi,
    admitPatient as admitPatientApi,
    addNewPatient as addNewPatientApi,
    updatePatient as updatePatientApi,
    deletePatient as deletePatientApi,
    searchPatient as searchPatientApi,
    getPatientVitals as getPatientVitalsApi,
    updatePatientVitals as updatePatientVitalsApi,
    addNewPatientVitals as addNewPatientVitalsApi,
    getPatientAllVitalsTable as getPatientAllVitalsTableApi,
    getPatientVitalsRecords as getPatientVitalsRecordsApi,
    assignDoctor as assignDoctorApi,
    getBedsByWard as getBedsByWardApi,
    assignBedPatient as assignBedPatientApi,
    getConsultationList as getConsultationListApi,
    getHospitalisationList as getHospitalisationListApi,
    leaveBed as leaveBedApi,
    releasePatient as releasePatientApi,
    getAllDoctors as getAllDoctorsApi,
    changeDoctorToSee as changeDoctorToSeeApi,
    getDoctorToSee as getDoctorToSeeApi,
    getOdpAppointments as getOdpAppointmentsApi,
    getOdpAppointmentDetail as getOdpAppointmentDetailApi,
    createOdpAppointment as createOdpAppointmentApi,
    updateOdpAppointment as updateOdpAppointmentApi,
    deleteOdpAppointment as deleteOdpAppointmentApi,
    markOdpAppointmentAttended as markOdpAppointmentAttendedApi,
    markPatientConsulted as markPatientConsultedApi,
    getPatientForSale as getPatientForSaleApi
} from "../../helpers/backend_helper"
import { createAsyncThunk } from '@reduxjs/toolkit';

/*
* Patients
*/
export const getPatients = createAsyncThunk("outputs/getPatients", async(config) => {
    try {
        const response = await getPatientsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientForSale = createAsyncThunk("output/getPatientForSale", async(term) => {
    try {
        const response = await getPatientForSaleApi(term)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatient = createAsyncThunk("outputs/getPatient", async(config) => {
    try {
        const response = await getPatientApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getRecordPatient = createAsyncThunk("output/getRecordPatient", async(config) => {
    try {
        const response = await getRecordPatientApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getRecordByPatient = createAsyncThunk("output/getRecordByPatient", async(config) => {
    try {
        const response = await getRecordByPatientApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientTypes =  createAsyncThunk("outputs/getPatientTypes", async() => {
    try {
        const response = await getPatientTypesApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const addNewPatient = createAsyncThunk("outputs/addNewPatient", async(data) => {
    try {
        const response = await addNewPatientApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updatePatient = createAsyncThunk("settings/updatePatient", async(config) => {
    try {
        const response = await updatePatientApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const admitPatient = createAsyncThunk('outputs/AdmitPatient', async(config) => {
    try {
        const response = await admitPatientApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const searchPatient = createAsyncThunk('outputs/SearchPatient', async(config) => {
    try {
        const response = await searchPatientApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const deletePatient = createAsyncThunk("outputs/deletePatient", async(config) => {
    try {
        const response = await deletePatientApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

// Patient vitals
export const getPatientVitals = createAsyncThunk("outputs/getPatientVitals", async(id) => {
    try {
        const response = await getPatientVitalsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const addNewPatientVitals = createAsyncThunk("outputs/addNewPatientVitals", async(data) => {
    try {
        const response = await addNewPatientVitalsApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getPatientAllVitalsTable = createAsyncThunk("outputs/getPatientAllVitalsTable", async(config) => {
    try {
        const response = await getPatientAllVitalsTableApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const updatePatientVitals = createAsyncThunk("settings/updatePatientVitals", async(config) => {
    try {
        const response = await updatePatientVitalsApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const getPatientVitalsRecords = createAsyncThunk("outputs/getPatientVitalsRecords", async(id) => {
    try {
        const response = await getPatientVitalsRecordsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Doctor
export const assignDoctor = createAsyncThunk("outputs/assignDoctor", async(config) => {
    try {
        const response = await assignDoctorApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getAllDoctors = createAsyncThunk("outputs/getAllDoctors", async() => {
    try {
        const response = await getAllDoctorsApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Beds
export const getBedsByWard = createAsyncThunk("outputs/getBedsByWard", async(id) => {
    try {
        const response =  await getBedsByWardApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const changeDoctorToSee = createAsyncThunk("outputs/changeDoctorToSee", async(config) => {
    try {
        const response = await changeDoctorToSeeApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getDoctorToSee = createAsyncThunk("outputs/getDoctorToSee", async(id) => {
    try {
        const response = await getDoctorToSeeApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const assignBedPatient = createAsyncThunk("outputs/assignBedPatient", async(config) => {
    try {
        const response = await assignBedPatientApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Consultation list
export const getConsultationList = createAsyncThunk("outputs/getConsultationList", async(config) => {
    try {
        const response =  await getConsultationListApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getHospitalisationList = createAsyncThunk("outputs/getHospitalisationList", async(config) => {
    try {
        const response = await getHospitalisationListApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const leaveBed = createAsyncThunk("output/leaveBed", async(config) => {
    try {
        const response =  await leaveBedApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const releasePatient = createAsyncThunk("output/releasePatient", async(config) => {
    try {
        const response = await releasePatientApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const markPatientConsulted = createAsyncThunk("output/markPatientConsulted", async(config) => {
    try {
        const response = await markPatientConsultedApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})



// Appointments

export const getOdpAppointments = createAsyncThunk("output/getOdpAppointments", async(config) => {
    try {
        const response = await getOdpAppointmentsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getOdpAppointmentDetail = createAsyncThunk("output/getOdpAppointmentDetail", async(config) => {
    try {
        const response = await getOdpAppointmentDetailApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createOdpAppointment = createAsyncThunk("output/createOdpAppointment", async(config) => {
    try {
        const response = await createOdpAppointmentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateOdpAppointment = createAsyncThunk("output/updateOdpAppointment", async(config) => {
    try {
        const response = await updateOdpAppointmentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
}) 

export const deleteOdpAppointment = createAsyncThunk("output/deleteOdpAppointment", async(config) => {
    try {
        const response = await deleteOdpAppointmentApi(config)
         toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const markOdpAppointmentAttended = createAsyncThunk("output/markOdpAppointmentAttended", async(id) => {
    try {
        const response = await markOdpAppointmentAttendedApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})