import React from 'react'
import withRouter from '../../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Modal, ModalHeader } from 'reactstrap'

const GeneralLabReports = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleLab} centered
      className="modal-dialog  modal-dialog-centered modal-xl" style={{ maxWidth: "1140px" }}
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleLab}>
      </ModalHeader>
      <div className="modal-body">
          <div className="gen-lab-rep-print">
              <div className="d-flex justify-content-start m-0 " style={{ gap: "300px" }}>
                  <p className="m-0 p-0">REG. No: ST.VERON52451C</p>
                  <p className="text-danger fw-bold m-0 p-0" style={{ padding: "0", fontSize: "22px" }}>Tax Invoice</p>
              </div>
              <div className="text-center m-0">
                  <div className="d-flex gap-2 justify-content-center m-0 align-items-center">
                      <img src="../assets/images/HMS-Logo 1.png" className="img-fluid" alt="" />
                      <h4 style={{ fontSize: "22px", color: "#d13e6a", fontWeight:"700", margin: "0", padding: "0" }}>ST.
                          VERONICA MEDICAL CENTER</h4>
                  </div>
                  <h5 style={{ color: "#45a049", fontWeight: "500" }}>BOKOKO-BUEA</h5>
                  <div className="d-flex justify-content-center gap-3">
                      <p>Email: St.veronicamedicalcenter@yahoo.com</p>
                      <p>Contact No: +251-9876543</p>
                  </div>
              </div>

              <div className="m-0">
                  <table className="table table-borderless m-0">
                      <tbody style={{ borderTop: "1px solid black" }}>

                          <tr>
                              <th style={{ width: "18%" }}>BILL NO.</th>
                              <td>LAB-756</td>
                              <th>DATE</th>
                              <td>09/02/2024</td>
                          </tr>
                          <tr>
                              <th>PATIENT NAME</th>
                              <td>ALEX THOMAS</td>
                              <th>Pr. By</th>
                              <td>Dr. S. WATSON</td>
                          </tr>
                          <tr>
                              <th>ADDRESS</th>
                              <td colspan="3">BIPINDI, CAMEROON, AFRICA</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div className="m-0">
                  <table className="table table-borderless m-0" >
                      <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                          <th>S.NO.</th>
                          <th>SERVICES NO.</th>
                          <th></th>
                          <th className="text-end" style={{ borderLeft: "1px solid black" }}>AMOUNT</th>
                      </tr>
                      <tr>
                          <td>1</td>
                          <td>THYROID TH1, TH2</td>
                          <td></td>
                          <td className="text-end" style={{ borderLeft: "1px solid black" }}>$85.01</td>
                      </tr>
                      <tr>
                          <td>2</td>
                          <td>BLOOD TEST</td>
                          <td></td>
                          <td className="text-end" style={{ borderLeft: "1px solid black" }}>$148.25</td>
                      </tr>
                      <tr>
                          <td>3</td>
                          <td>HEMOGLOBIN</td>
                          <td></td>
                          <td className="text-end" style={{ borderLeft: "1px solid black" }}>$108.56</td>
                      </tr>
                      {/*  Cash part */}
                      <tr style={{ borderTop: "1px solid black" }}>
                          <th>Cash Amt.</th>
                          <td style={{ textAlign: "end" }}>$341.88</td>
                          <td>Amt. In Words: <b>THREE HUNDRED EIGHT ONLY</b></td>
                          <th style={{ borderLeft: "1px solid black", paddingLeft: "60px" }}>Bill Amt</th>
                          <td className="text-end">$341.88</td>
                      </tr>
                      <tr>
                          <th>Less Dis</th>
                          <td className="text-end">$33.87</td>
                          <td style={{ visibility: "hidden" }}>Amt. In Words: <b>THREE HUNDRED EIGHT ONLY</b></td>
                          <th style={{ borderLeft: "1px solid black", paddingLeft:"60px" }}>Dis Amt</th>
                          <td className="text-end">$33.87</td>
                      </tr>
                      <tr>
                          <th>Net Amt.</th>
                          <td className="text-end">$308.01</td>
                          <td style={{ visibility: "hidden" }}>Amt. In Words: <b>THREE HUNDRED EIGHT ONLY</b></td>
                          <th style={{ borderLeft: "1px solid black", paddingLeft:"60px" }}>Paid Amt.</th>
                          <td className="text-end">$308.01</td>
                      </tr>
                      <tr>
                          <th></th>
                          <td></td>
                          <td style={{ visibility: "hidden" }}>Amt. In Words: <b>THREE HUNDRED EIGHT ONLY</b></td>
                          <th style={{ borderLeft: "1px solid black", paddingLeft:"60px" }}>Bal Amt</th>
                          <td className="text-end">$0.00</td>
                      </tr>
                      {/* signature */}

                  </table>
              </div>
              <div className="m-0 text-center">
                  <table className="table table-borderless m-0" style={{ borderBottom: "1px solid black" }}>
                      <tr style={{ borderTop: "1px solid black" }}>
                          <th style={{ width: "40%" }}>COMPUTERISED LABORATORY</th>
                          <th style={{ width: "30.8%", borderRight: "1px solid black", borderLeft: "1px solid black" }}>
                              WORKING HOURS
                          </th>
                          <th></th>
                      </tr>
                      <tr>
                          <td style={{ width: "43%" }}>All types of Hematology, Biochemistry, Stool Analysis, Urine
                              Analysis & Serology are
                              done here.
                          </td>
                          <td style={{ width: "30%", borderRight: "1px solid black", borderLeft: "1px solid black" }}>
                              Monday To Saturday<br />
                              6:30AM To 5:00PM<br />
                              Sunday 6:30AM To 5:00PM
                          </td>
                          <td className="pt-4" style={{ fontSize: "12px" }}>For St. Veronica Medical Center 
                            <p className="text-center">signature</p>
                          </td>
                      </tr>
                  </table>
              </div>
              
              <div className="text-center">
                  <p className="text fw-bold m-0 p-0 " style={{ fontSize: "22px", color: "#d13e6a" }}>100% GENUINE LAB
                      RESULT</p>
                  <p className="fw-bold text-danger m-0 p-0">Book Your Free Home Blood Sample Collection On: <span
                          className="text-primary" style={{ fontSize: "16px" }}>+251-9876543</span></p>
              </div>
          </div>
      </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(GeneralLabReports))