import React, { useState } from 'react'
import 'react-tabs/style/react-tabs.css';

import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import ActionButton from '../../../components/Global/ActionButton';
import NavHeaderCs from '../../../components/Global/NavHeaderCs';
import Users from './Users';
import Roles from './Roles';

const UserManagement = (props) => {

  const [navObject, setNavObject ] = useState([
    { icon: "fa-user", label: props.t("users"), target: "users-content", active: true},
    { icon: "fa-user", label: props.t("user_roles"), target: "roles-content", active: false }
  ])

  const [navObjectUser, setNavObjectUser ] = useState([
    { icon: "fa-user", label: props.t("users"), target: "users-content", active: true},
  ])

  const [navObjectRole, setNavObjectRole ] = useState([
    { icon: "fa-user", label: props.t("user_roles"), target: "roles-content", active: true }
  ])

  const [activeTab, setActiveTab] = useState('users-content')
  const permission = JSON.parse(localStorage.getItem("perm"));

  

  const handleTabChange = (target) => {
    setActiveTab(target);
  }

  document.title = props.t("user_management_title")
  return (
    <div className="setting-zone-screen-9-sec px-5">
        <div className="container-fluid">
            <div className="id g-3 pt-4 px-4 pb-5">
                <div className="head d-flex justify-content-between">
                    <BreadCrumb title={props.t("user_management")} pageTitle={props.t("settings")} />
                </div>
                <NavHeaderCs 
                  navData={permission && permission.includes("ALL_PERMISSIONS") ? navObject :
                    permission && (permission.includes("VIEW_USERS") || permission.includes("DELETE_USERS") || permission.includes("ADD_USERS") || permission.includes("UPDATE_USERS")) ? navObjectUser :
                    permission && (permission.includes("VIEW_ROLES") || permission.includes("DELETE_ROLES") || permission.includes("ADD_ROLES") || permission.includes("UPDATE_ROLES")) ? navObjectRole : []} 
                  onTabChange={handleTabChange}
                />

                <div className="setting-zone-screen-9">
                    <div className="tab-content" id="pills-tabContent">
                        <div className={`tab-pane fade ${activeTab === 'users-content' ? 'show active' : ''}`} id="users-content" role="tabpanel" aria-labelledby="pills-home-tab">
                            <Users isActive={activeTab === 'users-content'}/>
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'roles-content' ? 'show active' : ''}`} id="roles-content" role="tabpanel" aria-labelledby="pills-profile-tab">
                          <Roles isActive={activeTab === 'roles-content'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(UserManagement))
