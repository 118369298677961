import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'

import WardBedStatus from './wardBedStatus'
import AdmitPatients from './AdmitPatients'

const Ipd = (props) => {

    const [activeTab, setActiveTab] = useState('admit-content')
    const handleTabChange = (target) => {
        setActiveTab(target);
    }

  return (
    <div className='patients-list py-4 px-2'>
      <div className="container">
        <div className="patient-bill-and-discharge-sec">
            <div className="container">
                <div className="heading-sec d-flex justify-content-between">
                    <h6>{props.t("ipd")}</h6>
                </div>

                <ul className="nav nav-pills justify-content-between" id="pills-tab" role="tablist">
                    <div className="d-flex ">
                        <li className="nav-item" role="presentation">
                            <button className={ activeTab === "admit-content" ? "nav-link active" : "nav-link" } id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" type="button" role="tab"
                                aria-controls="pills-home" aria-selected="true" onClick={() => {handleTabChange("admit-content")}}>Admit Patients</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={ activeTab === "ward-content" ? "nav-link active" : "nav-link" } id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile" type="button" role="tab"
                                aria-controls="pills-profile" aria-selected="false" onClick={() => {handleTabChange("ward-content")}}>Ward/Bed Status</button>
                        </li>
                    </div>

                </ul>

                <div className="tab-content" id="pills-tabContent">

                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <AdmitPatients  isActive={activeTab === 'admit-content'}/>
                    </div>

                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <WardBedStatus  isActive={activeTab === 'ward-content'}/>
                    </div>
                </div>

            </div>
        </div>
        {/* <!-- Output Patients End --> */}

</div>
    </div>
  )
}

export default withRouter(withTranslation()(Ipd))
