import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const TaxRates = (props) => {
  return (
    <div>TaxRates</div>
  )
}

export default withRouter(withTranslation()(TaxRates))
