import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const Warehouse = (props) => {
  return (
    <div>Warehouse</div>
  )
}

export default withRouter(withTranslation()(Warehouse))
