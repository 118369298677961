import { combineReducers } from "redux"
import LoginReducer from "./auth/reducer"
import SettingsReducer from "./settings/reducer"
import OutputsReducer from "./Outputs/reducer"
import DoctorsReducer from "./Doctors/reducer"
import LaboratoryReducer from "./Laboratory/reducer"


const rootReducer = combineReducers({
    Login: LoginReducer,
    Settings: SettingsReducer,
    Outputs: OutputsReducer,
    Doctors: DoctorsReducer,
    Laboratory: LaboratoryReducer
})

export default rootReducer;