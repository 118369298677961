import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getProductsDiscount as onGetProductsDiscount,
  getPatientTypes as onGetPatientTypes,
  getProductsAndSearch as onGetProductsAndSearch,
  getProductDiscount as onGetProductDiscount,
  createProductDiscount as onCreateProductDiscount,
  updateProductDiscount as onUpdateProductDiscount,
  deleteProductDiscount as onDeleteProductDiscount,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const ProductDiscount = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings
  const selectLayoutStatePatient = (state) => state.Outputs
  
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      productsDiscount: setting.productsDiscount,
      productDiscount: setting.productDiscount,
      productsList: setting.productsList,
      createProductDiscountSuccess: setting.createProductDiscountSuccess,
      updateProductDiscountSuccess: setting.updateProductDiscountSuccess,
      deleteProductDiscountSuccess: setting.deleteProductDiscountSuccess,
      error: setting.error
    })
  )
  const OutputsProperties = createSelector(
    selectLayoutStatePatient,
      (output) =>({
          patientTypes: output.patientTypes,
      })
  )

  const { productsDiscount, productDiscount, productsList, createProductDiscountSuccess, updateProductDiscountSuccess, deleteProductDiscountSuccess, error } = useSelector(SettingsProperties)
  const { patientTypes } = useSelector(OutputsProperties)

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [TypesOptions, setTypesOptions] = useState([])
  const [productsOption, setProducstOption] = useState(null)
  const [selectedTypes, setselectedTypes] = useState(null)
  const [currentDiscount, setCurrentDiscount] = useState(null)
  const [selectedProduct, setSellectedProduct] = useState(null)
  const [errorProduct, setErrorProduct] = useState(false)
  const [errorType, setErrorType] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"))

  const loadProductsDiscount = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetProductsDiscount({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetProductsDiscount())
    }
  })

  const loadPatientTypes = useCallback(() => {
    dispatch(onGetPatientTypes())
  })

  const loadAllProduct = useCallback((term) => {
    if (term && term.length > 0) {
      dispatch(onGetProductsAndSearch(term))
    } else {
      dispatch(onGetProductsAndSearch())
    }
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setCurrentDiscount(null)
      setselectedTypes(null)
      setErrorProduct(false)
      setErrorType(false)
    } else {
      setModal(true);
    }
  }, [modal])
  
  const searchDiscounts = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetProductsDiscount({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetProductsDiscount({term: term, page: 1, size: 10}))
    }
  },[])


  useEffect(() =>{
    loadProductsDiscount()
    loadPatientTypes()
    loadAllProduct()
  },[dispatch])

  useEffect(() =>{
    if (patientTypes && patientTypes.length > 0) {
      const types_list = patientTypes.map( type => {
        return { label: type.typeName, value: type.typeName }
      })
      setTypesOptions(types_list)
    }
  },[patientTypes])

  useEffect(() => {
    if (productsDiscount && productsDiscount.page) {
      setIsLoading(false)
    }
  },[productsDiscount])

  useEffect(() =>{
    if (productsList && productsList.length > 0) {
      const products_list = productsList.map( prod => {
        return { label: prod.name, value: prod.id, price: prod.purchasePrice }
      })
      setProducstOption(products_list)
    }
  },[productsList])


  useEffect(() => {
    if (createProductDiscountSuccess && !error) {
      setIsEdit(false)
      setselectedTypes(null)
      setSellectedProduct(null)
      validation.resetForm()
      setModal(false)
      setCurrentDiscount(null)
      loadProductsDiscount()
    }
  },[createProductDiscountSuccess, error, productDiscount])

  useEffect(() => {
    if (updateProductDiscountSuccess && !error) {
      setIsEdit(false)
      setselectedTypes(null)
      setSellectedProduct(null)
      validation.resetForm()
      setModal(false)
      setCurrentDiscount(null)
      loadProductsDiscount()
    }
  },[updateProductDiscountSuccess, error, productDiscount])


  useEffect(() => {
    if (selectedProduct && selectedProduct.label) {
      setErrorProduct(false)
    }
  },[selectedProduct])

  useEffect(() => {
    if (selectedTypes && selectedProduct) {
      setErrorType(false)
    }
  },[selectedTypes])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentDiscount && currentDiscount.id) || '',
      productId: (currentDiscount && currentDiscount.discount) || '',
      description: (currentDiscount && currentDiscount.description) || '',
      discount: (currentDiscount && currentDiscount.discount) || '',
      patientType: (currentDiscount && currentDiscount.patientType.id) || '',
    },
    validationSchema: Yup.object({
      discount: Yup.string().required(props.t("you_need_provide_discount")),
      // bedCharge: Yup.string().required(props.t("you_need_provide_bedCharge")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateForm = {
          id: currentDiscount && currentDiscount.id,
          discount: values.discount,
          description: values.description,
          patientType: selectedTypes.value,
          productId: selectedProduct.value
        }
        const id = currentDiscount.id

        dispatch(onUpdateProductDiscount({ data: updateForm, id: id }))

      } else {
        const newForm = {
          discount: values.discount,
          description: values.description,
          patientType: selectedTypes.value,
          productId: selectedProduct.value
        }
        dispatch(onCreateProductDiscount(newForm))
      }
      
    },
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectDiscount = (arg) => {
    const selectedDiscount = arg
    setCurrentDiscount({
      id: selectedDiscount.id,
      discount: selectedDiscount.discount,
      description: selectedDiscount.description,
      patientType: selectedDiscount.patientType
    })
    setSellectedProduct({ label: selectedDiscount.product && selectedDiscount.product.name, value: selectedDiscount.product && selectedDiscount.product.id })
    setselectedTypes({ label: selectedDiscount.patientType, value: selectedDiscount.patientType })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (ward) => {
    setCurrentDiscount(ward);
    setDeleteModal(true);
  }

  function handleDeleteDiscount() {
    if (currentDiscount) {
      console.log("currentDiscount: ", currentDiscount);
      dispatch(onDeleteProductDiscount({ data: currentDiscount, id: currentDiscount.id }))
      loadProductsDiscount()
      setDeleteModal(false)
    }
  }

  function handleSelectType(selectedTypes) {
    setselectedTypes(selectedTypes)
  }

  const handleSelectProduct = (sup) => {
      setSellectedProduct(sup)
  }

  const handleSearchProduct = (value) => {
    loadAllProduct(value)
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("product_name"),
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{(cell.row.original.product != null )? cell.row.original.product.name : ''}</span>
        }
      },
      {
        header: props.t("discount"),
        accessorKey: "discount",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("patient_type"),
        style: "button",
        accessorKey: "patientType",
        enableColumnFilter: false,
        // cell: (cell) => {
        //   return <span>{(cell.row.original.patientType != null )? cell.row.original.patientType.typeName : ''}</span>
        // }
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_BEDS")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const discountData = cellProps.row.original; handleSelectDiscount(discountData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_BEDS")) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const discountData = cellProps.row.original; onClickDelete(discountData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectDiscount]
  );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteDiscount} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                          <BreadCrumb title={props.t("product_discounts")} pageTitle={props.t("settings")} />
                          </div>
                          <div className="col-sm-auto new-mode-btn">
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_PRODUCT_DISCOUNTS")) ?
                              <SinpleActionButton title={props.t("create_product_discount")} img="../assets/images/user.png" action={handleOpen} />
                              :<></>
                            }
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((productsDiscount && productsDiscount.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ productsDiscount && productsDiscount.size }
                                    tablePage={ productsDiscount && productsDiscount.page }
                                    tableTotal= { productsDiscount && productsDiscount.total_items }
                                    tableTotalPage= { productsDiscount && productsDiscount.total_page }
                                    onNextPage= {loadProductsDiscount}
                                    onSearch= {searchDiscounts}
                                    SearchPlaceholder={props.t("search_discount")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_product_discount") : props.t("create_product_discount")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={6} sm={6}>
                                  <div className='mb-3'>
                                    <Label  htmlFor="product-field" className="form-label">
                                        {props.t("product")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Select
                                        name='product'
                                        id='product-field'
                                        value={selectedProduct}
                                        isMulti={false}
                                        isClearable={false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                          handleSelectProduct(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                          if (action === 'input-change') {
                                            if (inputValue.length > 0) {
                                              handleSearchProduct(inputValue)
                                            } else {
                                              loadAllProduct()
                                            }
                                          }
                                        }}
                                        onMenuOpen={() => {
                                          loadAllProduct()
                                        }}
                                        options={productsOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                    {errorProduct ?
                                      <p className="text-danger" style={{ fontSize: "14px" }}>{props.t("you_need_to_select_product")}</p>
                                      : <></>
                                    }
                                  </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                  <div className='mb-3'>
                                  <Label
                                      htmlFor="patientType-field"
                                      className="form-label"
                                    >
                                      {props.t("patient_type")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Select
                                      name='patientType'
                                      id='patientType-field'
                                      value={selectedTypes}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off"
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectType(e);
                                      }}
                                      options={TypesOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                    {errorType ?
                                      <p className="text-danger" style={{ fontSize: "14px" }}>{props.t("you_need_to_select_type")}</p>
                                      : <></>
                                    }
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("discount")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="discount"
                                      id="discount-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("discount")}
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.discount || ""}
                                      invalid={
                                        validation.touched.discount && validation.errors.discount ? true : false
                                      }
                                    />
                                    {validation.touched.discount && validation.errors.discount ? (
                                      <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("description")}
                                    </Label>
                                    <textarea cols="" rows="3"
                                      name="description"
                                      id="description-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("description")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.description || ""}
                                      invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                      }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setselectedTypes(null); setCurrentDiscount(null); setIsEdit(false);  setErrorProduct(false); setErrorType(false) }}> {props.t("close")} </button>
                                    <button 
                                      type="submit" 
                                      className="btn btn-success"
                                      onClick={() =>{
                                        selectedProduct && selectedProduct.label ? setErrorProduct(false): setErrorProduct(true);
                                        selectedTypes && selectedTypes.label ? setErrorType(false) : setErrorType(true)
                                      }}
                                    > 
                                      {!!isEdit ? props.t("update") : props.t("save")} 
                                    </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>
                      </Modal>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ProductDiscount))