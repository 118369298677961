import { createSlice } from "@reduxjs/toolkit";
import {
    getConsultationListDoctor,
    getPatientAllVitals,
    getDoctorsAppointments,
    registerAppointment,
    getAppointmentsDetails,
    updateAppointment,
    deleteAppointment,
    markAppointmentAttended,
    markPatientConsultedDoctor,
    getPatientLabRecords
} from "./thunk"


export const initialState = {
    consultationPatientsList: [],
    patientVitalsHistory: [],
    doctorAppointments: [],
    patientsLabRecords: [],
    doctorAppointment: {},
    doctorAppointmentDetail: {},
    error: null,
    success: null,
}


const DoctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        /*
        * ConsultationPatientsList
        */
        builder.addCase(getConsultationListDoctor.fulfilled, (state, action) => {
            state.error = null
            state.mark_patient_consulted_success = false
            state.consultationPatientsList = action.payload.data
        })
        builder.addCase(getConsultationListDoctor.rejected, (state, action) => {
            state.consultationPatientsList = []
            state.error = action.error.message || null
        })

        builder.addCase(getPatientLabRecords.fulfilled, (state, action) => {
            console.log(action.payload);
            
            state.patientsLabRecords = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientLabRecords.rejected, (state, action) => {
            state.patientsLabRecords = []
            state.error = action.error.message || null
        })

        builder.addCase(getPatientAllVitals.fulfilled, (state, action) => {
            state.patientVitalsHistory = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientAllVitals.rejected, (state, action) => {
            state.patientVitalsHistory = []
            state.error = action.error.message || null
        })

        builder.addCase(getDoctorsAppointments.fulfilled, (state, action) => {
            state.doctorAppointments = action.payload.data
            state.succes_create = false
            state.successUpdate = false
            state.successDelete = false
            state.error = null
        })
        builder.addCase(getDoctorsAppointments.rejected, (state, action) => {
            state.doctorAppointments = []
            state.error = action.error.message || null
        })

        builder.addCase(registerAppointment.fulfilled, (state, action) => {
            state.doctorAppointment = action.payload.data
            state.succes_create = true
            state.error = null
        })
        builder.addCase(registerAppointment.rejected, (state, action) => {
            state.doctorAppointment = {}
            state.succes_create = false
            state.error = action.error.message || null
        })

        builder.addCase(getAppointmentsDetails.fulfilled, (state, action) => {
            state.doctorAppointmentDetail = action.payload.data
            state.error = null
        })
        builder.addCase(getAppointmentsDetails.rejected, (state, action) => {
            state.doctorAppointmentDetail = {}
            state.error = action.error.message || null
        })

        builder.addCase(updateAppointment.fulfilled, (state, action) => {
            state.doctorAppointment = action.payload.data
            state.successUpdate = true
            state.error = null
        })
        builder.addCase(updateAppointment.rejected, (state, action) => {
            state.doctorAppointment = {}
            state.successUpdate = false
            state.error = action.error.message || null
        })

        builder.addCase(deleteAppointment.fulfilled, (state, action) => {
            state.doctorAppointment = {}
            state.successDelete = true
            state.error = null
        })
        builder.addCase(deleteAppointment.rejected, (state, action) => {
            state.error = action.error.message || null
            state.successDelete = false
            state.success = false
        })

        builder.addCase(markAppointmentAttended.fulfilled, (state, action) => {
            state.markAppointmentAttended = true
            state.error = null
        })
        builder.addCase(markAppointmentAttended.rejected, (state, action) => {
            state.markAttendedSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(markPatientConsultedDoctor.fulfilled, (state, action) => {
            state.error = null
            state.mark_patient_consulted_success = true
        })
        builder.addCase(markPatientConsultedDoctor.rejected, (state, action) => {
            state.patients = []
            state.mark_patient_consulted_success = false
            state.error = action.error.message || null
        })
    }
})

export default DoctorsSlice.reducer;