import React, { useCallback, useEffect, useState } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { createSelector } from "reselect"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { 
    getPharmacyProductsAndSearch as onGetPharmacyProductsAndSearch,
    getSale as onGetSale,
    getPatientAccount as onGetPatientAccount,
    getPatientForSale as onGetPatients,
    searchPatient as onSearchPatient,
    getAssociatedService as onGetAssociatedService,
    createSale as onCreateSale,
    getPaymentMode as onGetPaymentMode
} from '../../../../slice/thunks'
import { Col, Label, Modal } from 'reactstrap'
import SaleRow from '../../../../components/App/Sales/SaleRow'
import DeleteModal from '../../../../components/Global/DeleteModal'
import moment from 'moment'
import { toast } from 'react-toastify'



const SalesCahier = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const selectLayoutStatePatient = (state) => state.Outputs;

    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            pharmacyProduct: setting.pharmacyProduct,
            associatedService: setting.associatedService,
            patientAccount: setting.patientAccount,
            paymentModes: setting.paymentModes,
            createSaleSuccess: setting.createSaleSuccess,
            sale: setting.sale,
            error: setting.error
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutStatePatient,
        (outputs) =>({
            patients: outputs.patients,
            patient: outputs.patient,
        })
    )

    const { pharmacyProduct, associatedService, patientAccount, createSaleSuccess, sale, paymentModes, error } = useSelector(SettingsProperties)
    const { patients } = useSelector(OutputsProperties)
    const [ patientOption, setPatientOption ]= useState([])
    const [ targetOption, setTargetOption] = useState([])
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ productsOption, setProductsOption] = useState([])
    const [ selectedPatient, setSelectedPatient ] = useState(null)
    const [ selectedTarget, setSelectedTarget] = useState(null)
    const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
    const [ selectedProduct, setSelectedProduit] = useState(null)
    const [ currentPatientAccount, setCurrentPatientAccount] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [isWatch, setIsWatch] = useState(false)
    const [ items, setItems ] = useState([])
    const [ onHold, setOnHold] = useState(false)
    const [ onKeepBalance, setOnKeepBalance] = useState(false)
    const [ totalAmount, setTotalAmount ] = useState(0)
    const [amountRecieved, setAmountRecieved ] = useState(0)
    const [amountReturn, setAmountReturn ] = useState(0)
    const [ modal, setModal ] = useState(false)
    const [ onHold1, setOnHold1 ] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const loadPatients = useCallback((term) => {
        if (term) {
            dispatch(onSearchPatient({term: term, page: 1, size: 10}))
        } else {
          dispatch(onSearchPatient({term: '', page: 1, size: 10}))
        }
    },[])

    const loadSale = useCallback((id) => {
        dispatch(onGetSale(id))
    })

    const loadPatientAccount = useCallback(() => {
        dispatch(onGetPatientAccount(selectedPatient.id))
    })

    const loadProducts = useCallback((currentType, target, term) => {
        if (term) {
            dispatch(onGetPharmacyProductsAndSearch({ term: term, type: currentType, target: target }))
        } else {
            dispatch(onGetPharmacyProductsAndSearch({ type: currentType, target: target }))
        }
    },[])

    const loadTarget = useCallback(() => {
        dispatch(onGetAssociatedService())
    },[])

    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    },[])

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
        } else {
          setModal(true);
        }
    })

    useEffect(() => {        
        if (patients && patients.items && patients.items.length > 0) {
            const patient_list = patients.items.map( target => {
                return {
                    label: target.firstName+' '+target.lastName,
                    value: target.patientRecord.id,
                    type: target.patientType.typeRef,
                    id: target.id
                }
            })
            setPatientOption(patient_list)
            // setMenuIsOpen(true)
        }
    },[patients])

    useEffect(() => {
        console.log("pat: ", patientOption);
    },[patientOption])

    useEffect(() => {
        console.log("items: ", items);
        
    },[items])

    useEffect(() =>{
        if (!props.sale) {
            setItems([])
        }
    },[selectedPatient])

    useEffect(() => {
        
        if (props.sale && props.sale.id) {
            loadSale(props.sale.id)
            setSelectedPatient({
                label: props.sale.patient.firstName+' '+props.sale.patient.lastName,
                value: props.sale.patientRecord.id,
                type: props.sale.patient.patientType.typeRef,
                id: props.sale.patient.id
            })

            let array_items = []

            props.sale.items.forEach(element => {
                let row = {
                    label: element.product.name,
                    value: element.product.id,
                    qtyStock: element.product.quantity,
                    type: element.product.productType,
                    unitPrice: element.unitPrice,
                    discount: element.discount,
                    quantity: element.quantity
                }
                array_items.push(row)
            });

            console.log("sale: ", array_items);

            setItems(array_items)
        }
        
    }, [props.sale])

    useEffect(() => {
        if (associatedService && associatedService.length > 0) {
            const target_list = associatedService.map( target => {
              return { label: target.title, value: target.value }
            })
            setTargetOption(target_list)
        }
    },[associatedService])

    useEffect(() => {
        if (paymentModes && paymentModes.length > 0) {
            const payment_lists = paymentModes.map( mode => {
              return { label: mode.title, value: mode.value }
            })
            setPaymentOption(payment_lists)
        }
    },[paymentModes])

    useEffect(() => {
        if (selectedPaymentM && selectedPaymentM.value == 'PATIENT_CREDIT') {            
            loadPatientAccount()
        } else {
            setCurrentPatientAccount(null)
        }
    },[selectedPaymentM, selectedPatient])

    useEffect(() => {
        if (patientAccount && patientAccount.balance) {
            setCurrentPatientAccount(patientAccount);
        }
    },[patientAccount])

    useEffect(() => {
        if ((selectedPatient && selectedPatient.type)) {
            // if ((selectedPatient && selectedPatient.type) && (selectedTarget && selectedTarget.value) ) { // activer target service
            // loadProducts(selectedPatient.type, selectedTarget.value) // activer target service
            loadProducts(selectedPatient.type)
        }
    },[selectedTarget, selectedPatient])

    // useEffect(() => {
    //     if (createSaleSuccess && !error) {
    //
    //     }
    // },[createSaleSuccess, sale])

    useEffect(() => {
        
        if (createSaleSuccess && !error) {
            if (onHold1) {
                history("/cashier/all-sales")
                props.setIsCreate(false)
                setOnHold1(false)
            } else {
                handleOpen()
            }
            setOnHold(false)
            setOnKeepBalance(false)
            setCurrentPatientAccount(null)
        }
    },[createSaleSuccess])

    // useEffect(() => {
    //     if (!onHold) {
    //     }
    // },[onHold])

    useEffect(() => {
        if (pharmacyProduct && pharmacyProduct.length > 0) {
            const product_list = pharmacyProduct.map( prod => {
                return {
                    label: prod.name,
                    value: prod.id,
                    qtyStock: prod.quantity,
                    unitPrice: prod.unitPrice,
                    discount: prod.discount,
                    rowTotal: (props.unitPrice * 1) - (((props.unitPrice * 1)*props.discount)/100),
                    type: prod.productType,
                    quantity: 1
                }
            })
            
            setProductsOption(product_list)
        }

    },[pharmacyProduct])

    useEffect(() => {
        loadPatients()
        loadTarget()
        loadPaymentMode()
    },[dispatch])

    useEffect(() => {
        if (selectedProduct !== null) {
            let current_products = [...items];
            if (!current_products.some((current) => current.value === selectedProduct.value)) {
              const new_products = [...current_products, selectedProduct];
              setItems(new_products);
            }
        }
      }, [selectedProduct]);

    useEffect(() => {
        let total_amount = 0
        console.log(items);
        if (items && items.length > 0) {
            items.forEach(row => {
                total_amount = total_amount+(row.rowTotal ? row.rowTotal : 0)
            });
            setTotalAmount(total_amount)            
        } else {
            setTotalAmount(0)            
        }

        setAmountRecieved()
    },[items])

    useEffect(() => {
        if (amountRecieved > 0) {
            setAmountReturn(totalAmount - amountRecieved)
        } else {
            setAmountReturn(totalAmount)
        }
    },[amountRecieved])


    const handleOpen = () => {
        toggle()
    }


    function handleSelectPatient(selectedP) {
        if (selectedP) {
            setSelectedPatient(selectedP)
        } else {
            setSelectedPatient(null)
            loadPatients()
        }
        setMenuIsOpen(false)
    }

    function handleSelectTarget(selectedT) {
        setSelectedTarget(selectedT)
        setItems([])
    }

    function handleSelectProduct(selectedP) {
        setSelectedProduit(selectedP)
    }

    function handleSelectMethod(selectedM) {
        setSelectedPaymentM(selectedM)
    }

    const handleUpdateRow = (row) => {        
        let current_products = [...items]; 
        const index = current_products.findIndex(objet => objet.value === row.value);
        if (index !== -1) {
          current_products[index] = { ...current_products[index], ...row };
          setItems(current_products); 
        } 
    }

    const onClickDelete = (row) => {
        setSelectedProduit(row)
        setDeleteModal(true);
    }

    const handleDelete = () => {
        if (selectedProduct) {
          if (selectedProduct.id) {
            // dispatch(onDeleteTransfertOrderItem(selectedProduct.id))
          } else {
            let current_products = [...items]; 
            current_products = current_products.filter((prod) => prod.value !== selectedProduct.value )
            setItems(current_products); 
          }
          setDeleteModal(false)
        }
    }

    const handleSearchProduct = (value) => {
        // if ((selectedPatient && selectedPatient.type) && (selectedTarget && selectedTarget.value) ) {
        //     loadProducts(selectedPatient.type, selectedTarget.value, value)
        if ((selectedPatient && selectedPatient.type)) {
            loadProducts(selectedPatient.type)
        }
    }

    const loadDefaultProducts = () => {
        if ((selectedPatient && selectedPatient.type) && (selectedTarget && selectedTarget.value) ) {
            loadProducts(selectedPatient.type, selectedTarget.value)
        }
    }

    const saveBills = (hold) => {

        let itemsObj = items.map(item => {
            return {
              productId: item.value,
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              finalPrice: item.unitPrice,
              discount: item.discount
            };
        });

        if (hold) {
            setOnHold1(hold)
        }
        
        let bill = {
            patientRecordId: selectedPatient.value,
            paymentMode: onHold ? null : selectedPaymentM ? selectedPaymentM.value : null,
            amountReceived: Number(amountRecieved),
            salesOnHold: onHold ? true : hold ? true : false,
            keepBalance: onKeepBalance ? true : false,
            // targetService: selectedTarget.value,
            items: itemsObj
        }

        dispatch(onCreateSale(bill))
    }

    const handlePrintInvoice = () => {
        toggle()
        history("/cashier/all-sales")
        setSelectedPatient(null)
        setSelectedTarget(null)
        setSelectedProduit(null)
        setSelectedPaymentM(null)
        setTotalAmount(0)
        setAmountRecieved(0)
        setAmountReturn(0)
        props.setIsCreate(false)
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

  return (
    <>
        <div className="home-tab-content">
            <div className="heading-parent-sec" onClick={() => props.setIsCreate(false) } style={{ cursor: "pointer" }}>
                <div className="heading-child-one-sec d-flex pt-4">
                    <i className="fa-solid fa-chevron-left pt-1"></i>
                    <h6>{props.sale ? props.t("sale_on_hold") : props.t("new_sales")}</h6>
                </div>
            </div>
            <div className="row">
                <Col className="col-lg-8 pe-lg-0">
                    <div className="tab-content-left-part">
                        <div className="head-area" style={{ zIndex: "1000" }}>
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="patient-field" className="form-label">
                                        {props.t("patient")}<span className='text-danger'>*</span>
                                    </Label><br/>
                                    <Select
                                        key={patientOption.length}
                                        name='patient'
                                        id='patient-field'
                                        value={selectedPatient}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(e) => {
                                            handleSelectPatient(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                if (inputValue.length > 0) {
                                                    loadPatients(inputValue)
                                                } else {
                                                    // loadPatients()
                                                }
                                            }
                                        }}
                                        // onMenuOpen={() => {
                                        //     loadPatients()
                                        // }}
                                        options={patientOption}
                                        closeMenuOnSelect={true}
                                        menuIsOpen={menuIsOpen}
                                        onMenuOpen={() => setMenuIsOpen(true)} // Ouvrez le menu au clic
                                        onMenuClose={() => setMenuIsOpen(false)}
                                        onBlur={() => setMenuIsOpen(false)}
                                        components={animatedComponents}
                                        isDisabled= {props.sale && props.sale.id ? true : false }
                                    />
                                </div>
                            </Col>
                            {/* <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="status-field" className="form-label">
                                        {props.t("target_service")}<span className='text-danger'>*</span>
                                    </Label><br/>
                                    <Select
                                        name='status'
                                        id='status-field'
                                        value={selectedTarget}
                                        isMulti={false}
                                        isClearable={false}
                                        onChange={(e) => {
                                            handleSelectTarget(e);
                                        }}
                                        options={targetOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div>
                            </Col> */}
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="product-field" className="form-label">
                                        {props.t("product")}
                                    </Label><br/>
                                    <Select
                                        name='product'
                                        id='product-field'
                                        value=""
                                        isMulti={false}
                                        isClearable={false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            handleSelectProduct(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                if (inputValue.length > 0) {
                                                    handleSearchProduct(inputValue)
                                                } else {
                                                    loadDefaultProducts()
                                                }
                                            }
                                        }}
                                        onMenuOpen={() => {
                                            loadDefaultProducts()
                                        }}
                                        options={productsOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isDisabled= { selectedPatient && selectedPatient.value ? false : true}
                                    />
                                </div>
                            </Col>
                        </div>

                        <div className="table-area show" id="home-table">
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        {/* <!-- <th style= {{ width: "30%" }} >Name</th> --> */}
                                        <th style={{ width: "20%" }}>{props.t("item_name")}</th>
                                        <th style={{ width: "10%" }}>{props.t("price_unit")}</th>
                                        <th style={{ width: "10%" }}>{props.t("discount")}</th>

                                        <th style={{ width: "10%" }}>{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} colSpan="2">{props.t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {items && items.map((row, index) => (
                                    <tr key={row.value}>
                                      <SaleRow row={row} index={index} isWatch={isWatch} isValidate= {isValidate} updateRow= {handleUpdateRow} deleteRow={onClickDelete}/>
                                    </tr>
                                  ))}
                                    
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="options-area" id="tableTabs">
                            <button className="option change-qty-btn">Change Qty</button>
                            <button className="option">Post Bill to Ward</button>
                            <button className="option">Remove Item</button>
                            <button className="option">Recall Bills</button>
                            <button className="option">Care Of Staff</button>
                            <button className="option">Pharmacy Bills</button>
                        </div> */}
                    </div>
                </Col>

                {/* invoiceSide */}
                <div className="col-lg-4 ps-lg-0">
                    <div className="tab-content-right-part">

                        <div className="customer-detail">
                            <h6 className="title">{props.t("customer_detail")}</h6>
                            <h6>{ selectedPatient && selectedPatient.label }</h6>
                        </div>

                        <div className="bill-detail">
                            <h6 className="title">{props.t("bill_details")}</h6>
                            <div className="detail-row">
                                <p className="tag">{props.t("sub_total")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                            <div className="total-row">
                                <p className="tag">{props.t("total_amount")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                        </div>

                        <div className="payment-detail">
                            <div className="payment-mode">
                                <Label htmlFor="payment-field" className="form-label">{props.t("payment_mode")}</Label><br/>
                                <Select
                                    name='payment'
                                    id='payment-field'
                                    styles={customStyles}
                                    value={selectedPaymentM}
                                    isMulti={false}
                                    isClearable={false}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        handleSelectMethod(e);
                                    }}
                                    options={paymentOption}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    // isDisabled= {totalAmount > 0 ? false : true}
                                />
                            </div>
                            <div className="payment-mode">
                                <label>{props.t("amount_recieved")}</label>
                                <input 
                                    className="form-control" 
                                    type='number'
                                    value={amountRecieved} 
                                    onChange={(e) => {setAmountRecieved(e.target.value) }}
                                    disabled= {selectedPaymentM && selectedPaymentM.value == 'PATIENT_CREDIT' ? true : false}
                                />
                            </div>
                            
                            {currentPatientAccount && currentPatientAccount.balance ?
                                <div className="total-row">
                                    <p className="tag">{props.t("patient_credit_balance")}:</p>
                                    <p className="price">{currentPatientAccount.balance}</p>
                                </div>
                                :
                                <div className="total-row">
                                    <p className="tag">{props.t("amount_return")}:</p>
                                    <p className="price">{amountReturn && amountReturn > 0 ? Math.round(amountReturn) : 0}</p>
                                </div>
                            }


                            

                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    value="" 
                                    id="flexCheckDefault" 
                                    onClick={() => {
                                        setOnKeepBalance(!onKeepBalance);
                                    }}
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    {props.t("keep_balance")}
                                </label>
                            </div>

                            <div className="btn-first-area d-flex justify-content-between pb-2" style={{ gap: "10px" }}>
                                <button
                                    className="bill-on-hold-btn show"
                                    style = { onHold ? { background: "#2563eb", color: "#fff" } : {}}
                                    id="bill-on-btn"
                                    onClick={() => {
                                        let hold = true
                                        saveBills(hold);
                                        if (onKeepBalance) {
                                            setOnKeepBalance(false)
                                        }
                                    }}
                                    disabled= { items && items.length > 0 ? false : true }
                                >
                                    {props.t("bill_on_hold")}
                                </button>

                                {/* <button
                                    className="bill-on-hold-btn show"
                                    style = { onKeepBalance ? { background: "#2563eb", color: "#fff" } : {}}

                                    id="bill-on-btn"
                                    onClick={() => {
                                        setOnKeepBalance(!onKeepBalance);
                                        if (onHold) {
                                            setOnHold(false)
                                        }
                                    }}
                                >
                                    {props.t("keep_balance")}
                                </button> */}
                                {/* <button className="fwd-to-pharmacy-btn" style={{ visibility: "hidden" }}>
                                    {props.t("forward_to_pharmacy")}
                                </button> */}
                            </div>
                        </div>

                        <div className="btn-area">
                            <button
                                className="print-btn"
                                id="save-print-btn"
                                onClick={(e) => {
                                    saveBills();
                                    // handleOpen()
                                }}
                                disabled= {
                                    // ((selectedPaymentM != null) || onHold  || onKeepBalance) && items.length > 0 ? false : true}
                                    ((selectedPaymentM != null) || onHold  || onKeepBalance || (totalAmount >= 0)) && (items && items.length > 0) ? false : true}
                            >
                                    {props.t("save_print")}
                            </button>
                            {/* <button className="partial-pay-btn" data-bs-toggle="modal" data-bs-target="#partialPayModal">
                                {props.t("partial_pay")}
                            </button>
                            <button className="multi-pay-btn" data-bs-toggle="modal" data-bs-target="#multiPayModal">
                                {props.t("multi_pay")}
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />

        <Modal className="" id='paymentReceiptModal' tabindex="-1" aria-labelledby="" aria-hidden="true" isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
            {/* <div className="modal-dialog"> */}
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="payment-reciept-modal-heading-sec">
                            <div className="row g-0">
                                <div className="col-xl-2 col-lg-2 col-md-2 col-2 pe-0">
                                    <div className="header-logo-sec">
                                        <img src="../assets/images/LOGO.png" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-9 col-9">
                                    <div className="header-heading-sec">
                                        <div className="heading-one">
                                            <h2 className="mb-0">ST. VERONICA MEDICAL CENTER</h2>
                                        </div>
                                        <div className="heading-two">
                                            <h3 className="mb-0">BOKOKO-BUEA</h3>
                                        </div>
                                        <div className="heading-three">
                                            <h4 className="mb-0">Tel: +251-9876543</h4>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="col-xl-1 col-lg-1 col-md-1 col-1 text-end">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div> */}
                            </div>
                        </div>
                        <div className="payment-receipt-details-sec">
                            <hr />
                            <p className="payment-receipt-heading-sec">{props.t("payment_reciept")}</p>
                            <div className="row row-one-sec">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-5">
                                    <div className="bill-no-parent">
                                        <div className="bill-no-child-one">{props.t("bill_no")} :
                                            <span className="bill-no-child-two">79545</span>
                                        </div>
                                        <div className="bill-no-child-one">{props.t("name")} :
                                            <span className="bill-no-child-two">{sale && sale.patient && sale.patient.firstName+" "+sale.patient.lastName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-1"></div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-1"></div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-5">
                                    <div className="bill-no-parent ps-2">
                                        <div className="bill-no-child-one">{props.t("date")} :
                                            <span className="bill-no-child-two">{sale && moment(sale.orderDate).format('DD / MM/ YYYY')}</span>
                                        </div>
                                        {/* <div className="bill-no-child-one">Cashier :
                                            <span className="bill-no-child-two">John</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row g-0 row-two-sec">
                                <div className="d-flex justify-content-between">
                                    <div className="col-10">
                                        <p className="mb-0">{props.t("item")}</p>
                                    </div>
                                    <div className="col-2 d-flex justify-content-between">
                                        <span className="bill-no-child-one">{props.t("qty")}</span>
                                        <span className="bill-no-child-one px-2">{props.t("amount")}</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row g-0">
                                {items && items.map((row, index) => (
                                    <div className="d-flex justify-content-between" key={index}>
                                        <div className="col-10">
                                            <p className="mb-0">{row.label}</p>
                                        </div>
                                        <div className="col-2 d-flex justify-content-between">
                                            <span className="bill-no-child-one">{row.quantity}</span>
                                            <span className="bill-no-child-one px-2">{row.rowTotal}</span>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            <hr />
                            <div className="row g-0 row-four-sec">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-6">
                                    <div className="bill-no-child-one">
                                        <p className="mb-0"><span className="sub-total">{props.t("sub_total")}</span></p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-3 text-end">
                                    {/* <span className="sub-total">5 Nos</span> */}
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-3 text-end">
                                    <span className="sub-total">{totalAmount}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="row g-0 row-five-sec">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                                    <div className="total">
                                        <p className="mb-0">Total</p>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2 col-md-2 col-2"></div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-4 text-end">
                                    <div className="total">
                                        <p className="mb-0">{totalAmount}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {/* <div className="row g-0 row-six-sec">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                                    <div className="bill-no-child-one">
                                        <p className="mb-0">Cash :</p>
                                    </div>
                                    <div className="bill-no-child-one">
                                        <p className="mb-0">Cash Tendered :</p>
                                    </div>
                                    <div className="bill-no-child-one">
                                        <p className="mb-0">Balance :</p>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2 col-md-2 col-2"></div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-4 text-end">
                                    <span className="bill-no-child-one">Fr. 18000</span><br />
                                    <span className="bill-no-child-one">Fr. 19000</span><br />
                                    <span className="bill-no-child-one">Fr. 1000</span><br />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn" onClick={(e) => { handlePrintInvoice()}}>
                            {props.t("print_download")}
                        </button>
                    </div>
                </div>
            {/* </div> */}
        </Modal>

    </>
  )
}

export default withRouter(withTranslation()(SalesCahier))
