import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const SettingZone = (props) => {
  return (
    <div>SettingZone</div>
  )
}

export default withRouter(withTranslation()(SettingZone))
