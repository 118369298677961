import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
// import SalesCahier from './SalesCahier'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { createSelector } from "reselect"
import { 
  getInvoices as onGetInvoice,
  getPaymentMode as onGetPaymentMode,
  getAssociatedService as onGetAssociatedService,
  getInvoiceStatus as onGetInvoiceStatus,
  getUsers as onGetUsers,
  makePayment as onMakePayment,
} from '../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { Link } from 'react-router-dom'
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

const PhInvoiceList = (props) => {
    
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            saleInvoices: setting.saleInvoices,
            associatedService: setting.associatedService,
            invoiceStatus: setting.invoiceStatus,
            paymentModes: setting.paymentModes,
            users: setting.users,
            makePayment : setting.makePayment,
            makePaymentSuccess: setting.makePaymentSuccess,
            error: setting.error
        })
    )
    const { saleInvoices, invoiceStatus,  makePaymentSuccess, users, associatedService, paymentModes, error } = useSelector(SettingsProperties)
    const [iscreate, setIsCreate] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [ targetOption, setTargetOption] = useState([])
    const [searchUserInput, setSearchUserInput] = useState(null)
    const [searchInput, setSearchInput] = useState('')
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ statusOption, setStatusOption] = useState(null)
    const [ usersOption, setUsersOption ] = useState(null)
    const [ selectedTarget, setSelectedTarget] = useState(null)
    const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
    const [ selectedStatus, setSelectedStatus ] = useState(null)
    const fp = useRef(null);
    const [ selectedUser, setSelectedUser ] = useState(null)
    const [periods, setPeriods] = useState([]);
    const [filterStatus, setFilterStatus] = useState(null)
    const [filterTarget, setFilterTarget] = useState(null)
    const [currentInvoice, setCurrentInvoice] = useState(null)
    const [ selectedPaymentMPay, setSelectedPaymentMPay ] = useState(null)
    const [modal, setModal] = useState(false)
    const [modalView, setModalView] = useState(false)

    const loadSales = useCallback((pageNumber, pageSize) => {
        let reqObject = {}

        if ((pageNumber !== null) && (pageNumber != undefined)) reqObject.pageNumber = pageNumber;
        if ((pageSize !== null) && (pageSize != undefined)) reqObject.pageSize = pageSize;
        if (filterStatus !== null) reqObject.status = filterStatus;
        if (searchUserInput !== null) reqObject.users = searchUserInput;
        if (periods.length > 0) reqObject.startDate = moment(periods[0]).format("YYYY-MM-DD");
        if (periods.length > 0) reqObject.endDate = moment(periods[1]).format("YYYY-MM-DD");
        if (filterTarget !== null) reqObject.target = filterTarget;
        if (searchInput !== null) reqObject.term = searchInput;

        dispatch(onGetInvoice(reqObject))
    })

    const toggle = useCallback(() => {      
      if (modal) {
        setModal(false);
        validation.resetForm();
        setCurrentInvoice(null);
        setSelectedStatus(null)
        setSelectedPaymentMPay(null)
      } else {
        setModal(true);
      }
    }, [modal])

    const toggleView = useCallback(() => {
        if (modalView) {
          setModalView(false);
          validation.resetForm();
          setCurrentInvoice(null);
          setSelectedStatus(null)
          setSelectedPaymentMPay(null)
        } else {
          setModalView(true);
        }
    }, [modalView])

    const loadTarget = useCallback(() => {
      dispatch(onGetAssociatedService())
    })

    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    })

    const loadStatus = useCallback(() => {
      dispatch(onGetInvoiceStatus())
    })
    

    const loadUsers = useCallback(() => {
      dispatch(onGetUsers())
    })

    useEffect(() => {
        loadSales()
        loadTarget()
        loadUsers()
        loadStatus()
        loadPaymentMode()
    },[props.isActive])

    useEffect(() => {
      if (saleInvoices && saleInvoices.page > 0) {
        setIsLoading(false)
      }
    },[saleInvoices])

    useEffect(() => {
      if (associatedService && associatedService.length > 0) {
          const target_list = associatedService.map( target => {
            return { label: target.title, value: target.value }
          })
          setTargetOption(target_list)
      }
    },[associatedService])

    useEffect(() => {
      if (paymentModes && paymentModes.length > 0) {
          // const payment_lists = paymentModes.filter(mode => mode.value !== 'PATIENT_CREDIT').map(mode => ({
            const payment_lists = paymentModes.map(mode => ({
              label: mode.title,
              value: mode.value
          }));
          setPaymentOption(payment_lists);
      }
    }, [paymentModes]);

    useEffect(() => {
      if (invoiceStatus && invoiceStatus.length > 0) {
            const payment_lists = invoiceStatus.map(mode => ({
              label: mode.title,
              value: mode.value
          }));
          setStatusOption(payment_lists);
      }
    }, [invoiceStatus]);

    useEffect(() => {
        if (users && users.items && users.items.length > 0) {
          const user_lists = users.items.map( user => {
            return { label: user.fullName, value: user.id }
          })
          setUsersOption(user_lists)
        }
    },[users])
  
    useEffect(() => {
        if ((makePaymentSuccess && !error)) {
            loadSales()
            setSelectedPaymentMPay(null)
            setCurrentInvoice(null)
            toggle()
        }
    },[makePaymentSuccess])
  
    useEffect(() => {
        if (selectedStatus && selectedStatus.length > 0) {
        let filter_status = null
        selectedStatus.forEach(row => {
            filter_status = filter_status === null ? row.value : filter_status+","+row.value
        });
        setFilterStatus(filter_status)
        }else {
        setFilterStatus(null)
        }
        if (selectedTarget && selectedTarget.length > 0) {
        let filter_target = null
        selectedTarget.forEach(row => {
            filter_target = filter_target === null ? row.value : filter_target+","+row.value
        });
        setFilterTarget(filter_target)
        }else {
        setFilterTarget(null)
        }
    },[selectedTarget, selectedStatus])
  
    useEffect(() => {
        loadSales()
    },[searchInput, searchUserInput,filterTarget, filterStatus, selectedUser, periods])

    function handleSearch () {
        // loadConsultationList(1, 10, searchInput)
    }
  
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            id: (setCurrentInvoice && setCurrentInvoice.id) || null,
            paymentMode: (setCurrentInvoice && setCurrentInvoice.paymentMode) || '',
            amountReceived: (setCurrentInvoice && setCurrentInvoice.amountReceived) || '',
        },
        validationSchema: Yup.object({
          // amountReceived: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_amount") }).required(props.t("provide_amount")),
        }),
        onSubmit: (values) => {
            const newVitalForm = {
                paymentMode: selectedPaymentMPay && selectedPaymentMPay.value,
                amountReceived: values.amountReceived,
                invoiceId: currentInvoice.id
            }
            dispatch(onMakePayment(newVitalForm))
        },
    })
  
    const handleSelectInvoice = (invoice) => {
    setCurrentInvoice(invoice)
    toggle()
    }

    const handleOpenInvoice = (invoice) => {
        setCurrentInvoice(invoice)
        toggleView()
    }

    const handleOpen = () => {
        setIsCreate(true)
    }

    const columns = useMemo(
        () => [
        {
            header: props.t("no"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cellProps) => {
            return <span>
                {saleInvoices.page == 1 ? (cellProps.row.index + 1) : (((saleInvoices.page-1)*10)+cellProps.row.index + 1) }
            </span>;
            },
        },
        {
            header: props.t("date"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cell) => {
            return <div className='d-flex align-items-center'>
                        <span>
                        {moment(cell.row.original.createdAt).format('DD / MM/ YYYY HH:mm')}
                        </span>
                </div>
            }
        },
        {
            header: props.t("ref"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>{cell.row.original.invoiceRef}</span>
            }
        },
        {
            header: props.t("amount"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>{cell.row.original.invoiceAmount}</span>
            }
        },
        {
            header: props.t("status"),
            style: "purchase",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>{cell.row.original.invoiceStatus}</span>
            }
        },
        {
            header: props.t("customer"),
            style: "purchase",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>{cell.row.original.saleOrder.patient.firstName +" "+cell.row.original.saleOrder.patient.lastName}</span>
            }
        },
        {
            header: props.t("done_by"),
            style: "purchase",
            enableColumnFilter: true,
            cell: (cell) => {
            return <div className='d-flex align-items-center'>
                        <span>
                        {cell.row.original.createdBy}
                        </span>
                </div>
            }
        },
        {
            header: props.t("action"),
            style: "purchase",
            cell: (cellProps) => {
            return (
                <div className="d-flex justify-content-start">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title={props.t("make_payment")}>
                          <Link
                            to="#"
                            className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                            onClick={() => handleOpenInvoice(cellProps.row.original)}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </Link>
                        </li>
                    {cellProps.row.original.invoiceStatus !== 'Paid' ?
                        <li className="list-inline-item edit" title={props.t("make_payment")}>
                        <Link
                            to="#"
                            className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
                            onClick={() => handleSelectInvoice(cellProps.row.original)}
                        >
                            <i className="fa fa-credit-card" aria-hidden="true"></i>
                        </Link>
                        </li>
                        :
                        <></>
                    }
                    </ul>
                </div>
            );
            },
        },
        ],
        [saleInvoices]
    );
  
    return (
        <>
            {isLoading ?
                <GlobalLoader />
            :
                <>
                <div className='px-3'>
                    <div className=" px-3 d-flex justify-content-between">
                        <div className="pt-4">
                            <h5>{props.t("bills")}</h5>
                        </div>
                        <div className="pt-4">
                            <div className="create-new-patient-btn-child-two">
                            </div>
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col xl={2} sm={4} className="">
                                <div className='mb-2'>
                                    <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                                    <div className='d-flex align-items-center'>
                                        <Flatpickr
                                            className='flatpick-custom'
                                            id="inputGroupSelect01"
                                            ref={fp}
                                            placeholder={props.t("select_date_range")}
                                            defaultValue={periods}
                                            value={ periods }
                                            options={{
                                                altInput: true,
                                                altFormat: "d / m/ Y",
                                                maxDate: new Date(),
                                                mode: "range",
                                            }}
                                            onChange={(e) => {
                                                if (e.length > 1) {
                                                    setPeriods(e)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={2} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="user-field" className="form-label">{props.t("done_by")}</Label><br/>
                                    <div className="search-box me-2 mb-1 col-10">
                                        <Input
                                            className="custom-border form-control"
                                            value={searchUserInput}
                                            onChange={(e) => setSearchUserInput(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={2} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="user-field" className="form-label">{props.t("status")}</Label><br/>
                                    <Select
                                        name='user'
                                        id='user-field'
                                        value={selectedStatus}
                                        isMulti={true}
                                        isClearable={false}
                                        onChange={(e) => {
                                            setSelectedStatus(e);
                                        }}
                                        options={statusOption}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                            </Col>
                            <Col xl={3} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="status-field" className="form-label">{props.t("search")}</Label><br/>
                                    <div className="d-flex align-items-center">
                                        <div className="search-box me-2 mb-1 col-10">
                                            <Input
                                                className="custom-border form-control"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </div>
                                        <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                            <i className="fa fa-search p-0"></i>
                                        </button>
                                        <button 
                                            type="button" 
                                            className="btn btn-warning mx-1" 
                                            onClick={() => { 
                                                setSearchUserInput('')
                                                if (!fp?.current?.flatpickr) return; 
                                                fp.current.flatpickr.clear(); 
                                                setPeriods([]);
                                                setFilterStatus(null)
                                                setFilterTarget(null)
                                                setSelectedTarget(null)
                                                setSelectedUser(null)
                                                setSelectedStatus(null)
                                            }} 
                                        >
                                            {props.t("clear")}
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <TableContainerWarehouse
                            columns={columns}
                            data={((saleInvoices && saleInvoices.items) || [])}
                            isGlobalFilter={false}
                            customPageSize={10}
                            divClass="card table-card table-warehouse table-responsive"
                            tableClass="table"
                            theadClass="thead-sec table-light"
                            tdClass="td"
                            tableSize={ saleInvoices && saleInvoices.size }
                            tablePage={ saleInvoices && saleInvoices.page }
                            tableTotal= { saleInvoices && saleInvoices.total_items }
                            tableTotalPage= { saleInvoices && saleInvoices.total_page }
                            onNextPage= {loadSales}
                            onSearch= {loadSales}
                            SearchPlaceholder={props.t("search_product")}
                        />
                    </div>
                </div>

                <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {props.t("make_payment")}
                    </ModalHeader>
                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <ModalBody>
                            <Row>
                                <Col xl={12} sm={12}>
                                <div className='mb-2'>
                                    <Label htmlFor="paymentMode-field" className="form-label">{props.t("payment_mode")}</Label><br/>
                                    <Select
                                        name='paymentMode'
                                        id='paymentMode-field'
                                        isMulti={false}
                                        isClearable={false}
                                        onBlur={validation.handleBlur}
                                        value={selectedPaymentMPay}
                                        onChange={(e)=> setSelectedPaymentMPay(e)}
                                        options={paymentOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="amountReceived-field"
                                            className="form-label"
                                        >
                                            {props.t("Amount")}
                                            {selectedPaymentMPay && selectedPaymentMPay.value == 'PATIENT_CREDIT' ?
                                            <></>
                                            :
                                            <span className='text-danger'>*</span>
                                            }
                                        </Label>
                                        <Input
                                            name="amountReceived"
                                            id="amountReceived-field"
                                            className="form-control"
                                            autoComplete="off" 
                                            placeholder=".."
                                            type="text"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.amountReceived || ""}
                                            invalid={
                                                validation.touched.amountReceived && validation.errors.amountReceived ? true : false
                                            }
                                            disabled={selectedPaymentMPay && selectedPaymentMPay.value == 'PATIENT_CREDIT' ? true : false}
                                        />
                                        {validation.touched.amountReceived && validation.errors.amountReceived ? (
                                            <FormFeedback type="invalid">{validation.errors.amountReceived}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='p-3'>
                            <Row className="align-items-center">
                            <div className="col-sm">
                                <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                            </div>
                            <div className="col-sm-auto">
                                <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setSelectedPaymentMPay(null) }}> {props.t("close")} </button>
                                <button type="submit" className="btn btn-success"> {props.t("save")} </button>
                                </div>
                            </div>
                            </Row>
                        </div>
                    </Form>
                </Modal>

                <Modal id='showModal' size='md' isOpen={modalView || false} toggle={toggleView} backdrop={'static'} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggleView}>

                    </ModalHeader>
                    <ModalBody>
                        <Row>
                        <Col xl={4} sm={6}>
                            <p><b>{props.t("invoice_ref")}: {currentInvoice && currentInvoice.invoiceRef}</b></p>
                        </Col>
                        <Col xl={4} sm={6}>
                            <p><b>{props.t("amount")}: <span className='text-success'> {currentInvoice && currentInvoice.invoiceAmount}</span></b></p>
                        </Col>
                        <Col xl={4} sm={6}>
                            <p><b>{props.t("status")}: <span className='text-success'>{currentInvoice && currentInvoice.invoiceStatus}</span></b></p>
                        </Col>
                        <Col xl={4} sm={6}>
                            <p><b>{props.t("createdBy")}: {currentInvoice && currentInvoice.createdBy}</b></p>
                        </Col>
                        <Col xl={6} sm={6}>
                            <p><b>{props.t("date")}: {currentInvoice && moment(currentInvoice.createdAt).format('DD / MM/ YYYY HH:mm')}</b></p>
                        </Col>
                        </Row>
                        <div className="table-area show mt-3" id="home-table">
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        {/* <!-- <th style= {{ width: "30%" }} >Name</th> --> */}
                                        <th style={{ width: "20%" }}>{props.t("item_name")}</th>
                                        <th style={{ width: "10%" }}>{props.t("price_unit")}</th>
                                        <th style={{ width: "10%" }}>{props.t("discount")}</th>
                                        <th style={{ width: "10%" }} colSpan="2">{props.t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { currentInvoice && currentInvoice.saleOrder && currentInvoice.saleOrder.items &&
                                    currentInvoice.saleOrder.items.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "10%" }}>{index+1}</td>
                                        <td style={{ width: "20%" }}>{row.product && row.product.name}</td>
                                        <td style={{ width: "10%" }}>{row.unitPrice}</td>
                                        <td style={{ width: "10%" }}>{row.discount}</td>
                                        <td style={{ width: "10%" }}>{row.finalPrice}</td>
                                    </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                        <div className='p-3'>
                            <Row className="align-items-center">
                            <div className="col-sm">
                            </div>
                            <div className="col-sm-auto">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModalView(false); setSelectedPaymentMPay(null) }}> {props.t("close")} </button>
                                </div>
                            </div>
                            </Row>
                        </div>
                </Modal>
                
                </>
            }
        </>
    )
}

export default withRouter(withTranslation()(PhInvoiceList))
