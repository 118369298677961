import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import {
    getAllCategories as onGetAllCategories,
    getPharmacyStock as onGetPharmacyStock,
  } from "../../../slice/thunks"
import { Col, Input, Label, Row } from 'reactstrap'
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'

function PhStockReports(props) {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const outputLayoutState = (state) => state.Settings
    const OutputsProperties = createSelector(
        outputLayoutState,
        (setting) =>({
            phStockReports: setting.phStockReports,
            all_categories: setting.all_categories,
            error: setting.error
        })
    )
    const { all_categories, phStockReports, error } = useSelector(OutputsProperties)
    const [categoriesOptions, setCategoriesOptions] = useState([{label: props.t("all"), value: null}])
    const [selectedCategories, setselectedCat] = useState(null)
    const [searchInput, setSearchInput] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const loadStock = useCallback((pageNumber, pageSize, term) => {

        if (pageNumber && pageSize) {
            if (term) {
                if (selectedCategories !== null && selectedCategories.value !== null) {
                    dispatch(onGetPharmacyStock({page: pageNumber, size: pageSize, term: searchInput, category: selectedCategories.value}))
                } else {
                    dispatch(onGetPharmacyStock({page: pageNumber, size: pageSize, term: searchInput}))
                }
            }
            else {
                if (selectedCategories !== null && selectedCategories.value !== null) {
                    dispatch(onGetPharmacyStock({page: pageNumber, size: pageSize, category: selectedCategories.value}))
                }else {
                    dispatch(onGetPharmacyStock({page: pageNumber, size: pageSize}))
                }
            }
        } else {
            if (term) {
                dispatch(onGetPharmacyStock({page: 1, size: 10, term: searchInput}))
            }
            else {
                dispatch(onGetPharmacyStock({page: 1, size: 10}))
            }
        }
    })
    
    const loadCategories = useCallback(() => {
        dispatch(onGetAllCategories())
    })

    useEffect(() => {
        loadStock();
        loadCategories();
    },[dispatch])


    useEffect(() => {
        setIsLoading(false)
    },[phStockReports])

    useEffect(() =>{
        if (all_categories && all_categories.length > 0) {
            const categories_list = all_categories.map( category => {
                return { label: category.name, value: category.id }
            })
            setCategoriesOptions([...categoriesOptions, ...categories_list])
        }
    },[all_categories])

    useEffect(() => {
        if ((searchInput.length !== null) && (searchInput.length > 2) ) {
            loadStock(1, 10, searchInput)
        } else {
            loadStock(1, 10)
        }
    },[selectedCategories, searchInput])

    const handleSelectCategory = (selectedCat) => {
        setselectedCat(selectedCat)
    }

    const handleSearch = () => {
        loadStock(1, 10, searchInput)
    }

    const columns = useMemo(
        () => [
            {
                header: props.t("s_no"),
                style: "cat",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return <span>
                        {phStockReports.page == 1 ? (cellProps.row.index + 1) : (((phStockReports.page-1)*10)+cellProps.row.index + 1) }
                    </span>;
                },
            },
            {
                header: props.t("product_name"),
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{cell.row.original.product.name}</span>
                }
            },
            {
                header: props.t("product_ref"),
                style: "type",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{cell.row.original.product.productRef}</span>
                }
            },
            {
                header: props.t("qty_in_stock"),
                style: "sale",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{cell.row.original.quantityInStock}</span>
                }
            },
            {
                header: props.t("status"),
                style: "action",
                enableColumnFilter: false,
                cell: (cell) => {
                    if (cell.row.original.quantityInStock > cell.row.original.product.minQuantity) {
                        return <button className="btn btn-success" style={{fontSize: "11px" }} disabled>{props.t("above_min")}</button>
                    } else {
                        return <button className="btn btn-danger" style={{fontSize: "11px" }} disabled>{props.t("below_min")}</button>
                    }
                }
            },
        ],
        [phStockReports]
    );


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">

                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                    <h6>{props.t("stock_reports")}</h6>
                                </div>
                            </div>

                            <div className="card border-0 row p-2">
                                <div className="mt-3" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <Row className='m-2 pb-3'>
                                        <Col xl={4} sm={4}>
                                            <div className='mb-2'>
                                                <Label htmlFor="status-field" className="form-label">{props.t("categories")}</Label><br/>
                                                <Select
                                                    name='status'
                                                    id='status-field'
                                                    value={selectedCategories}
                                                    isMulti={false}
                                                    style={{ zIndex: 9999 }}
                                                    isClearable={true}
                                                    onChange={(e) => {
                                                        handleSelectCategory(e);
                                                    }}
                                                    options={categoriesOptions}
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={4} sm={4}>
                                            <div className='mb-2'>
                                                <Label htmlFor="status-field" className="form-label">{props.t("search_product")}</Label><br/>
                                                <div className="d-flex align-items-center">
                                                    <div className="search-box me-2 mb-1 col-10">
                                                        <Input
                                                            className="custom-border form-control"
                                                            value={searchInput}
                                                            onChange={(e) => setSearchInput(e.target.value)}
                                                        />
                                                    </div>
                                                    <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                                        <i className="fa fa-search p-0"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="send-patient-to-doctor-main-sec pb-3">
                                        <TableContainerWarehouse
                                            columns={columns}
                                            data={((phStockReports && phStockReports.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ phStockReports && phStockReports.size }
                                            tablePage={ phStockReports && phStockReports.page }
                                            tableTotal= { phStockReports && phStockReports.total_items }
                                            tableTotalPage= { phStockReports && phStockReports.total_page }
                                            onNextPage= {loadStock}
                                            onSearch= {handleSearch}
                                            SearchPlaceholder={props.t("search_product")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PhStockReports))
