import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbItem, Breadcrumb } from 'reactstrap'


const BreadCrumb = ({ title, pageTitle }) => {
    return (
        <React.Fragment>
            <div className="d-flex">
                <Breadcrumb listTag="div">
                    <BreadcrumbItem tag="p">
                        <Link to="/settings">
                            {pageTitle}
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active tag="p" >
                        {title}
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
        </React.Fragment>
    );
};

export default BreadCrumb;