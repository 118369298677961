import React from 'react'
import withRouter from '../../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Modal, ModalHeader } from 'reactstrap'

const ThermalLabReports = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleLab} centered
        className="modal-dialog modal-dialog-centered modal-sm therm-lab-rep-modal"
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleLab}>
      </ModalHeader>
      <div className="modal-content therm-lab-rep-content">
          <div className="modal-body therm-lab-rep-body">
              <div className="therm-lab-rep-print">
                  <div className="text-center">
                      <img src={"../assets/images/HMS-Logo-1.png"} className="img-fluid" alt="HMS Logo" style={{ maxWidth: "50px" }} />
                      <h4 className="therm-lab-rep-title">ST. VERONICA MEDICAL CENTER</h4>
                      <p className="therm-lab-rep-subtitle m-0 p-0" style={{ color: "#45a049", margin: "0px", padding: "0px" }}>BOKOKO-BUEA</p>
                      <div className="">
                          <p className="therm-lab-rep-email m-0 p-0" style={{ color: "#45a049", fontSize: "12px", margin: "0px", padding: "0px" }}>
                            Email: St.veronicamedicalcenter@yahoo.com
                          </p>
                          <p className="therm-lab-rep-contact mb-1 p-0" style={{ margin: "0px", marginBottom: "10px", padding: "0px" }}>
                            Contact No: +251-9876543
                          </p>
                      </div>
                  </div>
                  <div className="therm-lab-rep-info">
                      <table className="therm-lab-rep-table">
                          <tr>
                              <td>Reg no.</td>
                              <td className="text fw-bold">ST.VERON086</td>
                          </tr>
                          <tr>
                              <td>BILL NO.</td>
                              <td className="text fw-bold">LAB-756</td>
                          </tr>
                          <tr>
                              <td>DATE</td>
                              <td className="text fw-bold">09/02/2024</td>
                          </tr>
                          <tr>
                              <td>PATIENT NAME</td>
                              <td className="text fw-bold">ALEX THOMAS</td>
                          </tr>
                          <tr>
                              <td>Pr. By</td>
                              <td className="text fw-bold">Dr. S. WATSON</td>
                          </tr>
                          <tr>
                              <td>ADDRESS:</td>
                              <td className="text fw-bold">BIPINDI, CAMEROON, AFRICA</td>
                          </tr>
                      </table>
                  </div>
                  <div className="therm-lab-rep-services">
                      <table className="therm-lab-rep-table">
                          <tr className="therm-lab-rep-border-top">
                              <th style={{ width: "10%" }}>S.NO.</th>
                              <th style={{ width: "60%" }}>SERVICES</th>
                              <th style={{ width: "30%", textAlign: "right" }}>AMOUNT</th>
                          </tr>
                          <tr>
                              <td>1</td>
                              <td>THYROID TH1, TH2</td>
                              <td className="text-end">$85.01</td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td>BLOOD TEST</td>
                              <td className="text-end">$148.25</td>
                          </tr>
                          <tr>
                              <td>3</td>
                              <td>HEMOGLOBIN</td>
                              <td className="text-end">$108.56</td>
                          </tr>
                      </table>
                  </div>
                  <div className="therm-lab-rep-amounts">
                      <table className="therm-lab-rep-table">
                          <tr className="therm-lab-rep-border-top">
                              <th>Cash Amt.</th>
                              <td className="text-end">$341.88</td>
                          </tr>
                          <tr>
                              <th>Less Dis</th>
                              <td className="text-end">$33.87</td>
                          </tr>
                          <tr>
                              <th>Net Amt.</th>
                              <td className="text-end">$308.01</td>
                          </tr>
                          <tr>
                              <th>Bal Amt</th>
                              <td className="text-end">$0.00</td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>
      </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(ThermalLabReports)) 
