import React, { useState } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from "react-router-dom"
import NewPatients from './NewPatients'
import PatientFromLab from './PatientFromLab'

function DoctorPanel(props) {

    const history = useNavigate()
    const { param } = useParams()
    const permission = JSON.parse(localStorage.getItem("perm"))
    const [activeTab, setActiveTab] = useState('new_patients-content')
    const handleTabChange = (target) => {
        setActiveTab(target);
    }

  return (
    <div className="new-patients-list-content">
            {/* <!-- doctor-panel Start --> */}
            <div className="patients-list py-4 px-2  ">
                <div className="container">
                    <div className="row list-patients d-flex align-items-center justify-content-between">
                        <div className="col-lg-4">
                            <h6 className="ps-2">{props.t("doctor-panel")}</h6>
                        </div>
                        <div className="col-lg-4 offset-lg-1 offset-0 mb-lg-0 mb-2">
                            {/* <div className="form-control search-input">
                                <i className="fas fa-search"></i>
                                <input type="text" className="search-field" placeholder="Search name/tel no./patient id" />
                            </div> */}
                        </div>

                        <div className="col-lg-3 text-align-center">
                            <Link to={"/doctor/doctor-panel/appointment"}>
                                <button className="btn btn-one" type="button">{props.t("upcoming_apoinments")} &nbsp;<i
                                        className="fa-solid fa-calendar-days"></i></button>
                            </Link>
                        </div>

                    </div>
                    {/* <!-- Patient Tabs and tables --> */}

                    {/* <!-- Tabs --> */}
                    <ul className="nav nav-tabs nav-underline d-flex mt-2" id="myTab" role="tablist"style={{ borderBottom: "none" }}>
                        
                        <li className="nav-item" role="presentation">
                            <button className={ activeTab === "new_patients-content" ? "nav-link active" : "nav-link" }  id="new_patients-tab" data-bs-toggle="tab"
                                data-bs-target="#new_patients" type="button" role="tab" aria-controls="new_patients"
                                aria-selected="true" onClick={() => {handleTabChange("new_patients-content")}}>
                                <i className="fa fa-user ms-1 me-2"></i> {props.t("new_patients")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={ activeTab === "patient_from_lab-content" ? "nav-link active" : "nav-link" } id="patient_from_lab-tab" data-bs-toggle="tab"
                                data-bs-target="#patient_from_lab" type="button" role="tab"
                                aria-controls="patient_from_lab" aria-selected="false" onClick={() => {handleTabChange("patient_from_lab-content")}}>
                                <i className="fa fa-user me-2"></i> {props.t("patient_from_lab")}
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className={ activeTab === "patient_from_lab-content" ? "nav-link active" : "nav-link" } id="patient_history-tab" data-bs-toggle="tab"
                                data-bs-target="#patient_history" type="button" role="tab"
                                aria-controls="patient_history" aria-selected="false" onClick={() => {handleTabChange("patient_history-content")}}>
                                <i className="fa fa-user me-2" aria-hidden="true"></i> {props.t("patient_history")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={ activeTab === "ward_status-content" ? "nav-link active" : "nav-link" } id="ward_status-tab" data-bs-toggle="tab"
                                data-bs-target="#ward_status" type="button" role="tab" aria-controls="ward_status"
                                aria-selected="false" onClick={() => {handleTabChange("ward_status-content")}}>
                                <i className="fa fa-user me-2" aria-hidden="true"></i> {props.t("ward_status")}
                            </button>
                        </li> */}
                    </ul>

                    {/* <!-- TABS Content --> */}
                    <div className="tab-content" id="myTabContent">
                        {/* <!--==================== TAB 1 : new-patient =================--> */}
                        <div className={activeTab === "new_patients-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="new_patients" role="tabpanel"
                            aria-labelledby="new_patients-tab">
                            <NewPatients className="p-3"  isActive={activeTab === 'new_patients-content'}/>
                        </div>
                        {/* <!--=================== TAB 2 : patient-from-lab ==================--> */}
                        <div className={activeTab === "patient_from_lab-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="patient_from_lab" role="tabpanel"
                            aria-labelledby="patient_history-tab">
                            <PatientFromLab className="p-3"  isActive={activeTab === 'patient_from_lab-content'}/>
                        </div>
                        {/* <!--==================== TAB 3 : patient_history ====================--> */}
                        <div className={activeTab === "patient_history-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="patient_history" role="tabpanel"
                            aria-labelledby="patient_history-tab">
                            {/* <!-- patient history contant --> */}

                            <div className="doctor-panel-history-table pt-1 pb-4">
                                <div className="card table-card table-responsive">
                                    <table className="table">
                                        <thead className="back-color">
                                            <tr>
                                                <th className="main">Patient ID</th>
                                                <th className="main">Patient Name</th>
                                                <th className="main">Mobile No.</th>
                                                <th className="action-main main">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Leslie Alexander</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#025833</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Ronald Richards</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Jane Cooper</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Robert Fox</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Jenny Wilson</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Marshall Cook</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Stephanie Cook</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Marion James</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />Teresa
                                                    Holland</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="sub-main">#098835</td>
                                                <td className="sub-main d-flex"> <img src="../assets/images/16.png" />
                                                    &nbsp;&nbsp;Zachary Marshall</td>
                                                <td className="sub-main">+251-1234568</td>
                                                <td className="action sub-main sub-main-eye">
                                                    <a href="../doctor-panel/screen-10.html"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <!-- Pages section --> */}
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination foot-page pt-3">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true"><i
                                                            className="fa-solid fa-angle-left"></i></span>
                                                </a>
                                            </li>
                                            <li className="page-item active"><a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item "><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link " href="#" aria-label="Next">
                                                    <span aria-hidden="true"><i
                                                            className="fa-solid fa-angle-right"></i></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* <!-- TAB 4 : ward_status --> */}
                        <div className={activeTab === "ward_status-content" ? "tab-pane fade show active card border-0": "tab-pane fade"} id="ward_status" role="tabpanel" aria-labelledby="ward_status-tab">
                            <div className="doctor-ward-status-list pt-1 pb-2 px-2 ">
                                <div className="container">
                                    <div className="row">
                                        {/* <!-- TAB  --> */}
                                        <ul className="nav nav-tabs nav-underline px-3 d-flex justify-content-between"
                                            id="myTab" role="tablist">
                                            <li className="nav-item " role="presentation">
                                                <button className="nav-link active " id="patient-registration-tab"
                                                    data-bs-toggle="tab" data-bs-target="#patient-registration"
                                                    type="button" role="tab" aria-controls="patient-registration"
                                                    aria-selected="true">
                                                    <i className="fa fa-user ms-1 me-2" aria-hidden="true"></i>Bed
                                                    Status&nbsp;&nbsp;&nbsp;&nbsp;
                                                </button>
                                            </li>
                                            <li className="choose d-flex">
                                                <div className="color-occupied"><a href="">Occupied<i
                                                            className="fa-solid fa-square"></i></a>
                                                </div>
                                                <div className="color-free"><a href="">Free<i
                                                            className="fa-solid fa-square"></i></a></div>
                                                <div className="color-outof-free"><a href="">Out of free<i
                                                            className="fa-solid fa-square"></i></a></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <!-- TABS Content --> */}
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="patient-registration" role="tabpanel"
                                        aria-labelledby="patient-registration-tab">
                                        {/* <!-- Output-patient-bed-status-TABLE--> */}
                                        <div className="all-bed-status pb-5">
                                            <div className="container">
                                                <div className="row px-2 table-responsive">
                                                    <table className="table-striped table-responsive">
                                                        <tr>
                                                            <th className="count-head">VIP Ward</th>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">01</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">02</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">03</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">04</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">05</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">06</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">07</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">08</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">09</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">10</div>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>Pvt. Non AC</th>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">01</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">02</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">03</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">04</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">05</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">06</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">07</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">08</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">09</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">10</div>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>Pvt. AC</th>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">01</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">02</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">03</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">04</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">05</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">06</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">07</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">08</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">09</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">10</div>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>ICU</th>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">01</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">02</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">03</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">04</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">05</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">06</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">07</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">08</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">09</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">10</div>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>General</th>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">01</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">02</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed occupied"></i>
                                                                <div className="count-occupied">03</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">04</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">05</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">06</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">07</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed free-bed"></i>
                                                                <div className="count-free">08</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">09</div>
                                                            </td>
                                                            <td><i className="fa-solid fa-bed out-of-use"></i>
                                                                <div className="count-out-of-use">10</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default withRouter(withTranslation()(DoctorPanel))