

import { Link } from "react-router-dom";

const CustomPagination = ({ item, gotoPage, tablePage, tableTotalPage, setCurrentPage }) => {
    const isFirstPage = item <= 1;
    const isLastPage = item >= tableTotalPage;
    const isWithinTwoPagesOfCurrent = Math.abs(item - tablePage) <= 1;
  
    if (isFirstPage || isLastPage || isWithinTwoPagesOfCurrent) {
      return (
        <li className="page-item">
          <Link
            to="#"
            className={`page-link ${tablePage === item ? 'active text-white' : ''}`}
            onClick={() => {gotoPage(item, 10); setCurrentPage(item)}}
          >
            {item}
          </Link>
        </li>
      );
    }
  
    if (Math.abs(item - tablePage) === 2) {
      return (
        <li className="page-item">
          <Link to="#" className="page-link page-item disabled">
            ...
          </Link>
        </li>
      );
    }
    return ''; 
  };

  export default CustomPagination;