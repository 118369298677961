import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import {
    getSuppliers as onGetSuppliers,
    getSupplier as onGetSupplier,
    createSupplier as onCreateSupplier,
    updateSupplier as onUpdateSupplier,
    deleteSupplier as onDeleteSupplier,
    searchSupplier as onSearchSupplier,
  } from "../../../slice/thunks"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import { Input, Modal, FormFeedback, Form, ModalBody, Row, Col } from 'reactstrap'
import DeleteModal from '../../../components/Global/DeleteModal'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const  RecordSuppliers = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            suppliers: setting.suppliers,
            supplier: setting.supplier,
            createSupplierSuccess: setting.createSupplierSuccess,
            updateSupplierSuccess: setting.updateSupplierSuccess,
            deleteSupplierSuccess: setting.deleteSupplierSuccess,
            error: setting.error
        })
    )
    const { suppliers, supplier, createSupplierSuccess, updateSupplierSuccess, deleteSupplierSuccess, error } = useSelector(SettingsProperties)
    const [currentSupplier, setCurrentSupplier] = useState(null)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    const loadSuppliers = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetSuppliers({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetSuppliers())
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentSupplier(null);
        } else {
          setModal(true);
        }
    }, [modal])

    const searchSupplier = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onSearchSupplier({term: term, page: pageNumber, size: pageSize}))
        } else {
            dispatch(onSearchSupplier({term: term, page: 1, size: 10}))
        }
    },[])


    useEffect(() => {
        loadSuppliers()
    },[dispatch])

    useEffect(() => {
      setIsLoading(false)
    },[suppliers])

    useEffect(() => {
        if (createSupplierSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentSupplier(null)
          loadSuppliers()
        }
    },[createSupplierSuccess, error, supplier])

    useEffect(() => {
        if (deleteSupplierSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentSupplier(null)
          loadSuppliers()
        }
    },[deleteSupplierSuccess, error, supplier])

    useEffect(() => {
        if (updateSupplierSuccess && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentSupplier(null)
          loadSuppliers()
        }
    },[updateSupplierSuccess, error, supplier])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
       
        initialValues: {
          id: (currentSupplier && currentSupplier.id) || '',
          companyName: (currentSupplier && currentSupplier.companyName) || '',
          personToContact: (currentSupplier && currentSupplier.personToContact) || '',
          phone: (currentSupplier && currentSupplier.phone) || '',
          email: (currentSupplier && currentSupplier.email) || '',
          region: (currentSupplier && currentSupplier.address && currentSupplier.address.region) || '',
          city: (currentSupplier && currentSupplier.address && currentSupplier.address.city) || '',
          address: (currentSupplier && currentSupplier.address && currentSupplier.address.address) || '',
        },
        validationSchema: Yup.object({
          companyName: Yup.string().required(props.t("you_need_provide_name")),
          personToContact: Yup.string().required(props.t("you_need_provide_contact_name")),
        }),
        onSubmit: (values) => {

    
          if (isEdit) {
            const updateSupplierForm = {
                companyName: values.companyName,
                personToContact: values.personToContact,
                phone: values.phone,
                email: values.email,
                address: {
                    region: values.region,
                    city: values.city,
                    address: values.address
                }
            }
            const id = currentSupplier.id
    
            dispatch(onUpdateSupplier({ body: updateSupplierForm, id: id }))
          } else {
            const newSupplierForm = {
                companyName: values.companyName,
                personToContact: values.personToContact,
                phone: values.phone,
                email: values.email,
                address: {
                    region: values.region,
                    city: values.city,
                    address: values.address
                }
            }
            dispatch(onCreateSupplier(newSupplierForm))
          }
          
        },
    })

    const handleOpen = () => {
        setIsEdit(false)
        toggle()
    }

    const handleSelectSupplier = (arg) => {
        setCurrentSupplier(arg)
        setIsEdit(true);
        toggle();
    }

    const onClickDelete = (supplier) => {
        setCurrentSupplier(supplier);
        setDeleteModal(true);
    }
    
    function handleDeleteSupplier() {
        if (currentSupplier) {
          dispatch(onDeleteSupplier( currentSupplier.id ))
          loadSuppliers()
          setDeleteModal(false)
          setCurrentSupplier(null)
        }
    }

    const columns = useMemo(
        () => [
          
          {
            header: props.t("companyName"),
            accessorKey: "companyName",
            style: "name",
            enableColumnFilter: false,
          },
          {
            header: props.t("contact_person"),
            accessorKey: "personToContact",
            style: "name",
            enableColumnFilter: false,
          },
          {
            header: props.t("phone"),
            accessorKey: "phone",
            style: "name",
            enableColumnFilter: false,
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-center">
                    <ul className="list-inline hstack mb-0">
                      <li className="list-inline-item edit" title="Edit">
                        <Link
                          to="#"
                          className="text-primary d-inline-block edit-item-btn"
                          onClick={() => { const supplierData = cellProps.row.original; handleSelectSupplier(supplierData); }}
                        >
                          <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                        </Link>
                      </li>
                      <li className="list-inline-item" title="Remove">
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => { const supplierData = cellProps.row.original; onClickDelete(supplierData); }}
                        >
                          <img src="../assets/images/delete.png" alt=""></img>
                        </Link>
                      </li>
                    </ul>
                </div>
              );
            },
          },
        ],
        [handleSelectSupplier]
    );


  return (
    <React.Fragment>
      {isLoading ?
          <GlobalLoader />
        :
        <div className="">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteSupplier} onCloseClick={() =>setDeleteModal(false)} />
            <div className="setting-zone-screen-9-sec">
                <div className="container-fluid">
                    <div className="id g-4  px-2 pb-5 ">
                        <div className="heading-parent-sec ">
                            <div className="heading-child-one-sec pt-4">
                                <h6>{props.t("record_suppliers")}</h6>
                            </div>
                            <div className="heading-child-two-sec pt-4">
                                <div className="create-new-patient-btn my-2 ps-2">
                                    <div className="create-new-patient-btn-child-one">
                                        <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                    </div>
                                    <div className="create-new-patient-btn-child-two">
                                        <a href="#">
                                            <button className="btn" onClick={() =>{handleOpen()}}  role="button">{props.t("add_supplier")}</button>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="setting-zone-screen-9">
                            {/* <div className="row py-3 ps-4 m-0">
                                <div className="col-md-4">
                                    <label htmlFor="height" className="form-label">Filter Supplier</label>
                                    <select className="form-select item" aria-label="Default select example">
                                        <option selected className="select">Supplier</option>
                                    </select>
                                </div>
                                <div className="col-md-8 d-flex justify-content-end align-items-center">
                                    <div className="btnn-two pe-4">
                                        <div className="dropdown">
                                            <button className="btn " type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false">Print&nbsp;<i
                                                    className="fa-solid fa-print"></i></button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="#">Export CXV</a></li>
                                                <li><a className="dropdown-item" href="#">Export XLS</a></li>
                                                <li><a className="dropdown-item" href="#">Export PDF</a></li>
                                                <li><a className="dropdown-item" href="#">Import XLS</a></li>
                                                <li><a className="dropdown-item" href="#">Import XLS</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='card m-2'>
                                <TableContainerWarehouse
                                    columns={columns}
                                    data={((suppliers && suppliers.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="card table-card table-warehouse table-responsive"
                                    tableClass="table"
                                    theadClass="thead-sec table-light"
                                    tdClass="td"
                                    tableSize={ suppliers && suppliers.size }
                                    tablePage={ suppliers && suppliers.page }
                                    tableTotal= { suppliers && suppliers.total_items }
                                    tableTotalPage= { suppliers && suppliers.total_page }
                                    onNextPage= {loadSuppliers}
                                    onSearch= {searchSupplier}
                                    SearchPlaceholder={props.t("search_supplier")}
                                />
                            </div>

                            {/* <div className="card table-card table-responsive">
                                <table className="table "> 
                                    <thead className="back-color">
                                        <tr>
                                            <th className="main ps-4">Name</th>
                                            <th className="main">Mobile No.</th>
                                            <th className="main">Balance</th>
                                            <th className="action-main main">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="sub-main ps-4">Ajeet Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.1000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">JK Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.2000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">John Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.1000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">Robert Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.2000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">Ajeet Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.1000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">JKL Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.2000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">John Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.1000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">Robert Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.2000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">Ajeet Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.1000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sub-main ps-4">John Pharmacy</td>
                                            <td className="sub-main">+251-1234568</td>
                                            <td className="sub-main">Fr.2000</td>
                                            <td className="action sub-main">
                                                <div className="eye-pen">
                                                    <a href="#"><i
                                                            className="fa-solid fa-eye"></i></a>
                                                    <a href="#"><i
                                                            className="fa-solid fa-pencil"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        {/* <!--ADD Supplier Modal  -->  */}
                        <Modal isOpen={modal || false} toggle={toggle} id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                            {/* <div className="modal-dialog modal-dialog-centered"> */}
                                <div className="modal-content ">
                                    <div className="modal-header">
                                        <h6>{isEdit ? props.t("edit_supplier") : props.t("add_supplier")}</h6>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            style={{ borderRadius:" 50%", border:"1px solid rgba(171, 175, 179, 1)" }} onClick={() => { toggle ()}}></button>
                                    </div>
                                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                                            console.log("e: ", e);
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                    }}>
                                        <ModalBody className="p-0">
                                            <Row className="px-4">
                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="companyName-field" className="form-label">{props.t("companyName")}<span className='text-danger'>*</span></label>
                                                    <Input 
                                                        name='companyName' 
                                                        type="text" 
                                                        id="companyName-field" 
                                                        placeholder=""  
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.companyName || ""}
                                                        invalid={
                                                            validation.touched.companyName && validation.errors.companyName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.companyName && validation.errors.companyName ? (
                                                        <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="phone-field" className="form-label">{props.t("phone")}</label>
                                                    <Input 
                                                        id="phone-field" 
                                                        name='phone' 
                                                        type="text" 
                                                        placeholder="" 
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone || ""}
                                                        invalid={
                                                            validation.touched.phone && validation.errors.phone ? true : false
                                                        }
                                                    />
                                                    {validation.touched.phone && validation.errors.phone ? (
                                                        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col md={12} className="pt-2">
                                                    <label htmlFor="personToContact-field" className="form-label">{props.t("contact_person")}<span className='text-danger'>*</span></label>
                                                    <Input 
                                                        name='personToContact' 
                                                        type="text" 
                                                        id="personToContact-field" 
                                                        placeholder=""  
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.personToContact || ""}
                                                        invalid={
                                                            validation.touched.personToContact && validation.errors.personToContact ? true : false
                                                        }
                                                    />
                                                    {validation.touched.personToContact && validation.errors.personToContact ? (
                                                        <FormFeedback type="invalid">{validation.errors.personToContact}</FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="weight" className="form-label">{props.t("email")}</label>
                                                    <Input 
                                                        id="weight" 
                                                        type="email" 
                                                        name='email' 
                                                        placeholder="" 
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="weight" className="form-label">{props.t("region")}</label>
                                                    <Input 
                                                        id="weight" 
                                                        type="text" 
                                                        name='region' 
                                                        placeholder="" 
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.region || ""}
                                                        invalid={
                                                            validation.touched.region && validation.errors.region ? true : false
                                                        }
                                                    />
                                                    {validation.touched.region && validation.errors.region ? (
                                                        <FormFeedback type="invalid">{validation.errors.region}</FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="weight" className="form-label">{props.t("city")}</label>
                                                    <Input 
                                                        name='city' 
                                                        type="text" 
                                                        id="weight" 
                                                        placeholder="" 
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city ? true : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                                    ) : null}
                                                    
                                                </Col>

                                                <Col md={6} className="pt-2">
                                                    <label htmlFor="weight" className="form-label">{props.t("address")}</label>
                                                    <Input 
                                                        type="text" 
                                                        id="weight" 
                                                        name='address' 
                                                        placeholder="" 
                                                        className="form-control" 
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address && validation.errors.address ? true : false
                                                        }
                                                    />
                                                    {validation.touched.address && validation.errors.address ? (
                                                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center mt-4 ps-3 pe-3 mb-4">
                                                <button className="btn1 mb-3" type="button" onClick={() => { toggle() }}>{props.t("back")}</button>
                                                <button className="btn2 mb-2" type="submit">{isEdit ? props.t("edit_supplier") : props.t("add_supplier")}</button>
                                            </div>
                                        </ModalBody>
                                    </Form>
                                </div>
                            {/* </div> */}
                        </Modal>

                    </div>
                </div>
            </div>
        </div>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(RecordSuppliers))
