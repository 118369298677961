import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'

import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
    getOtherRevenues as onGetOtherRevenues,
    getSubClassAccount as onGetSubClassAccount,
    getPaymentMode as onGetPaymentMode,
    createOtherRevenue as onCreateOtherRevenue,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"

const OtherRevenue = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      otherRevenues: setting.otherRevenues,
      otherRevenue: setting.otherRevenue,
      subClassAccount: setting.subClassAccount,
      paymentModes: setting.paymentModes,
      createOtherRevenueSuccess: setting.createOtherRevenueSuccess,
      error: setting.error
    })
  )
  const { otherRevenues, otherRevenue, subClassAccount, createOtherRevenueSuccess, paymentModes, error } = useSelector(SettingsProperties)
  const [classAccountOption, setClassAccountOption] = useState(null)
  const [paymentModeOption, setPaymentModeOption] = useState(null)
  const [selectedClassAccount, setSelectedClassAccount] = useState(null)
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null)
  const [currentRevenue, setCurrentRevenue] = useState(null)
  const [modal, setModal] = useState(false)
  const [isView, setIsView] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const loadOtherRevenues = useCallback(() => {
      dispatch(onGetOtherRevenues()) 
  })

  const loadPaymentMode = useCallback(() => {
      dispatch(onGetPaymentMode())
  })

  const loadClassAccount = useCallback(() => {
      dispatch(onGetSubClassAccount())
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentRevenue(null);
    } else {
      setModal(true);
    }
  }, [modal])

  const handleOpen = () => {
    setIsView(false)
    toggle()
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (currentRevenue && currentRevenue.id) || '',
      amount: (currentRevenue && currentRevenue.amount) || '',
      classAccountId: (currentRevenue && currentRevenue.classAccountId) || '',
      description: (currentRevenue && currentRevenue.description) || '',
      date: (currentRevenue && currentRevenue.date) || '',
      paymentMode: (currentRevenue && currentRevenue.paymentMode) || '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().required(props.t("you_need_provide_amount")),
      description: Yup.string().required(props.t("you_need_provide_description")),
    }),
    onSubmit: (values) => {

        const newForm = {
          amount: values.amount,
          description: values.description,
          date: values.date,
          classAccountId: selectedClassAccount && selectedClassAccount.value,
          paymentMode: selectedPaymentMode && selectedPaymentMode.value,
        }

        console.log(newForm);
        
        dispatch(onCreateOtherRevenue(newForm))
    },
  })

  useEffect(() => {
    loadOtherRevenues()
    loadPaymentMode()
    loadClassAccount()
  },[dispatch])

  useEffect(() => {
    if (otherRevenues && otherRevenues.page >= 0) {
    setIsLoading(false)
    }
  },[otherRevenues])

  useEffect(() => {
    if (paymentModes && paymentModes.length > 0) {
        const payment_lists = paymentModes.filter(mode => mode.value !== 'PATIENT_CREDIT').map(mode => ({
            label: mode.title,
            value: mode.value
        }));
        setPaymentModeOption(payment_lists);
    }
  }, [paymentModes]);

  useEffect(() =>{
    if (subClassAccount && subClassAccount.length > 0) {
      const subClassAccount_list = subClassAccount.map( subclass => {
        return { label: subclass.name, value: subclass.id }
      })
      setClassAccountOption(subClassAccount_list)
    }
  },[subClassAccount])

  useEffect(() => {
    if (createOtherRevenueSuccess && !error) {
      loadOtherRevenues()
      toggle()
    }
  },[createOtherRevenueSuccess])

  const handleSelectRevenue = (arg) => {
    setCurrentRevenue(arg)
    setSelectedClassAccount(arg.classAccountId && (classAccountOption != null) ? classAccountOption.find((element) => element.value === arg.classAccountId) : null)
    setSelectedPaymentMode(arg.paymentMode && (paymentModeOption != null) ? paymentModeOption.find((element) => element.value === arg.paymentMode) : null )

    setIsView(true)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("no"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {otherRevenues.page == 1 ? (cellProps.row.index + 1) : (((otherRevenues.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
          header: props.t("date"),
          style: "cat",
          enableColumnFilter: false,
          cell: (cell) => {
              return <span>
                  {moment(cell.row.original.date).format('DD / MM/ YYYY')}
              </span>;
          },
      },
      {
        header: props.t("payment_mode"),
        accessorKey: "paymentMode",
        style: "cat",
        enableColumnFilter: false,
      },
      {
        header: props.t("description"),
        accessorKey: "description",
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
            const truncateText = (text, maxLength) => {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
                return text;
            };
        
            return <span>{truncateText(cell.row.original.description, 20)}</span>;
        }
      },
      {
        header: props.t("account"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.classAccountName}</span>
        }
      },
      {
        header: props.t("done_by"),
        style: "sale",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.doneBy}</span>
        }
      },
      // {
      //   header: props.t("action"),
      //   style: "action",
      //   cell: (cellProps) => {
      //     return (
      //       <div className="d-flex justify-content-center">
      //           <ul className="list-inline hstack mb-0">
      //               <li className="list-inline-item edit" title="Edit">
      //                   <Link
      //                   to="#"
      //                   className="text-primary d-inline-block edit-item-btn"
      //                   onClick={() => { const accountData = cellProps.row.original; handleSelectAccount(accountData); }}
      //                   >
      //                   <img src="../assets/images/edit.png" alt="" className="me-2"></img>
      //                   </Link>
      //               </li>
      //               <li className="list-inline-item" title="Remove">
      //                   <Link
      //                   to="#"
      //                   className="text-danger d-inline-block remove-item-btn"
      //                   onClick={() => { const accountData = cellProps.row.original; onClickDelete(accountData); }}
      //                   >
      //                   <img src="../assets/images/delete.png" alt=""></img>
      //                   </Link>
      //               </li>
      //           </ul>
      //       </div>
      //     );
      //   },
      // },
    ],
    [otherRevenues, handleSelectRevenue]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <div className="patients-list-content">
              <div className="patients-list px-2 pb-5 ">
                  <div className="container-fluid ">
                      <div className="heading-parent-sec ">
                          <div className="heading-child-one-sec pt-4">
                              <h6>{props.t("other_revenues")}</h6>
                          </div>
                          <div className="heading-child-two-sec pt-4">
                              <div className="create-new-patient-btn-child-two">
                                <SinpleActionButton title={props.t("add_other_revenue")} img="../assets/images/user.png" action={handleOpen} />
                              </div>
                          </div>
                      </div>
                      <div className="card border-0 row p-2">
                        <div className="row">
                                <TableContainerWarehouse
                                columns={columns}
                                data={((otherRevenues && otherRevenues.items) || [])}
                                isGlobalFilter={false}
                                customPageSize={10}
                                divClass="card table-card table-warehouse table-responsive"
                                tableClass="table"
                                theadClass="thead-sec table-light"
                                tdClass="td"
                                tableSize={ otherRevenues && otherRevenues.size }
                                tablePage={ otherRevenues && otherRevenues.page }
                                tableTotal= { otherRevenues && otherRevenues.total_items }
                                tableTotalPage= { otherRevenues && otherRevenues.total_page }
                                onNextPage= {loadOtherRevenues}
                                onSearch= {loadOtherRevenues}
                                SearchPlaceholder={props.t("search_requisition")}
                                />
                        </div>
                      </div>

                      </div>
              </div>
          </div>

          <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {isView ? "" : props.t("add_other_revenue")}
            </ModalHeader>
            <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
              <ModalBody>
                <Row>
                  <Col xl={6} sm={6}>
                    <Label htmlFor="">{props.t("amount")}<span className='text-danger'>*</span></Label>
                    <Input 
                        className="p-3" 
                        type="number" 
                        id="amount" 
                        name="amount"
                        placeholder=""
                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        invalid={
                            validation.touched.amount && validation.errors.amount ? true : false
                        }
                        disabled= { isView ? true : false }
                    />
                    {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                    ) : null}
                  </Col>
                  <Col xl={6} sm={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="date-field"
                        className="form-label"
                      >
                        {props.t("date")}<span className='text-danger'>*</span>
                      </Label>
                      <Input
                        name="date"
                        id="date-field"
                        className="form-control"
                        autoComplete="off"
                        placeholder={props.t("date")}
                        type="date"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.date || ""}
                        invalid={
                          validation.touched.date && validation.errors.date ? true : false
                        }
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={6} sm={6}>
                      <div className="mb-3">
                      <Label
                          htmlFor="wards-field"
                          className="form-label"
                      >
                          {props.t("choose_class")}
                      </Label>
                      <Select
                          name='accountType'
                          id='accountType-field'
                          value={selectedClassAccount}
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off" 
                          // className='p-0'
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                              setSelectedClassAccount(e);
                          }}
                          options={classAccountOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled= { isView ? true : false }
                      />
                      </div>
                  </Col>
                  <Col xl={6} sm={6}>
                      <div className="mb-3">
                      <Label
                          htmlFor="paymentMode-field"
                          className="form-label"
                      >
                          {props.t("payment_mode")}
                      </Label>
                      <Select
                          name='paymentMode'
                          id='paymentMode-field'
                          value={selectedPaymentMode}
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off" 
                          // className='p-0'
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                              setSelectedPaymentMode(e);
                          }}
                          options={paymentModeOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled= { isView ? true : false }
                      />
                      </div>
                  </Col>
                  <Col  xl={12} sm={12}>
                      <Label htmlFor="">{props.t("description")}<span className='text-danger'>*</span></Label>
                      <textarea
                          className="p-3"
                          type="text"
                          id="description"
                          name="description"
                          placeholder=""
                          style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                          validate={{
                              required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                              validation.touched.description && validation.errors.description ? true : false
                          }
                          disabled= { isView ? true : false }
                      />
                      {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                  </Col>
                </Row>
              </ModalBody>
              <div className='p-3'>
                <Row className="align-items-center">
                  <div className="col-sm">
                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                  </div>
                  <div className="col-sm-auto">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentRevenue(null); setIsView(false) }}> {props.t("close")} </button>
                      {!isView ?
                        <button type="submit" className="btn btn-success"> { props.t("save")} </button>
                        :
                        <></>
                      }
                    </div>
                  </div>
                </Row>
              </div>
            </Form>
          </Modal>
        </>
      }
        
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(OtherRevenue))
