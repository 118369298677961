import React from 'react'
import withRouter from '../../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'

const MatrixCashier = (props) => {
  return ( 
    <Modal id='' isOpen={props.isOpen || false} toggle={props.toggleCashier} centered
        className="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content" style={{ fontFamily: "Courier, monospace", backgroundColor: "#fefefe", padding: "10px", width: "max-content", height: "max-content"}}>
                <div class="container">
                    <div class="header">
                        <div class="reg-no" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "140px", margin: "0" }}>
                            <p style={{ fontFamily: "Courier, monospace", fontSize: "11px" }}>REG. No: ST.VERON52451C</p>
                            <p style={{ padding: "0", fontSize: "22px", fontFamily: "Courier, monospace", margin: "0" }}>Tax Invoice</p>
                        </div>
                        <h4 style={{ textAlign: "center", fontFamily: "Courier, monospace", fontWeight: "600" }}>ST. VERONICA MEDICAL CENTER</h4>
                        <h6 style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>BOKOKO-BUEA</h6>
                        <div style={{ display: "flex", justifyContent: "center", gap: "15px", margin: "0", flexDirection: "row" }}>
                            <p style={{ fontFamily: "Courier, monospace"}}>Email: St.veronicamedicalcenter@yahoo.com</p>
                            <p style={{ fontFamily: "Courier, monospace"}}>Contact No: +251-9876543</p>
                        </div>
                    </div>

                    <div class="section" style={{ marginBottom: "0" }}>
                        <div class="m-0">
                            <table class="table table-borderless m-0">
                                <tbody style={{ borderTop: "1px solid black" }}>
                                    <tr>
                                        <th style={{ fontFamily: "Courier, monospace" }}>BILL NO.</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>LAB-756</td>
                                        <th style={{ fontFamily: "Courier, monospace"}}>Patient ID</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>12334</td>
                                        <th style={{ fontFamily: "Courier, monospace"}}>DATE</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>09/02/2024</td>
                                    </tr>
                                    <tr>
                                        <th style={{ fontFamily: "Courier, monospace"}}>PATIENT NAME</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>ALEX THOMAS</td>
                                        <th style={{ fontFamily: "Courier, monospace", textAlign: "center" }}>Gender</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>Male</td>
                                        <th style={{ fontFamily: "Courier, monospace"}}>Pr. By</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>Dr. S. WATSON</td>
                                    </tr>
                                    <tr>
                                        <th style={{ fontFamily: "Courier, monospace"}}>ADDRESS</th>
                                        <td style={{ fontFamily: "Courier, monospace", fontSize: "12px" }}>BIPINDI, CAMEROON, AFRICA</td>
                                        <th style={{ fontFamily: "Courier, monospace", textAlign: "center" }}>Age:</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>25</td>
                                        <th style={{ fontFamily: "Courier, monospace"}}>Bed No.</th>
                                        <td style={{ fontFamily: "Courier, monospace"}}>VIP 02</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="section two" style={{ margin: "0" }}>
                        <div class="m-0">
                            <table class="table table-borderless mb-4">
                                <tbody style={{ border: "none !important" }}>
                                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                        <th style={{ fontFamily: "Courier, monospace" }}>S.NO.</th>
                                        <th style={{ fontFamily: "Courier, monospace" }}>IPD Service Details</th>
                                        <th style={{ fontFamily: "Courier, monospace" }}>QTY</th>
                                        <th style={{ fontFamily: "Courier, monospace" }}>Rate</th>
                                        <th style={{ fontFamily: "Courier, monospace" }}>Amount</th>
                                    </tr>
                                    <tr>
                                        <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>Operation Charge</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>256.81</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>256.81</td>
                                    </tr>
                                    <tr style={{ borderBottom: "none !important;" }}>
                                        <td style={{ fontFamily: "Courier, monospace"}}>2</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>Bed Charge (VIP WARD 02)</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>85.07</td>
                                        <td style={{ fontFamily: "Courier, monospace"}}>85.07</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="totals" style={{ margin: "0" }}>
                        <div class="m-0">
                            <table class="table table-borderless m-0">
                                <tr style={{ borderTop: "1px solid black" }}>
                                    <td style={{ fontFamily: "Courier, monospace" }}>INCL TAXES DETAILS: </td>
                                    <td style={{ fontFamily: "Courier, monospace", width: "60%" }}>203.20x12%= 24.38,<br/>GST 0% AMT: 80.10</td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>VAT: <b>$ 12.19</b></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>Gross <br/>Less Diss</td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 341.88 <br/>: $ 33.87</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td style={{ visibility: "hidden" }}></td>
                                    <td></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="section" style={{ marginTop: "0" }}>
                        <div class="m-0">
                            <table class="table table-borderless m-0">
                                <tr style={{ borderTop: "1px solid black" }}>
                                    <td style={{ fontFamily: "Courier, monospace" }}>E.& O.E. &nbsp; MATHEW &nbsp;12:26pm </td>
                                    <td style={{ textAlign: "left", fontFamily: "Courier, monospace" }} ></td>
                                    <td style={{ visibility: "hidden" }}></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}><b> Total Amnt.</b></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.01</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid black" }}>
                                    <td style={{ whiteSpace:"nowrap", fontFamily: "Courier, monospace" }}>USD: Three Hundred and Eight Only </td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>Net Amt. (R/O)</td>
                                    <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.00</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="footer">
                        <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center", margin: "0", padding: "0", fontFamily: "Courier, monospace" }}>We Care We Protect</p>
                        <p style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>
                          Book Your Free Home Blood Sample Collection On: 
                          <span style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>+251-9876543</span>
                        </p>
                    </div>
                </div>
            </div>

    </Modal>
  )
}

export default withRouter(withTranslation()(MatrixCashier))
