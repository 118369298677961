import React from 'react'
import withRouter from '../../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Modal, ModalHeader } from 'reactstrap'

const ThermalCashier = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleCashier} centered
        className="modal-dialog modal-dialog-centered modal-sm therm-lab-rep-modal"
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleCashier}>
      </ModalHeader>
      <div class="modal-body therm-lab-rep-body">
          <div class="therm-lab-rep-print">
              <div class="text-center">
                  <img src={"../assets/images/HMS-Logo-1.png"} class="img-fluid" alt="HMS Logo" style={{ maxWidth: "50px" }} />
                  <h4 class="therm-lab-rep-title">ST. VERONICA MEDICAL CENTER</h4>
                  <p class="therm-lab-rep-subtitle m-0 p-0" style={{ color: "#45a049" }}>BOKOKO-BUEA</p>
                  <div class="">
                      <p class="therm-lab-rep-email m-0 p-0" style={{ fontSize: "12px" }}>Email: St.veronicamedicalcenter@yahoo.com</p>
                      <p class="therm-lab-rep-contact mb-1 p-0">Contact No: +251-9876543</p>
                  </div>
              </div>
              <div class="therm-lab-rep-info">
                  <table class="therm-lab-rep-table">
                      <tr>
                          <td>REG NO.</td>
                          <td class="text fw-bold">STVERON1234</td>
                      </tr>
                      <tr>
                          <td>BILL NO.</td>
                          <td class="text fw-bold">LAB-756</td>
                      </tr>
                      <tr>
                          <td>DATE</td>
                          <td class="text fw-bold">09/02/2024</td>
                      </tr>
                      <tr>
                          <td>PATIENT NAME</td>
                          <td class="text fw-bold">ALEX THOMAS</td>
                      </tr>
                      <tr>
                          <td>Pr. By</td>
                          <td class="text fw-bold">Dr. S. WATSON</td>
                      </tr>
                      <tr>
                          <td>ADDRESS:</td>
                          <td class="text fw-bold">BIPINDI, CAMEROON, AFRICA</td>
                      </tr>
                  </table>
              </div>
              <table class="therm-lab-rep-table therm-lab-rep-border-top">
                  <tr>
                      <th style={{ width: "10%" }}>S.NO.</th>
                      <th style={{ width: "50%" }}>IPD Service Details</th>
                      <th style={{ width: "10%" }}>QTY</th>
                      <th style={{ width: "15%" }}>Rate</th>
                      <th style={{ width: "15%" }}>Amount</th>
                  </tr>
                  <tr>
                      <td>1</td>
                      <td>Operation Charge</td>
                      <td>1</td>
                      <td>256.81</td>
                      <td>256.81</td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Bed Charge (VIP WARD 02)</td>
                      <td>1</td>
                      <td>85.07</td>
                      <td>85.07</td>
                  </tr>
              </table>
              <table class="therm-lab-rep-table therm-lab-rep-border-top">
                  <tr>
                      <td style={{ width: "50%" }}>INCL TAXES DETAILS: 203.20x12%= 24.38, GST 0% AMT: 80.10</td>
                      <td style={{ width: "50%", textAlign: "right" }}>VAT: $ 12.19</td>
                  </tr>
                  <tr>
                      <td>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                      <td class="text-end"><b>Total Amnt.</b>: $ 308.01</td>
                  </tr>
                  <tr>
                      <td>USD: Three Hundred and Eight Only </td>
                      <td class="text-end">Net Amt. (R/O): $ 308.00</td>
                  </tr>
              </table>
              <div class="therm-lab-rep-signature">
                  <p style={{ textAlign: "center", margin: "0", padding: "0" }}>We Care We Protect</p>
                  <p class="therm-lab-rep-booking" style={{ textAlign: "center", margin: "0", padding: "0", fontSize: "8px" }}>
                    Book Your Free Home Blood Sample Collection On:
                    <span class="text-primary">+251-9876543</span>
                  </p>
              </div>
          </div>
      </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(ThermalCashier))
