import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getFinancialYears as onGetFinancialYears,
  getFinancialYear as onGetFinancialYear,
  createFinancialYear as onCreateFinancialYear,
  updateProductDiscount as onUpdateProductDiscount,
  closeFinancialYear as onCloseFinancialYear,
  openFinancialYear as onOpenFinancialYear
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const FinancialYear = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings
  const selectLayoutStatePatient = (state) => state.Outputs
  
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      financialYears: setting.financialYears,
      financialYear: setting.financialYear,
      createFinancialYearSuccess: setting.createFinancialYearSuccess,
      closeFinancialYearSuccess: setting.closeFinancialYearSuccess,
      openFinancialYearSuccess: setting.openFinancialYearSuccess,
      error: setting.error
    })
  )

  const { financialYears, financialYear, createFinancialYearSuccess, closeFinancialYearSuccess, openFinancialYearSuccess, error } = useSelector(SettingsProperties)

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedProduct, setSellectedProduct] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"))
  const [currentFinancialYear, setCurentFinancialYear] = useState(null)

  const loadFinancialYears = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetFinancialYears({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetFinancialYears())
    }
  })

  const loadFinancialYear = useCallback(() => {
    dispatch(onGetFinancialYear())
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
    } else {
      setModal(true);
    }
  }, [modal])
  
  const searchYear = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetFinancialYears({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetFinancialYears({term: term, page: 1, size: 10}))
    }
  },[])


  useEffect(() =>{
    loadFinancialYears()
  },[dispatch])

  useEffect(() => {
    if (financialYears && financialYears.page) {
      setIsLoading(false)
    }
  },[financialYears])

  useEffect(() => {
    if (createFinancialYearSuccess && !error) {
      setCurentFinancialYear(null)
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      loadFinancialYears()
    }
  },[createFinancialYearSuccess, error, financialYear])

  useEffect(() => {
    if (closeFinancialYearSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      loadFinancialYears()
    }
  },[closeFinancialYearSuccess, error, financialYear])

  useEffect(() => {
    if (openFinancialYearSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      loadFinancialYears()
    }
  },[openFinancialYearSuccess, error, financialYear])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (currentFinancialYear && currentFinancialYear.id) || '',
      yearname: (currentFinancialYear && currentFinancialYear.yearName) || '',
    },
    validationSchema: Yup.object({
      yearname: Yup.string().required(props.t("you_need_provide_year_name")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateForm = {
          id: currentFinancialYear && currentFinancialYear.id,
          yearname: values.yearName,
        }
        const id = currentFinancialYear.id

        dispatch(onUpdateProductDiscount({ data: updateForm, id: id }))
      } else {
        const newForm = {
          yearName: values.yearname,
        }
        dispatch(onCreateFinancialYear(newForm))
      }
    },
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  function handleCloseFinancialYear(year) {
    if (year) {
      dispatch(onCloseFinancialYear(year.id))
      loadFinancialYears()
    }
  }

  function handleOpenFinancialYear(year) {
    if (year) {
      dispatch(onOpenFinancialYear(year.id))
      loadFinancialYears()
    }
  }


  const columns = useMemo(
    () => [
      {
        header: props.t("s_no"),
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {financialYears.page == 1 ? (cellProps.row.index + 1) : (((financialYears.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: props.t("yearname"),
        accessorKey: "yearName",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("opened"),
        accessorKey: "opened",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {/* {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_BEDS")) ? */}
                    {cellProps.row.original.opened ?
                      <li className="list-inline-item" title={props.t("close_year")}>
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => { const yearData = cellProps.row.original; handleCloseFinancialYear(yearData); }}
                        >
                          <i class='fas fa-door-closed'></i>
                        </Link>
                      </li>
                      :
                      <li className="list-inline-item edit" title={props.t("open_year")}>
                        <Link
                          to="#"
                          className="text-success d-inline-block edit-item-btn"
                          onClick={() => { const yearData = cellProps.row.original; handleOpenFinancialYear(yearData); }}
                        >
                          <i class='fas fa-door-open'></i>
                        </Link>
                      </li>
                    }
                    {/* :<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_BEDS")) ? */}
                    
                    {/* :<></>
                  } */}
                </ul>
            </div>
          );
        },
      },
    ],
    [financialYears]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                          <BreadCrumb title={props.t("financial_year")} pageTitle={props.t("settings")} />
                          </div>
                          <div className="col-sm-auto new-mode-btn">
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("")) ?
                              <SinpleActionButton title={props.t("create_financial_year")} img="../assets/images/user.png" action={handleOpen} />
                              :<></>
                            }
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((financialYears && financialYears.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ financialYears && financialYears.size }
                                    tablePage={ financialYears && financialYears.page }
                                    tableTotal= { financialYears && financialYears.total_items }
                                    tableTotalPage= { financialYears && financialYears.total_page }
                                    onNextPage= {loadFinancialYears}
                                    onSearch= {searchYear}
                                    SearchPlaceholder={props.t("search_financial_year")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_product_discount") : props.t("create_product_discount")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("yearname")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="yearname"
                                      id="yearname-field"
                                      className="form-control"
                                      autoComplete="off"
                                      placeholder={props.t("yearname")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.yearname || ""}
                                      invalid={
                                        validation.touched.yearname && validation.errors.yearname ? true : false
                                      }
                                    />
                                    {validation.touched.yearname && validation.errors.yearname ? (
                                      <FormFeedback type="invalid">{validation.errors.yearname}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>
                      </Modal>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(FinancialYear))