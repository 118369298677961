import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link, useNavigate, useParams } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
    createRequisition as onCreateRequisition,
    getSubClassAccount as onGetSubClassAccount,
    getPaymentMode as onGetPaymentMode,
    getRequisition as onGetRequisition,
    updateRequisition as onUpdateRequisition,
} from "../../../slice/thunks"

const CashCreateRequisition = (props) => {
    const {id, action} = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        requisitions: setting.requisitions,
        requisition: setting.requisition,
        subClassAccount: setting.subClassAccount,
        paymentModes: setting.paymentModes,
        requisitionStatus: setting.requisitionStatus,
        createRequisitionSuccess: setting.createRequisitionSuccess,
        updateRequisitionSuccess: setting.updateRequisitionSuccess,
        error: setting.error
      })
    )
    const {
        requisitions,
        requisition,
        paymentModes,
        subClassAccount,
        requisitionStatus,
        createRequisitionSuccess,
        updateRequisitionSuccess,
        error
    } = useSelector(SettingsProperties)
    const [classAccountOption, setClassAccountOption] = useState(null)
    const [paymentModeOption, setPaymentModeOption] = useState(null)
    const [selectedClassAccount, setSelectedClassAccount] = useState(null)
    const [selectedPaymentMode, setSelectedPaymentMode] = useState(null)
    const [currentRequisition, setCurrentRequisition] = useState(null)
    const [netAmount, setNetAmount] = useState(0)
    const [ait, setAit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [isEdit, setIsEdit] = useState(false)


    const loadRequesition = useCallback(() => {
        dispatch(onGetRequisition(id))
    })

    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    })

    const loadClassAccount = useCallback(() => {
        dispatch(onGetSubClassAccount())
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            id: (currentRequisition && currentRequisition.id) || '',
            classAccountId: (currentRequisition && currentRequisition.classAccountId) || '',
            position: (currentRequisition && currentRequisition.position) || '',
            subject: (currentRequisition && currentRequisition.subject) || '',
            amountInWord: (currentRequisition && currentRequisition.amountInWord) || '',
            beneficiary: (currentRequisition && currentRequisition.beneficiary) || '',
            tax: (currentRequisition && currentRequisition.tax) || '',
            amount: (currentRequisition && currentRequisition.amount) || '',
            paymentMode: (currentRequisition && currentRequisition.paymentMode) || '',
        },
    
        validationSchema: Yup.object({
            amount: Yup.string().required(props.t("you_need_provide_amount")),
            beneficiary: Yup.string().required(props.t("you_need_provide_beneficiary")),
            position: Yup.string().required(props.t("you_need_provide_position")),
        }),
    
        onSubmit: (values) => {
    
            if (action && action =='edit') {
                const updateForm = {
                    position: values.position,
                    subject: values.subject,
                    amountInWord: values.amountInWord,
                    classAccountId: selectedClassAccount && selectedClassAccount.value,
                    paymentMode: selectedPaymentMode && selectedPaymentMode.value,
                    beneficiary: values.beneficiary,
                    tax: ait ? 5.5 : 0,
                    amount: values.amount
                }
                const id = currentRequisition.id
                dispatch(onUpdateRequisition({ body: updateForm, id: id }))
    
            } else {
                const newForm = {
                    position: values.position,
                    subject: values.subject,
                    amountInWord: values.amountInWord,
                    classAccountId: selectedClassAccount && selectedClassAccount.value,
                    paymentMode: selectedPaymentMode && selectedPaymentMode.value,
                    beneficiary: values.beneficiary,
                    tax: ait ? 5.5 : 0,
                    amount: values.amount
                }
    
                dispatch(onCreateRequisition(newForm))
            }
        },
    
    })

    useEffect(() => {
        loadPaymentMode()
        loadClassAccount()
    },[dispatch])

    useEffect(() => {
        if (paymentModes && paymentModes.length > 0) {
            const payment_lists = paymentModes.filter(mode => mode.value !== 'PATIENT_CREDIT').map(mode => ({
                label: mode.title,
                value: mode.value
            }));
            setPaymentModeOption(payment_lists);
        }
    }, [paymentModes]);

    useEffect(() =>{
        if (subClassAccount && subClassAccount.length > 0) {
          const subClassAccount_list = subClassAccount.map( subclass => {
            return { label: subclass.name, value: subclass.id }
          })
          setClassAccountOption(subClassAccount_list)
        }
    },[subClassAccount])

    useEffect(() =>{
        if (createRequisitionSuccess && !error) {
          validation.resetForm()
          history("/cashier/requisitions")
        }
    },[createRequisitionSuccess, error, requisition])

    useEffect(() =>{
        if (updateRequisitionSuccess && !error) {
          validation.resetForm()
          history("/cashier/requisitions")
        }
    },[updateRequisitionSuccess, error, requisition])

    useEffect(() =>{
        if (id) {
            console.log(id);
            loadRequesition()
        }
    },[id])

    useEffect(() => {
        if(action == "view") {
            setIsView(true)
        } else if(action == "edit") {
            setIsEdit(true)
        }
    },[action])

    useEffect(() => {
        
        if (id && (requisition && requisition.id)) {
            setCurrentRequisition(requisition)
            setAit(requisition.tax > 0 ? true : false)
            setSelectedClassAccount(requisition.classAccountId && (classAccountOption != null) ? classAccountOption.find((element) => element.value === requisition.classAccountId) : null)
            setSelectedPaymentMode(requisition.paymentMode && (paymentModeOption != null) ? paymentModeOption.find((element) => element.value === requisition.paymentMode) : null )
        }
    },[requisition, paymentModeOption, classAccountOption])

    useEffect(() => {
        if (validation.values.amount > 0 && ait) {
            setNetAmount((parseInt(validation.values.amount)+(validation.values.amount*5.5)/100))
        } else {
            setNetAmount(validation.values.amount)
        }
    },[validation.values.amount, ait])


  return (
    <div className="dashboard">
        {/* <!-- Start requisition Section --> */}
        <div className="budgeting-sec">
            <div className="container pt-4">
                <div className="heading-parent-sec">
                    <div className="heading-child-one-sec">
                        <h6 className="m-0 p-0">{action && action =='edit' ? props.t("edit_requisition") : action && action =='view' ? '' : props.t("create_requisition")}</h6>
                    </div>
                    <div className="heading-child-two-sec">
                    </div>
                </div>
                <div className="card px-2 py-2"
                    style={{ borderRadius: "10px", border: "transparent", boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", height: "500px" }}>
                    <div className="row d-flex justify-content-between">
                        <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <div className="filter1 d-flex align-items-center">
                                <Col xl={6} sm={6}>
                                    <div className="mb-3">
                                    <Label
                                        htmlFor="wards-field"
                                        className="form-label"
                                    >
                                        {props.t("choose_class")}
                                    </Label>
                                    <Select
                                        name='accountType'
                                        id='accountType-field'
                                        value={selectedClassAccount}
                                        isMulti={false}
                                        isClearable={false}
                                        autoComplete="off" 
                                        // className='p-0'
                                        onBlur={validation.handleBlur}
                                        onChange={(e) => {
                                            setSelectedClassAccount(e);
                                        }}
                                        options={classAccountOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isDisabled= { isView ? true : false }
                                    />
                                    </div>
                                </Col>
                            </div>
                            <Row className="px-4 py-4">
                                <Col xl={6} sm={6}>
                                    <label for="">{props.t("amount")}<span className='text-danger'>*</span></label>
                                    <Input 
                                        className="p-3" 
                                        type="number" 
                                        id="amount" 
                                        name="amount"
                                        placeholder=""
                                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                            validation.touched.amount && validation.errors.amount ? true : false
                                        }
                                        disabled= { isView ? true : false }
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col  xl={6} sm={6}>
                                    <label for="">{props.t("in_words")}</label>
                                    <Input 
                                        className="p-3" 
                                        type="text" 
                                        id="amountInWords" 
                                        name="amountInWord"
                                        placeholder=""
                                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amountInWord || ""}
                                        invalid={
                                            validation.touched.amountInWord && validation.errors.amountInWord ? true : false
                                        }
                                        disabled= { isView ? true : false }
                                    />
                                    {validation.touched.amountInWord && validation.errors.amountInWord ? (
                                        <FormFeedback type="invalid">{validation.errors.amountInWord}</FormFeedback>
                                    ) : null}
                                </Col>
                            
                                <Col  xl={6} sm={6}>
                                    <label for="">{props.t("beneficiary")}<span className='text-danger'>*</span></label>
                                    <input 
                                        className="p-3" 
                                        type="text" 
                                        id="to" 
                                        name="beneficiary"
                                        placeholder=""
                                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.beneficiary || ""}
                                        invalid={
                                            validation.touched.beneficiary && validation.errors.beneficiary ? true : false
                                        }
                                        disabled= { isView ? true : false }
                                    />
                                    {validation.touched.beneficiary && validation.errors.beneficiary ? (
                                        <FormFeedback type="invalid">{validation.errors.beneficiary}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col  xl={6} sm={6}>
                                    <label for="">{props.t("position")}</label>
                                    <input 
                                        className="p-3" 
                                        type="text" 
                                        id="position" 
                                        name="position"
                                        placeholder=""
                                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.position || ""}
                                        invalid={
                                            validation.touched.position && validation.errors.position ? true : false
                                        }
                                        disabled= { isView ? true : false }
                                    />
                                    {validation.touched.position && validation.errors.position ? (
                                        <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                    ) : null}
                                </Col>
                
                                <Col  xl={6} sm={6}>
                                    <label for="">{props.t("subject")}</label>
                                    <textarea 
                                        className="p-3" 
                                        type="text" 
                                        id="justification" 
                                        name="subject"
                                        placeholder=""
                                        style={{ border: "1px solid #D0D0D0", width: "-webkit-fill-available", height: "50px", borderRadius: "10px" }}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.subject || ""}
                                        invalid={
                                            validation.touched.subject && validation.errors.subject ? true : false
                                        }
                                        disabled= { isView ? true : false }
                                    />
                                    {validation.touched.subject && validation.errors.subject ? (
                                        <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col xl={6} sm={6}>
                                        <span className="pt-3 mt-4" style={{ fontSize: "18px", color: "rgb(3, 197, 3)", fontWeight: "600", paddingRight: "10px" }} >{props.t("bill_amount")}: </span>
                                        <span style={{ color: "rgb(3, 197, 3)", fontSize: "18px" }}>&nbsp;{validation.values.amount}</span>
                                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                                        <span className="pt-3 mt-4" style={{ fontSize: "18px", color: "rgb(0, 0, 0)", fontWeight: "600", paddingRight: "34px" }} >{props.t("net_amount")}: </span>
                                        <span style={{ color: "rgb(0, 0, 0)", fontSize: "18px" }}>&nbsp;{netAmount}</span>
                                        <br />
                                        <input 
                                            className="form-check-input mx-1" 
                                            type="checkbox" 
                                            checked={ait ? true : false}
                                            id="flexCheckDefault" 
                                            disabled
                                            onClick={() => {
                                                setAit(!ait);
                                            }}
                                        /> 
                                        <span className="pt-3 mt-4" style={{ fontSize: "18px", color: "rgb(197, 3, 3)", fontWeight: "600", paddingRight: "85px" }} >
                                            {props.t("ait")}: 
                                        </span>
                                        <span style={{ color: "orange", fontSize: "18px" }}>&nbsp;5.5 %</span>
                                </Col>
                            </Row>

                            <div className="row px-4 py-3 justify-content-end" style={{ gap: "30px" }}>
                                <div className="col-lg-6 text-end" style={{ gap: "30px" }}>
                                    { isView ?
                                        <></>
                                        :
                                        <button 
                                            type="submit"
                                            className="btn btn-success"
                                        >
                                            {props.t("forward_for_approval") }
                                        </button>
                                    }
                                    <Link 
                                        type="button"
                                        className="btn btn-outline ms-2"
                                        to="/cashier/requisitions"
                                        style={{ border: "1px solid #00000080", padding: "5px 22px" }}
                                    >
                                        {props.t("cancel")}
                                    </Link>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            {/* <!-- Dashboard-cards End --> */}
        </div>
        {/* <!-- banking End --> */}
    </div>
  )
}

export default withRouter(withTranslation()(CashCreateRequisition))
