import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../Common/withRouter'
import { Modal } from 'reactstrap'

const MatrixDispensary = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleDispensary} centered
        className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: "1140px" }}
      >
        {/* <span classNameName="invoice-modal close" onClick={() => props.toggleLab()}>&times;</span> */}
        <div
          style={{ fontFamily: "Courier, monospace", backgroundColor: "#fefefe", padding: "10px", width: "max-content", height: "max-content" }}
        >
            <div className="container">
                <div className="header">
                    <div className="reg-no" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "300px", margin: "0" }}>
                        <p style={{ fontFamily: "Courier, monospace", fontSize: "11px" }}>REG. No: ST.VERON52451C</p>
                        <p style={{ textAlign: "center", padding: "0", fontSize: "22px", fontFamily: "Courier, monospace", margin: "0" }}>Tax Invoice</p>
                    </div>
                    <h4 style={{ textAlign: "center", fontFamily: "Courier, monospace", fontWeight: "600" }}>ST. VERONICA MEDICAL CENTER</h4>
                    <h6 style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>BOKOKO-BUEA</h6>
                    <div style={{ display: "flex", justifyContent: "center", gap: "15px", margin: "0", flexDirection: "row" }}>
                        <p style={{ fontFamily: "Courier, monospace" }}>Email: St.veronicamedicalcenter@yahoo.com</p>
                        <p style={{ fontFamily: "Courier, monospace" }}>Contact No: +251-9876543</p>
                    </div>
                </div>

                <div className="section" style={{ marginBottom: "0" }}>
                    <div className="m-0">
                        <table className="table table-borderless m-0">
                            <tbody style={{ borderTop: "1px solid black" }}>
  
                                <tr>
                                    <th style={{ fontFamily: "Courier, monospace" }}>BILL NO.</th>
                                    <td style={{ fontFamily: "Courier, monospace" }}> <b>658</b></td>
                                    <th style={{ fontFamily: "Courier, monospace" }}>DATE</th>
                                    <td style={{ fontFamily: "Courier, monospace" }}>   <b> 09/02/2024</b></td>
                                  
                                    
                                </tr>
                                <tr>
                                  <th style={{ fontFamily: "Courier, monospace" }}>Name</th>
                                  <td style={{ fontFamily: "Courier, monospace" }}> <b> ALex Thomas</b></td>
                                    
                                    <th style={{ fontFamily: "Courier, monospace" }}>Pr. By</th>
                                    <td style={{ fontFamily: "Courier, monospace" }}>Dr. S. WATSON</td>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: "Courier, monospace" }}>ADDRESS</th>
                                    <td style={{ fontFamily: "Courier, monospace" }}>BIPINDI, CAMEROON, AFRICA</td>
                                    <th></th>
                                    <td></td>
                                  
  
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="section two" style={{ margin: "0" }}>
                    <div className="m-0">
                        <table className="table table-borderless mb-4">
                            <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                <th style={{ fontFamily: "Courier, monospace" }}>S.NO.</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>QTY</th>
                                <th style={{ fontFamily: "Courier, monospace" }} >PACK</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>DESCRIPTION</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>HSN CODE</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>BATCH</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>EXPIRY DATE</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>RATE</th>
                                <th style={{ fontFamily: "Courier, monospace" }}>AMOUNT</th>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: "Courier, monospace" }}>1</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10 TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>MAHACEF 200 MG TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>30043200</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>COAW061</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>06/25</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>103.93</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>103.93</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: "Courier, monospace" }}>2</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>1</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>1*10ML</td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>NASIVION CLASSNameIC R10</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>2106</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>3328C84501</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10/26</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>109.01</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>109.01</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: "Courier, monospace" }}>3</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>3</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10 TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>MONTICOPE TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>30049069</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>C45W025</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>11/25</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>29.97</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>29.97</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: "Courier, monospace" }}>4</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>1</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>100ML</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>KHOKHI Z SYP</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>00</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>ZUKCUK-KHO</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>02/25</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>89.00</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>89.00</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: "Courier, monospace" }}>5</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>10 TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>CALPOL TAB</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>30049069</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>EA23173</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>11/25</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>9.97</td>
                                <td style={{ fontFamily: "Courier, monospace" }}>9.97</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="totals" style={{ margin: "0" }}>
                    <div className="m-0">
                        <table className="table table-borderless m-0">
                            <tr style={{ borderTop: "1px solid black" }}>
                                <td style={{ fontFamily: "Courier, monospace" }}>INCL TAXES DETAILS: </td>
                                <td style={{ fontFamily: "Courier, monospace", width: "60%" }}>203.20x12%= 24.38,<br />GST 0% AMT: 80.10</td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>VAT: <b>$ 12.19</b></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>Gross <br />Less Diss</td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 341.88 <br />: $ 33.87</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{ visibility: "hidden" }}></td>
                                <td></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="section" style={{ marginTop: "0" }}>
                    <div className="m-0">
                        <table className="table table-borderless m-0">
                            <tr style={{ marginTop: "20px" }}>
                                <td style={{ fontFamily: "Courier, monospace" }}>E.& O.E. &nbsp; MATHEW &nbsp;12:26pm </td>
                                <td style={{ textAlign: "left", fontFamily: "Courier, monospace" }} ></td>
                                <td style={{ visibility: "hidden" }}></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}><b> Total Amnt.</b></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.01</td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid black" }}>
                                <td style={{ whiteSpace:"nowrap", fontFamily: "Courier, monospace" }}>USD: Three Hundred and Eight Only </td>
                                <td></td>
                                <td></td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>Net Amt. (R/O)</td>
                                <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.00</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="footer">
                    <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center", margin: "0", padding: "0", fontFamily: "Courier, monospace" }}>
                      100% Genuine Medicines
                    </p>
                    <p style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>
                      Book Your Free Home Blood Sample Collection On: 
                      <span style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>+251-9876543</span>
                    </p>
                </div>
            </div>
        </div>

    </Modal>
  )
} 

export default withRouter(withTranslation()(MatrixDispensary))
