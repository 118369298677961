import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const CompanySettings = () => {
  return (
    <div>CompanySettings</div>
  )
}

export default withRouter(withTranslation()(CompanySettings))
