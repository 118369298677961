import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'
import {
  getLabRecords as onGetLabRecords,
} from "../../slice/thunks"
import { createSelector } from "reselect"
import moment from 'moment'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from '../../components/Common/GlobalLoader'
import TableContainerWarehouse from '../../components/Common/TableContainerWarehouse'


const LabRecord = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state.Laboratory;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        labRecords: setting.labRecords,
        error: setting.error
      })
  )
  const { labRecords, error } = useSelector(SettingsProperties)
  const permission = JSON.parse(localStorage.getItem("perm"));
  const [isLoading, setIsLoading] = useState(true)
  
  const loadLabRecords = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetLabRecords({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetLabRecords())
    }
  })

  const searchLabRecord = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetLabRecords({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetLabRecords({term: term, page: 1, size: 10}))
    }
  })

  useEffect(() => {
    loadLabRecords()
  },[dispatch])

  useEffect(() => {
    
    if (labRecords && labRecords.page > 0) {
        setIsLoading(false)
    }
  },[labRecords])


  const columns = useMemo(
    () => [

      {
        header: props.t("no"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {labRecords.page == 1 ? (cellProps.row.index + 1) : (((labRecords.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: props.t("patient_ref"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.patient.patientRef}</span>
        }
      },
      {
        header: props.t("patient_name"),
        accessorKey: "phone",
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName}</span>
        }
      },
      // {
      //   header: props.t("doctor_consultant"),
      //   accessorKey: "productType",
      //   style: "type",
      //   enableColumnFilter: false,
      //   cell: (cell) => {
      //     return <span>{cell.row.original.productType}</span>
      //   }
      // },
      {
        header: props.t("status"),
        style: "sale",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.labRecordStatus}</span>
        }
      },
      {
        header: props.t("date"),
        style: "sale",
        enableColumnFilter: false,
        cell: (cell) => {
          return <div className='d-flex align-items-center'>
                    <span>
                      {moment(cell.row.original.createdAt).format('DD / MM/ YYYY')}
                    </span>
            </div>
        }
      },
      {
        header: props.t("action"),
        style: "action",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center">
                {/* <ul className="list-inline hstack mb-0"> */}
                  {/* <li className="list-inline-item edit" title="Edit">
                  </li> */}
                  {permission.includes('ADD_LAB_TESTS') || permission.includes('UPDATE_LAB_TESTS') || permission.includes('VIEW_LAB_TESTS') ?
                    <td className="action-btns">
                        <Link
                          to={`/lab/lab-record/detail/${cellProps.row.original.id}`}
                          className="text-primary d-inline-block edit-item-btn"
                        >
                            <button className="view-btn">
                                <i className="fa-solid fa-eye"></i>
                            </button>
                        </Link>
                    </td>
                    :<></>
                  }
            </div>
          );
        },
      },
    ],
    [labRecords]
  );

  return (

    <React.Fragment>
      {isLoading ?
          <GlobalLoader />
        :
          <>
            <div className="px-4" style={{ paddingBottom: "80px" }}>
                <div className="lab-record">
                    <div className="row align-items-center justify-content-between py-4">
                        <div className="col-lg-4">
                            <h6>{props.t("lab_records")}</h6>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-2">
                        </div>
                    </div>
                    <div className="lab-record-table px-4">
                        <div className="container-fluide">
                            <div className="row">
                              <TableContainerWarehouse
                                    columns={columns}
                                    data={((labRecords && labRecords.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="card table-card table-warehouse table-responsive"
                                    tableClass="table"
                                    theadClass="thead-sec table-light"
                                    tdClass="td"
                                    tableSize={ labRecords && labRecords.size }
                                    tablePage={ labRecords && labRecords.page }
                                    tableTotal= { labRecords && labRecords.total_items }
                                    tableTotalPage= { labRecords && labRecords.total_page }
                                    onNextPage= {loadLabRecords}
                                    onSearch= {searchLabRecord}
                                    SearchPlaceholder={props.t("search_lab_record")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(LabRecord))
