import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
    getRequisitions as onGetRequisitions,
    getRequisitionStatus as onGetRequisitionStatus,
    getPaymentMode as onGetPaymentMode,
    createRequisition as onCreateRequisition,
    updateRequisition as onUpdateRequisition,
    approveRequisition as onApproveRequisition,
    payRequisition as onPayRequisition,
    rejectRequisition as onRejectRequisition,
    cancelRequisition as onCancelRequisition,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"
import ApproveModal from "../../../components/Global/ApproveModal"
import CancelModal from "../../../components/Global/CancelModal"

const CashRequisition = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        requisitions: setting.requisitions,
        requisition: setting.requisition,
        paymentModes: setting.paymentModes,
        requisitionStatus: setting.requisitionStatus,
        createRequisitionSuccess: setting.createRequisitionSuccess,
        approveRequisitionSuccess: setting.approveRequisitionSuccess,
        rejectRequisitionSuccess: setting.rejectRequisitionSuccess,
        cancelRequisitionSuccess: setting.cancelRequisitionSuccess,
        payRequisitionSuccess: setting.payRequisitionSuccess,
        updateRequisitionSuccess: setting.updateRequisitionSuccess,
        error: setting.error
      })
    )
    const {
        requisitions,
        requisition,
        requisitionStatus,
        paymentModes,
        payRequisitionSuccess,
        createRequisitionSuccess,
        approveRequisitionSuccess,
        rejectRequisitionSuccess,
        cancelRequisitionSuccess,
        updateRequisitionSuccess,
        error
    } = useSelector(SettingsProperties)

    const [isLoading, setIsLoading] = useState(true)
    const [currentRequisition, setCurrentRequisition] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [statusOption, setStatusOption] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
    const [modal, setModal] = useState(false)


    const loadRequesitionStatus = useCallback(() => {
        dispatch(onGetRequisitionStatus())
    })

    const loadRequesitions = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetRequisitions({status: 'Approved', page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetRequisitions({ status: 'Approved' }))
        }
    })

    const searchRequesitions = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetRequisitions({ term: term, status: 'Approved', page: pageNumber, size: pageSize }))
        } else {
          dispatch(onGetRequisitions({ term: term,status: 'Approved', page: 1, size: 10 }))
        }
    })

    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    },[])

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          setCurrentRequisition(null);
        } else {
          setModal(true);
        }
    }, [modal])


    useEffect(() => {
        loadRequesitionStatus()
        loadRequesitions()
        loadPaymentMode()
    },[dispatch])

    useEffect(() => {
        if (paymentModes && paymentModes.length > 0) {
            const payment_lists = paymentModes.filter((mode) => mode.value != 'PATIENT_CREDIT').map( mode => {
              return { label: mode.title, value: mode.value }
            })
            setPaymentOption(payment_lists)
        }
    },[paymentModes])

    useEffect(() => {
        if (requisitions && requisitions.page >0 ) {
            setIsLoading(false)
        }
    },[requisitions])

    useEffect(() => {
        const status_lists = [
            { label: 'All', value: ''}
        ]
        if (requisitionStatus && requisitionStatus.length > 0) {
            const data_lists = requisitionStatus.map(row => ({
                label: row.title,
                value: row.value
            }));
            setStatusOption([...status_lists, ...data_lists]);
        }
    },[requisitionStatus])

    useEffect(() => {
        if (payRequisitionSuccess && !error) {
            setSelectedPaymentM(null)
            loadRequesitions()
            toggle()
        }
    },[payRequisitionSuccess])

    useEffect(() => {
        if (approveRequisitionSuccess && !error) {
            setApproveModal(false)
            loadRequesitions()
        }
    },[approveRequisitionSuccess])

    useEffect(() => {
        if (rejectRequisitionSuccess && !error) {
            setDeleteModal(false)
            loadRequesitions()
        }
    },[rejectRequisitionSuccess])

    useEffect(() => {
        if (cancelRequisitionSuccess && !error) {
            setCancelModal(false)
            loadRequesitions()
        }
    },[cancelRequisitionSuccess])


    function handleDeleteRequisition() {
        if (currentRequisition) {
          dispatch(onRejectRequisition(currentRequisition.id))
          loadRequesitions()
          setDeleteModal(false)
        }
    }

    function handleApproveRequisition() {
        if (currentRequisition) {
          dispatch(onApproveRequisition(currentRequisition.id))
          loadRequesitions()
          setDeleteModal(false)
        }
    }

    function handleCancelRequisition() {
        if (currentRequisition) {
          dispatch(onCancelRequisition(currentRequisition.id))
          loadRequesitions()
          setCancelModal(false)
        }
    }

    const onClickDelete = (req) => {
        setCurrentRequisition(req);
        setDeleteModal(true);
    }

    const onClickCancel = (req) => {
        setCurrentRequisition(req);
        setCancelModal(true);
    }

    const onClickPaid = (req) => {
        setCurrentRequisition(req);
        toggle(true);
    }

    const savePayment = () => {

        let config = {
            id: (currentRequisition && currentRequisition.id),
            mode: (selectedPaymentM && selectedPaymentM.value)
        }
        dispatch(onPayRequisition(config))
    }


    const columns = useMemo(
        () => [
            {
                header: props.t("no"),
                style: "nbLines",
                enableColumnFilter: false,
                cell: (cellProps) => {
                  return <span>
                      {requisitions.page == 1 ? (cellProps.row.index + 1) : (((requisitions.page-1)*10)+cellProps.row.index + 1) }
                  </span>;
                },
            },
            {
                header: props.t("ref_no"),
                accessorKey: "requisitionRef",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("date"),
                accessorKey: "createdAt",
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>
                        {moment(cell.row.original.createAt).format('DD / MM/ YYYY HH:mm')}
                    </span>;
                },
            },
            {
                header: props.t("subject"),
                accessorKey: "subject",
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                    const truncateText = (text, maxLength) => {
                        if (text.length > maxLength) {
                            return text.substring(0, maxLength) + '...';
                        }
                        return text;
                    };

                    return <span>{truncateText(cell.row.original.subject, 20)}</span>;
                }
            },
            {
                header: props.t("beneficiary"),
                accessorKey: "beneficiary",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("actual_amount"),
                accessorKey: "amount",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("approved_by"),
                accessorKey: "approvedBy",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("action"),
                style: "name",
                cell: (cellProps) => {
                return (
                    <div className="d-flex justify-content-center">
                       <ul className="list-inline hstack mb-0">
                            <li className="list-inline-item edit" title={props.t("view")}>
                                <>
                                    <Link
                                        to={`/cashier/requisitions/create/view/${cellProps.row.original.id}`}
                                        className="btn save-del btn-light " style={{fontSize: "11px", border: "1px solid #808080" }}
                                    >
                                        {props.t("view") }
                                    </Link>
                                </>
                            </li>

                            {cellProps.row.original.status != 'Paid' ?
                                <>
                                    <li className="list-inline-item edit" title={props.t("cancel")}>
                                        <>
                                            <Link
                                                to="#"
                                                className="btn save-del btn-danger" style={{ fontSize: "11px" }}
                                                onClick={() => { const rowData = cellProps.row.original; onClickCancel(rowData); }}
                                            >
                                                {props.t("cancel") }
                                            </Link>
                                        </>
                                    </li>
                                    <li className="list-inline-item edit" title={props.t("paid")}>
                                        <>
                                            <Link
                                                to="#"
                                                className="btn save-del btn-warning " style={{ fontSize: "11px" }}
                                                onClick={() => { const rowData = cellProps.row.original; onClickPaid(rowData); }}
                                            >
                                                {props.t("paid") }
                                            </Link>
                                        </>
                                    </li>
                                </>
                                :<></>
                            }
                        </ul>
                    </div>
                );
                },
            },
        ],
        [requisitions ]
    );

    return (
        <React.Fragment>
            {isLoading ?
                <GlobalLoader />
                :
                <>
                    <DeleteModal show={deleteModal} onDeleteClick={handleDeleteRequisition} onCloseClick={() =>setDeleteModal(false)} />
                    <ApproveModal show={approveModal} onConfirmClick={handleApproveRequisition} onCloseClick={() =>setApproveModal(false)} />
                    <CancelModal show={cancelModal} onCancelClick={handleCancelRequisition} onCloseClick={() =>setCancelModal(false)} />

                    <div className="patients-list-content">
                        <div className="patients-list px-2 pb-5 ">
                            <div className="container-fluid ">
                                <div className="heading-parent-sec ">
                                    <div className="heading-child-one-sec pt-4">
                                        <h6>{props.t("list_of_expenditors")}</h6>
                                    </div>
                                    <div className="heading-child-two-sec pt-4">

                                    </div>
                                </div>

                                <div className="card border-0 row p-2">
                                    {/* <div className="see-doctor-patient "> */}
                                        <div className="row">
                                                <TableContainerWarehouse
                                                columns={columns}
                                                data={((requisitions && requisitions.items) || [])}
                                                isGlobalFilter={true}
                                                customPageSize={10}
                                                divClass="card table-card table-warehouse table-responsive"
                                                tableClass="table"
                                                theadClass="thead-sec table-light"
                                                tdClass="td"
                                                tableSize={ requisitions && requisitions.size }
                                                tablePage={ requisitions && requisitions.page }
                                                tableTotal= { requisitions && requisitions.total_items }
                                                tableTotalPage= { requisitions && requisitions.total_page }
                                                onNextPage= {loadRequesitions}
                                                onSearch= {searchRequesitions}
                                                SearchPlaceholder={props.t("search_expenditor")}
                                                />
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                            { props.t("paid_expenditor") }
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <div className="payment-mode">
                                        <Label htmlFor="payment-field" className="form-label">
                                            {props.t("payment_mode")}
                                            <span className='text-danger'>*</span>
                                        </Label>
                                        <br/>
                                        <Select
                                            name='payment'
                                            id='payment-field'
                                            // styles={customStyles}
                                            value={selectedPaymentM}
                                            isMulti={false}
                                            isClearable={false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setSelectedPaymentM(e);
                                            }}
                                            options={paymentOption}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            isDisabled= {currentRequisition && currentRequisition.amount > 0 ? false : true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='p-3'>
                            <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="button" className="btn btn-secondary" onClick={() => { toggle();  setSelectedPaymentM(null) }}> {props.t("close")} </button>
                                        <button type="submit" className="btn btn-success" onClick={() => { savePayment()}}> {props.t("paid")} </button>
                                    </div>
                                </div>
                            </Row>
                        </div>

                    </Modal>
                </>

            }
        </React.Fragment>
      )
    }

    export default withRouter(withTranslation()(CashRequisition))