// login
export const POST_LOGIN = "auth/login"
export const REFRESH_TOKEN = "auth/refreshToken"
export const VALIDATE_TOKEN = "auth/validateToken"
export const POST_LOGOUT = "auth/logout"
export const GET_USER = "sessions/user"
// roles
export const GET_ROLES = "/roles"
export const GET_ALL_ROLES = "/roles/all"
export const POST_ROLE = "/roles"
export const GET_ROLE = "/roles"
export const UPDATE_ROLE = "/roles"
export const DELETE_ROLE = "/roles"
export const SEARCH_ROLE = "/roles/search"
// Permissions
export const GET_PERMISSIONS = "/permissions"
export const GET_PERMISSION = "/permission"
export const UPDATE_PERMISSION = "/permissions"
// Users
export const GET_USERS = "/users"
export const ADD_USER = "/users"
export const LOAD_USER = "/users"
export const UPDATE_USER = "/users"
export const DELETE_USER = "/users"
export const SEARCH_USER = "/users/search"
// Wards
export const GET_WARDS = "/wards"
export const LOAD_WARDS = "/wards/all"
export const ADD_WARD = "/wards"
export const UPDATE_WARD = "/wards"
export const DELETE_WARD = "/wards"
export const SEARCH_WARD = "/wards/search"
// Beds
export const GET_BEDS = "/beds"
export const ADD_BED = "/beds"
export const UPDATE_BED = "/beds"
export const DELETE_BED = "/beds"
export const SEARCH_BED = "/beds/search"
export const GET_BEDS_BY_WARD = "/wards"
export const ASSIGN_BED_PATIENT = "/patient-records"
// Patients
export const GET_PATIENTS = "/patients"
export const GET_PATIENTS_TYPE = "/patient-types"
export const GET_PATIENT_RECORD = "/patients"
export const GET_RECORD_BY_PATIENT = "/patient-records"
export const GET_PATIENT = "/patients"
export const ADD_PATIENT = "/patients"
export const UPDATE_PATIENT = "/patients"
export const DELETE_PATIENT = "/patients"
export const ADMIT_PATIENT = "/patients"
export const SEARCH_PATIENT = "/patients/search"
export const MARK_PATIENT_CONSULTED = "/patient-records/"
// Doctors
export const GET_DOCTORS = "/doctors"
export const GET_ALL_DOCTORS = ""
export const GET_DOCTOR_TITLE = "/doctors/titles"
export const GET_DOCTOR_INFO = "/doctors"
export const UPDATE_DOCTOR = "/doctors"
export const DELETE_DOCTOR = "/doctors"
export const SEARCH_DOCTOR = "/doctors/search"
export const MARK_PATIENT_CONSULTED_DOCTOR = "/doctors/consultation/"
//Vitals
export const ADD_PATIENT_VITALS = "/patient-vitals"
export const LOAD_PATIENT_VITALS = "/patient-vitals"
export const LOAD_PATIENT_VITALS_PAGE = "/patients"
export const UPDATE_PATIENT_VITALS = "/patient-vitals"
export const GET_PATIENT_VITALS = "/patients"
//Patient vitals records
export const LOAD_PATIENT_VITALS_RECORD = "/patient-records"
// Record
export const ASSIGN_DOCTOR = "/patient-records"
export const CHANGE_DOCTOR_TO_SEE = "/patient-records"
export const GET_DOCTOR_TO_SEE = "/patient-records"
export const GET_CONSULTATION_LIST = "/patient-records/consultation-list"
export const GET_HOSPITALISATION_LIST = "/patient-records/hospitalisation-list"
export const LEAVE_BED = "/patient-records"
export const RELEASE_PATIENT = "/patient-records"
// Categories
export const GET_CATEGORIES = "/categories"
export const GET_CATEGORY = "/categories"
export const GET_CATEGORIES_PARENT = "/categories/all"
export const CREATE_CATEGORY = "/categories"
export const UPDATE_CATEGORY = "/categories"
export const DELETE_CATEGORY = "/categories"
export const SEARCH_CATEGORY = "/categories"
// Doctor Panel
export const GET_CONSULTATION_LIST_FOR_DOCTOR = "/doctors/consultation/list"
// Doctor appointments
export const GET_DOCTOR_APPOINTMENTS = "/doctors/appointments/list"
export const REGISTER_APPOINTMENTS =  "/doctors/appointments/add"
export const GET_APPOINTMENTS_DETAIL = "/doctors/appointments"
export const UPDATE_APPOINTMENT = "/doctors/appointments"
export const DELETE_APPOINTMENT = "/doctors/appointments"
export const MARK_APPOINTMENT_ATTENDED = "doctors/appointments"
export const GET_PATIENTS_LAB_RECORDS = "/doctors/patients/lab-records"

// Appointments
export const GET_OPD_APPOINTMENTS = "/appointments"
export const CREATE_OPD_APPOINTMENT = "/appointments"
export const GET_OPD_APPOINTMENT = "/appointments"
export const UPDATE_OPD_APPOINTMENT = "/appointments"
export const DELETE_OPD_APPOINTMENT = "/appointments"
export const MARK_OPD_APPOINTMENT_ATTENDED = "/appointments"

//Class account
export const GET_CLASSES_ACCOUNTS = "/class-accounts"
export const GET_PARENT_CLASSES_ACCOUNT = "/class-accounts/parents"
export const GET_TYPE_CLASSES_ACCOUNT = "/class-accounts/all"
export const CREATE_CLASS_ACCOUNT = "/class-accounts"
export const GET_CLASS_ACCOUNT = "/class-accounts"
export const UPDATE_CLASS_ACCOUNT = "/class-accounts"
export const DELETE_CLASS_ACCOUNT = "/class-accounts"
export const SEARCH_CLASS_ACCOUNT = "/class-accounts"
export const GET_SUB_CLASS_ACCOUNT = "/class-accounts/sub-accounts"
export const GET_PARENT_SUB_CLASS_ACCOUNT = "/class-accounts"

// Suppliers
export const GET_SUPPLIERS = "/suppliers"
export const GET_ALL_SUPPLIERS = "/suppliers/all"
export const GET_SUPPLIER = "/suppliers"
export const CREATE_SUPPLIER = "/suppliers"
export const UPDATE_SUPPLIER = "/suppliers"
export const DELETE_SUPPLIER = "/suppliers"
export const SEARCH_SUPPLIER = "/suppliers/search"

// Products
export const GET_ACTIVES_PRODUCTS = "/products"
export const GET_PRODUCTS_AND_SEARCH = "/products/search"
export const GET_PRODUCTS_AND_SEARCH_STOCK = "/stock/search-products"
export const GET_PRODUCT = "/products"
export const SEARCH_PRODUCT = "/products/search"
export const CREATE_PRODUCT = "/products"
export const UPDATE_PRODUCT = "/products"
export const DELETE_PRODUCT = "/products"

//Lab remark
export const GET_LAB_TEST_REMARKS = "/lab-tests-remarks"
export const GET_LAB_TEST_REMARK = "/lab-tests-remarks"
export const CREATE_LAB_TEST_REMARK = "/lab-tests-remarks"
export const UPDATE_LAB_TEST_REMARK = "/lab-tests-remarks"
export const DELETE_LAB_TEST_REMARK = "/lab-tests-remarks"

//Lab samples
export const GET_LAB_SAMPLES = "/lab-tests-samples"
export const GET_ALL_LAB_SAMPLES = "/lab-tests-samples/all"
export const GET_LAB_TEST_SAMPLE = "/lab-tests-samples"
export const CREATE_LAB_SAMPLE = "/lab-tests-samples"
export const UPDATE_LAB_SAMPLE = "/lab-tests-samples"
export const DELETE_LAB_SAMPLE = "/lab-tests-samples"


// Supplier-Order
export const GET_SUPPLIERS_ORDER = "/supplier-orders"
export const GET_SUPPLIER_ORDER = "/supplier-orders"
export const ADD_SUPPLIER_ORDER = "/supplier-orders"
export const UPDATE_SUPPLIER_ORDER = "/supplier-orders"
export const CONFIRM_SUPPLIER_ORDER = "/supplier-orders"
export const DELETE_SUPPLIER_ORDER_ITEM = "supplier-order-items"

// Warehouse-Stock
export const WAREHOUSE_ORDER_STOCK = "/stock"

// Pharmacy
export const PHARMACY_ORDER_STOCK = "/pharmacy-stock"
export const GET_PHARMACY_PRODUCTS_AND_SEARCH = "/pharmacy-stock/search-products"

// Product discount
export const GET_PRODUCTS_DISCOUNT = "/product-discounts"
export const GET_PRODUCT_DISCOUNT = "/product-discounts"
export const CREATE_PRODUCT_DISCOUNT = "/product-discounts"
export const UPDATE_PRODUCT_DISCOUNT = "/product-discounts"
export const DELETE_PRODUCT_DISCOUNT = "/product-discounts"

// Transfert order
export const GET_TRANSFERT_ORDERS = "/transfer-orders"
export const GET_TRANSFERT_ORDER = "/transfer-orders"
export const CREATE_TRANSFERT_ORDER = "/transfer-orders"
export const UPDATE_TRANSFERT_ORDER = "/transfer-orders"
export const CONFIRM_TRANSFERT_ORDER = "/transfer-orders"
export const DELETE_TRANSFERT_ORDER_ITEM = "/transfer-orders"

// Associated service
export const GET_ASSOCIATED_SERVICES = "/referential-values/associated-services"
export const GET_SALES_INVOICES = "/invoices"
export const GET_PAYMENT_MODE = "/referential-values/payment-modes"
// Lab record
export const GET_LAB_RECORDS = "/lab-records"
export const GET_LAB_RECORD = "/lab-records"
export const CREATE_LAB_TESTS = "/lab-records"
export const GET_ALL_LAB_REMARKS = "/lab-tests-remarks/all"

export const SEARCH_PATIENT_FOR_SALE = "/patients/search-patients"


///////////////
//  ---------- Sales


// Sales
export const GET_SALES = "/sales"
export const GET_SALE = "/sales"
export const CREATE_SALE = "/sales"

// Deposits
export const GET_DEPOSITS = "/patient-accounts/deposits/all"
// Payment
export const GET_PAYMENTS = "/payments"
export const GET_PAYMENT = "/payments"
export const CONFIRM_PAYMENT = "/payments"
export const MAKE_PAYMENT = "/payments/make-payment"
// Sales on hold
export const GET_SALES_ON_HOLD = "/sales"

// Invoice
export const GET_INVOICES = "/invoices"
export const GET_DAILY_BILLS = "/invoices/report/daily"
export const GET_MY_REPORT = "/invoices/report/my-invoices"
export const GET_INVOICE = "/invoices"
export const GET_PATIENT_INVOICES = "/invoices/patient/"
export const GET_INVOICE_STATUS = "/referential-values/invoice-statuses"


// Financial years
export const GET_FINANCIAL_YEARS = "/financial-years"
export const GET_FINANCIAL_YEAR = "/financial-years"
export const CREATE_FINANCIAL_YEAR = "/financial-years"
export const CLOSE_FINANCIAL_YEAR = "/financial-years"
export const OPEN_FINANCIAL_YEAR = "/financial-years"
// Patient Account
export const GET_PATIENTS_ACCOUNTS = "/patient-accounts"
export const GET_PATIENT_ACCOUNT = "/patient-accounts"
export const CREATE_PATIENT_ACCOUNT = "/patient-accounts"
export const MAKE_DEPOSIT = "/patient-accounts"
export const GET_PATIENT_ACCOUNT_HISTORY = "/patient-accounts"

// CashDesk
export const CREATE_CASH_DESK = "/cash-desks"
export const OPEN_CASH_DESK = "/cash-desks"
export const CLOSE_CASH_DESK = "/cash-desks"
export const GET_CLOSE_CASH_DESKS = "/cash-desks/all"
export const GET_CASH_DESKS = "/cash-desks"
export const CHECK_OPEN_CASHDESK = "/cash-desks/opened-by-current-user"
export const UPDATE_CASHDESK = "/cash-desks"

// Bank account
export const CREATE_BANK_ACCOUNT = "/bank-accounts"
export const UPDATE_BANK_ACCOUNT = "/bank-accounts"
export const DELETE_BANK_ACCOUNT = "/bank-accounts"
export const GET_BANK_ACCOUNTS = "/bank-accounts"
export const GET_ALL_BANK_ACCOUNT = "/bank-accounts/load/all"

// Requesition
export const GET_REQUISITIONS = "/requisitions"
export const CREATE_REQUISITION = "/requisitions"
export const GET_REQUISITION = "/requisitions"
export const APPROVE_REQUISITION = "/requisitions"
export const REJECT_REQUISITION = "/requisitions"
export const PAY_REQUISITION = "/requisitions"
export const CANCEL_REQUISITION = "/requisitions"
export const UPDATE_REQUISITION = "/requisitions"
export const GET_REQUISITION_STATUS = "/referential-values/requisition-statuses"

// Cash drop
export const GET_CASHDROPS = "/cash-drops"
export const CREATE_CASHDROP = "/cash-drops"

// Other Revenues
export const GET_OTHER_REVENUES = "/revenues"
export const CREATE_OTHER_REVENUE = "/revenues"

// Print Setting
export const GET_PRINT_SETTINGS = "/print-settings"
export const SAVE_PRINT_SETTING = "/print-settings"

///////////////