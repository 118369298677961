import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'
import {
  getLabRecord as onGetLabRecord,
  getAllLabRemarks as onGetAllLabRemarks,
  getAllLabSamples as onGetAllLabSamples,
  createLabTests as onCreateLabTests
} from "../../slice/thunks"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from '../../components/Common/GlobalLoader'
import { Link, useNavigate, useParams } from "react-router-dom"
import TestsRow from '../../components/App/LabRecords/TestsRow'
import DeleteModal from '../../components/Global/DeleteModal'

const LabRecordDetail = (props) => {
    const { id }= useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Laboratory;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            labRecord: setting.labRecord,
            allLabRemarks: setting.allLabRemarks,
            allLabSample: setting.allLabSample,
            createLabTestSuccess: setting.createLabTestSuccess,
            error: setting.error
        })
    )
    const { allLabRemarks, allLabSample, labRecord, createLabTestSuccess, error } = useSelector(SettingsProperties)
    const permission = JSON.parse(localStorage.getItem("perm"));
    const [ labRemarkOptions, setLabRemarkOptions ] = useState([])
    const [ labSampleOptions, setLabSampleOptions ] = useState([])
    const [ labTests, setLabTests ] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [currentRow, setCurrentRow ] = useState(null)
    const [isLoading, setIsLoading] = useState(true)


    const loadLabRec = useCallback(() => {
        if (id) {
          dispatch(onGetLabRecord(id))
        } 
    })

    const loadAllRemarks = useCallback(() => {
        if (id) {
          dispatch(onGetAllLabRemarks(id))
        } 
    })

    const loadAllSamples = useCallback(() => {
        if (id) {
          dispatch(onGetAllLabSamples(id))
        } 
    })

    useEffect(() => {
        loadLabRec()
        loadAllRemarks()
        loadAllSamples()
    },[dispatch])

    useEffect(() => {
        if (labRecord && labRecord.id) {
            setIsLoading(false)
        }
    },[labRecord])

    useEffect(() =>{
        if (allLabRemarks && allLabRemarks.length > 0) {
          const remarks_list = allLabRemarks.map( rem => {
            return { label: rem.name, value: rem.id }
          })
          setLabRemarkOptions(remarks_list)
        }
    },[allLabRemarks])

    useEffect(() =>{
        if (allLabSample && allLabSample.length > 0) {
          const samples_list = allLabSample.map( samp => {
            return { label: samp.name, value: samp.id }
          })
          setLabSampleOptions(samples_list)
        }
    },[allLabSample])

    useEffect(() => {
        if (labRecord && labRecord.labTests && labRecord.labTests.length > 0) {
            setLabTests(labRecord.labTests)            
        }
    },[labRecord])

    useEffect(() => {
        if (createLabTestSuccess && !error) {
            history("/lab/lab-record")
        }
    },[createLabTestSuccess, labRecord])

    const handleUpdateRow = (row, index) => {
        console.log(row, index);
        let current_labTest = [...labTests]; 
        if (index !== -1) {
            current_labTest[index] = { ...current_labTest[index], ...row };
            setLabTests(current_labTest);
        } 
    }
    const onClickDelete = (row) => {
        setCurrentRow(row)
        setDeleteModal(true);
    }
    const handleDelete = () => {        
        let current_labTest = [...labTests]; 
        current_labTest = current_labTest.filter((prod) => prod.productName !== currentRow.productName )
        console.log(current_labTest)
        setLabTests(current_labTest)
        setCurrentRow(null)
        setDeleteModal(false)
    }

    const saveLabTest = () => {        
        dispatch(onCreateLabTests({ id: labRecord.id, body: {labTests: labTests}}))
    }


  return (
    <React.Fragment>
        {isLoading ?
          <GlobalLoader />
            :
            <>
                <div className="px-4">
                    <div className="lab-record-details">
                        <div className="row py-4">
                            <div className="col-lg-4">
                                <Link to="/lab/lab-record">
                                    <div className="head-area">
                                        <i className="fa-solid fa-chevron-left"></i>
                                        <h6>{props.t("lab_tests")}</h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="patient-detail">
                                    <span className="title">{props.t("patient_id")}</span>
                                    <span className="value">- { labRecord && labRecord.patient && labRecord.patient.patientRef }</span>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="patient-detail">
                                    <span className="title">{props.t("patient_name")}</span>
                                    <span className="value">- { labRecord && labRecord.patient && labRecord.patient.firstName} {labRecord && labRecord.patient && labRecord.patient.lastName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="detail-table">
                            <table className="table m-0">
                                <thead>
                                    <tr>
                                        <th>{props.t("test_name")}</th>
                                        <th>{props.t("sample_type")}</th>
                                        <th>{props.t("results")}</th>
                                        <th>{props.t("range")}</th>
                                        <th>{props.t("remark")}</th>
                                        <th className="action-main">{props.t("action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {labTests.map((row, index) => (
                                        <tr key={row.productName}>
                                            <TestsRow row={row} index={index} 
                                                labRemarkOptions = {labRemarkOptions}
                                                labSampleOptions = {labSampleOptions} 
                                                updateRow= {handleUpdateRow} 
                                                deleteRow={onClickDelete}
                                            />
                                        </tr>
                                    ))}
                                    {/* <tr>
                                        <td>Test 1</td>
                                        <td>
                                            <select>
                                                <option>Sample</option>
                                                <option>Sample1</option>
                                                <option>Sample2</option>
                                            </select>
                                        </td>
                                        <td>
                                            Type here
                                        </td>
                                        <td>
                                            Type here
                                            </td>
                                        <td>
                                            <select>
                                                <option>Negative</option>
                                                <option>Positive</option>
                                            </select>
                                        </td>
                                        <td className="action">
                                            <i className="fa-solid fa-trash"></i>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        {permission.includes('ADD_LAB_TESTS') || permission.includes('UPDATE_LAB_TESTS') ?
                            <div className="btns-area py-4 pb-5" style={{ height: "300px" }} >
                                {/* <a href="tax-invoice.html"><button className="print-btn me-2"><i className="fa-solid fa-print"></i>{props.t("print")}</button></a> */}
                                <button className="save-btn" onClick={() => saveLabTest()}>{props.t("save")}</button>
                            </div>
                            :
                            <></>
                        }
                
                        
                    </div>
                </div>
                <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />

            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(LabRecordDetail))
