import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getClassesAccounts as onGetClassesAccounts,
  getParentClassesAccount as onGetParentClassesAccount,
  getParentSubClassAccount as onGetParentSubClassAccount,
  getTypesClassesAccount as onGetTypesClassesAccount,
  getClassAccount as onGetClassAccount,
  createClassAccount as onCreateClassAccount,
  updateClassAccount as onUpdateClassAccount,
  deleteClassAccount as onDeleteClassAccount,
  searchClassAccount as onSearchClassAccount,

} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerBase from "../../../components/Common/TableContainerBase"

const Accounting = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      classesAccount: setting.classesAccount,
      parentClassesAccount: setting.parentClassesAccount,
      parentSubClassesAccount: setting.parentSubClassesAccount,
      typeClassesAccount: setting.typeClassesAccount,
      classAccount: setting.classAccount,
      ClassAccountCreateSuccess: setting.ClassAccountCreateSuccess,
      ClassAccountUpdateSuccess: setting.ClassAccountUpdateSuccess,
      ClassAccountDeleteSuccess: setting.ClassAccountDeleteSuccess,
      error: setting.error
    })
  )
  const { classesAccount, parentClassesAccount, typeClassesAccount, classAccount, ClassAccountCreateSuccess,
          ClassAccountUpdateSuccess, ClassAccountDeleteSuccess, parentSubClassesAccount, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [modalParent, setModalParent] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [parentOptions, setParentOptions] = useState([])
  const [typesOptions, setTypesOptions] = useState([
    {label: " ", value: null},
    {label: props.t("credit"), value: "Credit"},
    {label: props.t("debit"), value: "Debit"},
  ])
  const [selectedParent, setselectedParent] = useState(null)
  const [selectedParentFilter, setselectedParentFilter] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [selectedTypes, setselectedTypes] = useState(null)
  const [currentClassAccount, setCurrentClassAccount] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() =>{
    loadClassesAccount()
    loadAllParentClassesAccount()
  },[dispatch])

  useEffect(() =>{
    if (parentClassesAccount && parentClassesAccount.length > 0) {
      let parent_list = [{label: " ", value: null}]
      let list = parentClassesAccount.map( classAccount => {
        return { label: classAccount.name, value: classAccount.id }
      })
      setParentOptions([...parent_list, ...list])
    }
  },[parentClassesAccount])

  useEffect(() => {
    if (ClassAccountCreateSuccess && !error) {
      setIsEdit(false)
      setselectedParent(null)
      setselectedTypes(null)
      validation.resetForm()
      setModal(false)
      setCurrentClassAccount(null)
      loadClassesAccount()
      loadAllParentClassesAccount()
    }
  },[ClassAccountCreateSuccess, error, classAccount])

  useEffect(() => {
    if (ClassAccountUpdateSuccess && !error) {
      setIsEdit(false)
      setselectedParent(null)
      setselectedTypes(null)
      validation.resetForm()
      setModal(false)
      setCurrentClassAccount(null)
      loadClassesAccount()
      loadAllParentClassesAccount()
    }
  },[ClassAccountUpdateSuccess, error, classAccount])

  useEffect(() => {
    if (ClassAccountDeleteSuccess && !error) {
      setIsEdit(false)
      setselectedParent(null)
      setselectedTypes(null)
      validation.resetForm()
      setModal(false)
      setCurrentClassAccount(null)
      loadClassesAccount()
      loadAllParentClassesAccount()
    }
  },[ClassAccountDeleteSuccess, error, classAccount])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentClassAccount(null);
      setselectedParent(null);
      setselectedTypes(null)
    } else {
      setModal(true);
    }
  }, [modal])

  const toggleParent = useCallback(() => {
    if (modalParent) {
      setModalParent(false);
    } else {
      setModalParent(true);
    }
  }, [modalParent])

  useEffect(() => {
    if (classesAccount && classesAccount.page) {
      setIsLoading(false)
    }
  },[classesAccount])

  useEffect(() => {
    if (selectedParentFilter && selectedParentFilter.value) {
      loadClassesAccount(1, 10)
    }
  },[selectedParentFilter])

  useEffect(() =>{
    if (searchInput.length >= 3) {
      loadClassesAccount(1,10)
    } else if (searchInput < 1) {
      if (selectedParentFilter && selectedParentFilter.value != null) {
        loadClassesAccount(1, 10)
      } else {
        loadClassesAccount()
      }
    }
  },[searchInput])
  
  const searchClassAc = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchClassAccount({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchClassAccount({term: term, page: 1, size: 10}))
    }
  },[])
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentClassAccount && currentClassAccount.id) || '',
      code: (currentClassAccount && currentClassAccount.code) || '',
      name: (currentClassAccount && currentClassAccount.name) || '',
      parentClassAccountId: (currentClassAccount && currentClassAccount.parent && currentClassAccount.parent.id) || '',
      classAccountType: (currentClassAccount && currentClassAccount.parent && currentClassAccount.parent.id) || '',
      description: (currentClassAccount && currentClassAccount.description) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("you_need_provide_bedNumber")),
      code: Yup.string().required(props.t("you_need_provide_bedCharge")),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateClassAccountForm = {
          id: currentClassAccount && currentClassAccount.id,
          name: values.name,
          code: values.code,
          description: values.description,
          classAccountType: selectedTypes && selectedTypes.value,
          parentClassAccountId: selectedParent.value,
        }
        const id = currentClassAccount.id

        dispatch(onUpdateClassAccount({ body: updateClassAccountForm, id: id }))
      } else {
        const newClassAccountForm = {
          name: values.name,
          code: values.code,
          description: values.description,
          classAccountType: selectedTypes && selectedTypes.value,
          parentClassAccountId: selectedParent && selectedParent.value || null
        }
        dispatch(onCreateClassAccount(newClassAccountForm))
      }
      
    },
  })

  const loadClassesAccount = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      if ((selectedParentFilter && selectedParentFilter.value != null) && (searchInput.length >= 3)) {
        dispatch(onGetClassesAccounts({page: pageNumber, size: pageSize, parent: selectedParentFilter.value, term: searchInput }))
      } else if (selectedParentFilter && selectedParentFilter.value != null) {
        dispatch(onGetClassesAccounts({page: pageNumber, size: pageSize, parent: selectedParentFilter.value }))
      } else if (searchInput.length >= 3) {
        dispatch(onGetClassesAccounts({page: pageNumber, size: pageSize, term: searchInput }))
      }
      else {
        dispatch(onGetClassesAccounts({page: pageNumber, size: pageSize}))
      }

    } else {
      dispatch(onGetClassesAccounts())
    }
  })

  const loadAllParentClassesAccount = useCallback(() => {
    dispatch(onGetParentClassesAccount())
  })

  const loadParentSubClassAccount = useCallback((id) => {
    dispatch(onGetParentSubClassAccount(id))
  })

  const loadAllTypesClassesAccount = useCallback(() => {
    dispatch(onGetTypesClassesAccount())
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleOpenParent = (row) => {
    loadParentSubClassAccount(row.parent.id)
    toggleParent()
  }

  const handleSelectAccount = (arg) => {
    const selectedAccount = arg
    setCurrentClassAccount({
      id: selectedAccount.id,
      name: selectedAccount.name,
      code: selectedAccount.code,
      wards: selectedAccount.wards
    })
    setselectedParent({ label: selectedAccount.parent && selectedAccount.parent.name, value: selectedAccount.wards && selectedAccount.parent.id })
    setselectedTypes({ label: selectedAccount.classAccountType, value: selectedAccount.classAccountType })
    setIsEdit(true);
    toggle();
  }

  function handleSearch () {
    loadClassesAccount(1, 10)
  }

  function handleSelectParentFilter(selectedVal) {
    setselectedParentFilter(selectedVal)
}

  const onClickDelete = (classAccount) => {
    setCurrentClassAccount(classAccount);
    setDeleteModal(true);
  }

  function handleDeleteClassAccount() {
    if (currentClassAccount) {
      dispatch(onDeleteClassAccount( currentClassAccount.id ))
      loadClassesAccount()
      setDeleteModal(false)
    }
  }

  function handleSelectWard(selectedParent) {
    setselectedParent(selectedParent)
  }

  function handleSelectType(selectedType) {
    setselectedTypes(selectedType)
  }

  const columns = useMemo(
    () => [
      
      {
        header: props.t("name"),
        accessorKey: "name",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("code"),
        accessorKey: "code",
        style: "button",
        enableColumnFilter: false,
      },
      // {
      //   header: props.t("type"),
      //   accessorKey: "classAccountType",
      //   style: "button",
      //   enableColumnFilter: false,
      // },
      {
        header: props.t("parent"), 
        accessorKey: "wards",
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => {
            return <Link
            to="#"
            className="text-dark d-inline-block edit-item-btn"
            onClick={() => { const classAccount = cellProps.row.original; handleOpenParent(classAccount); }}
          >
            <span>{(cellProps.row.original.parent != null )? cellProps.row.original.parent.name : ''}</span>
        </Link>
        }
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => { const classAccount = cellProps.row.original; handleSelectAccount(classAccount); }}
                    >
                      <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                    </Link>
                  </li>
                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const classAccount = cellProps.row.original; onClickDelete(classAccount); }}
                    >
                      <img src="../assets/images/delete.png" alt=""></img>
                    </Link>
                  </li>
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectAccount]
  );

  const columnsParent = useMemo(
    () => [
      {
        header: props.t("name"),
        accessorKey: "name",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("code"),
        accessorKey: "code",
        style: "button",
        enableColumnFilter: false,
      },
    ],
    [parentSubClassesAccount]
  );

  const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Définit un z-index maximal pour le menu
    }),
    control: (provided) => ({
        ...provided,
        zIndex: 9999, // Définit un z-index maximal pour le contrôle
    }),
    // Vous pouvez ajouter d'autres styles personnalisés ici
  };


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteClassAccount} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                          <BreadCrumb title={props.t("classes_account")} pageTitle={props.t("settings")} />
                          </div>
                          <div className="col-sm-auto ">
                            <SinpleActionButton title={props.t("create_class_account")} img="../assets/images/user.png" action={handleOpen} />
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="card container">
                          <Row className="mt-3">
                            <Col xl={4} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="status-field" className="form-label">{props.t("select_parent")}</Label><br/>
                                    <Select
                                        name='status'
                                        id='status-field'
                                        value={selectedParentFilter}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(e) => {
                                          handleSelectParentFilter(e);
                                          if (!e) { // Vérifie si la valeur est nulle (c'est-à-dire que le champ a été effacé)
                                            loadClassesAccount(); // Appelle la fonction à exécuter lors de l'effacement
                                        }
                                        }}
                                        options={parentOptions}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        styles={customStyles}
                                    />
                                </div>
                            </Col>
                            <Col xl={4} sm={4}>
                                <div className='mb-2'>
                                    <Label htmlFor="status-field" className="form-label">{props.t("search_class_account")}</Label><br/>
                                    <div className="d-flex align-items-center">
                                        <div className="search-box me-2 mb-1 col-10">
                                            <Input
                                                className="custom-border form-control"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </div>
                                        <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                            <i className="fa fa-search p-0"></i>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                          </Row>
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((classesAccount && classesAccount.items) || [])}
                                    isGlobalFilter={false}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ classesAccount && classesAccount.size }
                                    tablePage={ classesAccount && classesAccount.page }
                                    tableTotal= { classesAccount && classesAccount.total_items }
                                    tableTotalPage= { classesAccount && classesAccount.total_page }
                                    onNextPage= {loadClassesAccount}
                                    onSearch= {searchClassAc}
                                    SearchPlaceholder={props.t("search_class_account")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_class_account") : props.t("create_class_account")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={6} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="name-field"
                                      className="form-label"
                                    >
                                      {props.t("name")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="name"
                                      id="name-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("name")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name && validation.errors.name ? true : false
                                      }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                      <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={6} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="code-field"
                                      className="form-label"
                                    >
                                      {props.t("code")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="code"
                                      id="code-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("code")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.code || ""}
                                      invalid={
                                        validation.touched.code && validation.errors.code ? true : false
                                      }
                                    />
                                    {validation.touched.code && validation.errors.code ? (
                                      <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className='mb-3'>
                                    <Label
                                      htmlFor="parentClassAccountId-field"
                                      className="form-label"
                                    >
                                      {props.t("parent")}
                                    </Label>
                                    <Select
                                      name='parentClassAccountId'
                                      id='parentClassAccountId-field'
                                      value={selectedParent}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectWard(e);
                                      }}
                                      options={parentOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col>
                                {/* <Col xl={6} sm={12}>
                                  <div className='mb-3'>
                                    <Label
                                      htmlFor="classAccountType-field"
                                      className="form-label"
                                    >
                                      {props.t("type")}
                                    </Label>
                                    <Select
                                      name='classAccountType'
                                      id='classAccountType-field'
                                      value={selectedTypes}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectType(e);
                                      }}
                                      options={typesOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col> */}

                                <Col xl={12} sm={12}>
                                    <div>
                                        <Label htmlFor="description-field" className="form-label">
                                            {props.t("description")}
                                        </Label>
                                        <textarea 
                                            name="description"
                                            className="form-control" 
                                            id="description-field" 
                                            placeholder={props.t("enter_description")}
                                            rows={3}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.description || ""}
                                            invalid={
                                                validation.touched.description && validation.errors.description ? true : false
                                            }
                                        >
                                        </textarea>
                                    </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setselectedParent(null); setselectedTypes(null); setCurrentClassAccount(null); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>
                      </Modal>
                      
                      <Modal 
                        id='showModal' 
                        size='lg' 
                        isOpen={modalParent || false} 
                        toggle={toggleParent} 
                        backdrop={'static'} centered
                      >
                        <ModalHeader className="bg-light p-3" toggle={toggleParent}>
                          { props.t("all_classes_account") }
                        </ModalHeader>
                        <ModalBody>
                          <div className="row justify-content-between" >
                            <div className="card table-card" style = {{height: "50vh" }}>
                              <TableContainerBase
                                columns={columnsParent}
                                data={((parentSubClassesAccount && parentSubClassesAccount) || [])}
                                isGlobalFilter={false}
                                isAddUserList={false}
                                customPageSize={100}
                                divClass="card table-card table-responsive p-0"
                                tableClass="table align-middle"
                                theadClass="table-light text-muted"
                                handlePlanClick={loadParentSubClassAccount}
                                isCustomerFilter={false}
                                SearchPlaceholder={props.t("")}
                              />
                            </div>
                          </div>
                        </ModalBody>
                        <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModalParent(false) }}> {props.t("close")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                      </Modal>
                  </div>
              </div>
          </div>
        </>  
    }

    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Accounting))
