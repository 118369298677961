import { useEffect, useState } from "react";
import { getLoggedinUserToken } from "../../helpers/api_helper";

import { useSelector } from "react-redux";
import { createSelector } from "reselect";


const useProfile = () => {

  const selectLayoutState = (state) => state.Login;
  const LoginProperties = createSelector(
    selectLayoutState,
    (login) => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const userTokenSession = getLoggedinUserToken();
  var token = userTokenSession && userTokenSession;
  const [loading, setLoading] = useState(userTokenSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    user ? user : null
  );

  useEffect(() => {
    const userTokenSession = getLoggedinUserToken();
    var token = userTokenSession && userTokenSession;
    setUserProfile(user ? user : null);
    setLoading(token ? false : true);
  }, [user]);


  return { userProfile, loading, token };
};

export { useProfile };