import { toast } from 'react-toastify';

import {
    getConsultationListDoctor as getConsultationListDoctorApi,
    getPatientAllVitals as getPatientAllVitalsApi,
    getDoctorsAppointments as getDoctorsAppointmentsApi,
    registerAppointment as registerAppointmentApi,
    getAppointmentsDetails as getAppointmentsDetailsApi,
    updateAppointment as updateAppointmentApi,
    deleteAppointment as deleteAppointmentApi,
    markAppointmentAttended as markAppointmentAttendedApi,
    markPatientConsultedDoctor as markPatientConsultedDoctorApi,
    getPatientLabRecords as getPatientLabRecordsApi
} from "../../helpers/backend_helper"
import { createAsyncThunk } from '@reduxjs/toolkit';

/*
* Patients
*/
export const getConsultationListDoctor = createAsyncThunk("doctors/getConsultationListDoctor", async(config) => {
    try {
        const response = await getConsultationListDoctorApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientAllVitals = createAsyncThunk("doctors/getPatientAllVitals", async(id) => {
    try {
        const response = await getPatientAllVitalsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientLabRecords = createAsyncThunk("doctors/getPatientLabRecords", async(config) => {
    try {
        const response = await getPatientLabRecordsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getDoctorsAppointments = createAsyncThunk("doctors/getDoctorsAppointments", async(config) => {
    try {
        const response = await getDoctorsAppointmentsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const registerAppointment = createAsyncThunk("doctors/registerAppointment", async(config) => {
    try {
        const response = await registerAppointmentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getAppointmentsDetails = createAsyncThunk("doctors/getAppointmentsDetails", async(id) => {
    try {
        const response = await getAppointmentsDetailsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const updateAppointment = createAsyncThunk("doctors/updateAppointment", async(config) => {
    try {
        const response = await updateAppointmentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteAppointment = createAsyncThunk("doctors/deleteAppointment", async(config) => {
    try {
        const response = await deleteAppointmentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const markAppointmentAttended = createAsyncThunk("doctors/markAppointmentAttended", async(id) => {
    try {
        const response = await markAppointmentAttendedApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const markPatientConsultedDoctor = createAsyncThunk("doctors/markPatientConsultedDoctor", async(config) => {
    try {
        const response = await markPatientConsultedDoctorApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})


