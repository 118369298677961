import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link, useNavigate, useParams } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import {
  createTransfertOrder as onAddTransfertOrder,
  updateTransfertOrder as onUpdateTransfertOrder,
  confirmTransfertOrder as onConfirmTransfertOrder,
  getTransfertOrder as onGetTransfertOrder,
  getAllSuppliers as onGetAllSuppliers,
  getProductsAndSearchStock as onGetProductsAndSearchStock,
  deleteTransfertOrderItem as onDeleteTransfertOrderItem,
} from "../../../slice/thunks"
import { Input, Label } from 'reactstrap'
import TransfertRow from '../../../components/App/Warehouse/TransfertRow'
import DeleteModal from '../../../components/Global/DeleteModal'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const NewTransfertOrder = (props) => {
    const { id, action } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            productsListStock: setting.productsListStock,
            transfertOrder: setting.transfertOrder,
            createTransfertOrderSuccess: setting.createTransfertOrderSuccess,
            updateTransfertOrderSuccess: setting.updateTransfertOrderSuccess,
            confirmTransfertOrderSuccess: setting.confirmTransfertOrderSuccess,
            deleteTransfertOrderSuccess: setting.deleteTransfertOrderSuccess,
            error: setting.error
        })
    )
    const { productsListStock, transfertOrder, createTransfertOrderSuccess, updateTransfertOrderSuccess, confirmTransfertOrderSuccess, deleteTransfertOrderSuccess, error } = useSelector(SettingsProperties)
    const [productsOption, setProducstOption] = useState(null)
    const [selectedDestination, setSellectedDestination] = useState(null)
    const [selectedProduct, setSellectedProduct] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [transferOrderItems, setTransfertOrderItems] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [isWatch, setIsWatch] = useState(false)
    const transfertTo = [
      { label: props.t("pharmacy"), value: "Pharmacy"},
      { label: props.t("laboratory"), value: "Laboratory"},
    ]
    const [isLoading, setIsLoading] = useState(true)

    const loadAllSuppliers = useCallback(() => {
      dispatch(onGetAllSuppliers())
    })

    const loadSupplierOrder = useCallback((currentId) => {
      if (currentId) {
        dispatch(onGetTransfertOrder(currentId))
      }
    })

    const loadAllProduct = useCallback((term) => {
      if (term && term.length > 0) {
        dispatch(onGetProductsAndSearchStock(term))
      } else {
        dispatch(onGetProductsAndSearchStock())
      }
    })


    useEffect(() => {
      if (action && action === 'edit') {
        setIsEdit(true)
      } else if (action && action === 'validate') {
        setIsEdit(true)
        setIsValidate(true)
      } else if (action && action === 'see') {
        setIsWatch(true)
        setIsValidate(true)
      }
    },[action])

    useEffect(() => {
      loadAllSuppliers();
      loadAllProduct();
      if ((action === 'edit') || action === 'validate' || action === 'see') {
        loadSupplierOrder(id)
      }
    },[dispatch])

    useEffect(() =>{
      setIsLoading(false)
      if (productsListStock && productsListStock.length > 0) {
        const products_list = productsListStock.map( prod => {
          return { 
            label: prod.product.name, 
            value: prod.product.id, 
            quantityInStock: prod.quantityInStock 
          }
        })
        setProducstOption(products_list)
      }
    },[productsListStock])

    useEffect(() => {
      if (transfertOrder && transfertOrder.orderRef) {
        setSellectedDestination((transfertTo.filter((e) => e.value == transfertOrder.transferTo))[0])
        setSelectedDate(transfertOrder.orderDate)
        
        let Objproducts = transfertOrder.transferOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.product.id,
            quantity: item.quantity,
            name: item.product.name,
            quantityInStock: item.product.quantity,
            // quantityInRest: sup.quantityInStock,
          };
        });

        setTransfertOrderItems(Objproducts)
      }
    }, [transfertOrder])

    useEffect(() => {
      if (createTransfertOrderSuccess && !error) {
        setIsEdit(false)
        setSelectedDate(null)
        setSellectedDestination(null)
        setSellectedProduct(null)
        setTransfertOrderItems([])
        history("/warehouse/transfert-order")
      }
    },[createTransfertOrderSuccess, error, transfertOrder])

    useEffect(() => {
      if (updateTransfertOrderSuccess && !error) {
        history("/warehouse/transfert-order")
      }
    },[updateTransfertOrderSuccess, error, transfertOrder])

    useEffect(() => {
      if (confirmTransfertOrderSuccess && !error) {
        history("/warehouse/transfert-order")
      }
    },[confirmTransfertOrderSuccess, error, transfertOrder])

    useEffect(() => {
      if (deleteTransfertOrderSuccess && !error) {
        let current_products = [...transferOrderItems]; 
        current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
        setTransfertOrderItems(current_products);
      }
    },[deleteTransfertOrderSuccess, error, transfertOrder])

    useEffect(() => {
      if (selectedProduct !== null) {
          let current_products = [...transferOrderItems]; 
          if (!current_products.some((current) => current.productId === selectedProduct.productId)) {
            const new_products = [...current_products, selectedProduct]; 
            setTransfertOrderItems(new_products); 
          }
      }
    }, [selectedProduct]);


    const handleSelectDestination = (sup) => {
      setSellectedDestination(sup)
    }

    const handleSelectProduct = (sup) => {
      if(sup) {
        let row = {
          productId: sup.value,
          name: sup.label,
          quantityInStock: sup.quantityInStock,
          quantityInRest: sup.quantityInStock,
          quantity: 1
        }
        setSellectedProduct(row)
      }
    }

    const handleSearchProduct = (value) => {
      loadAllProduct(value)
    }

    const handleUpdateRow = (row) => {
      let current_products = [...transferOrderItems]; 
      const index = current_products.findIndex(objet => objet.productId === row.productId);
      if (index !== -1) {
        current_products[index] = { ...current_products[index], ...row };
        setTransfertOrderItems(current_products); 
      } 
    }

    const handleChangeDate = (val) => {
      setSelectedDate(val)
    }

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const onClickDelete = (row) => {
      setSellectedProduct(row)
      setDeleteModal(true);
    }

    const handleDelete = () => {
      if (selectedProduct) {
        if (selectedProduct.id) {
          dispatch(onDeleteTransfertOrderItem(selectedProduct.id))
        } else {
          let current_products = [...transferOrderItems]; 
          current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
          setTransfertOrderItems(current_products); 
        }
        setDeleteModal(false)
      }
    }

    const saveOrder = () => {
      let productsOrder = []

      if (isValidate) {

        productsOrder =  transferOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.productId,
            quantity: item.quantity,
          };
        });

        let confirmData = {
          orderDate: selectedDate,
          transferTo: selectedDestination.value,
          status: "",
          transferOrderItems: productsOrder
        }

        dispatch(onConfirmTransfertOrder({ id: id, data: confirmData }))
      }else if (isEdit) {
        productsOrder =  transferOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.productId,
            quantity: item.quantity
          };
        });

        let updateData = {
          orderDate: selectedDate,
          transferTo: selectedDestination.value,
          status: "",
          transferOrderItems: productsOrder
        }
        dispatch(onUpdateTransfertOrder({ id:id, data:updateData }))
      } else {

        productsOrder =  transferOrderItems.map(item => {
          return {
            productId: item.productId,
            quantity: item.quantity
          };
        });

        let createData = {
          orderDate: selectedDate,
          transferTo: selectedDestination.value,
          status: "",
          transferOrderItems: productsOrder
        }

        dispatch(onAddTransfertOrder(createData))
      }
    }

    const onClear = () => {
      setIsEdit(false)
      setSelectedDate(null)
      setSellectedDestination(null)
      setSellectedProduct(null)
      setTransfertOrderItems([])
    }

  return (
    <React.Fragment>
      {isLoading ?
          <GlobalLoader />
        :
          <>
            <div className="record-Purchase-contant pb-5">
                <div className="record-list py-4 px-4">
                    <h1>{ props.t("transfert_order")}</h1>
                    <div className="row  Purchase-order">
                        <h6>
                            <Link to="/warehouse/transfert-order">
                                <i className="fa-solid fa-chevron-left"></i> {isWatch ? props.t("transfert_order") : isEdit ? props.t("edit_transfert_order") : props.t("new_transfert_order")}
                            </Link>
                        </h6>
                    </div>

                    <div className="row py-3 Purchase-list">
                      {isEdit ? 
                        <div className="col-lg-2 second-select pt-3">
                            <Label  htmlFor="supplier-field" className="form-label">
                                {props.t("transfert_order_no")}<span className='text-danger'>*</span>
                            </Label>
                            <h4 className='px-2'><b>{ transfertOrder && transfertOrder.orderRef }</b></h4>
                        </div>
                      : <></>
                      }

                        <div className="col-lg-3 second-select pt-3">
                            <Label  htmlFor="supplier-field" className="form-label">
                                {props.t("sector")}<span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='supplier'
                                id='supplier-field'
                                value={selectedDestination}
                                isMulti={false}
                                isClearable={false}
                                autoComplete="off" 
                                onChange={(e) => {
                                  handleSelectDestination(e);
                                }}
                                options={transfertTo}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isDisabled = {isValidate ? true : false}
                            />
                        </div>
                        <div className="col-lg-3 second-select pt-3">
                            <Label  htmlFor="supplier-field" className="form-label">
                                {props.t("transfert_order_data")}<span className='text-danger'>*</span>
                            </Label>
                            <Input 
                                name="dob" id='dob-field' className="form-control" type="date"
                                value={selectedDate}
                                validate={{ required: { value: true } }} 
                                max={getTodayDate()} 
                                onChange={(e) => handleChangeDate(e.target.value)}
                                autoComplete="off"
                                disabled = {isValidate ? true : false}
                            />
                        </div>

                        <div className="col-lg-3 second-select pt-3">
                            <Label  htmlFor="product-field" className="form-label">
                                {props.t("product")}<span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='product'
                                id='product-field'
                                value=""
                                isMulti={false}
                                isClearable={false}
                                autoComplete="off"
                                onChange={(e) => {
                                  handleSelectProduct(e);
                                }}
                                onInputChange={(inputValue, { action }) => {
                                  if (action === 'input-change') {
                                    if (inputValue.length > 0) {
                                      handleSearchProduct(inputValue)
                                    } else {
                                      loadAllProduct()
                                    }
                                  }
                                }}
                                onMenuOpen={() => {
                                  loadAllProduct()
                                }}
                                options={productsOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isDisabled = {isValidate ? true : false}
                            />
                        </div>
                    </div>

                    <div className="Purchase-all-list ">
                        <div className="table table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr className="first-head">
                                        <th scope="col" style={{ width: "7%", textAlign: "center", padding:"17px" }}>{props.t("s_no")}</th>
                                        <th scope="col" style={{ width: "60%", padding:"17px" }}>{props.t("item")}</th>
                                        <th scope="col" style={{ width: "10%", textAlign: "center", padding:"17px" }}>{props.t("qtyInStock")}</th>
                                        <th scope="col" style={{ width: "10%", textAlign: "center", padding:"17px" }}>{props.t("qtyToTransfert")}</th>
                                        {/* <th scope="col" style={{ width: "19%", padding: "17px" }}>{props.t("rate_fcfa")}</th>
                                        <th scope="col" style={{ width: "19%", padding:"17px" }}>{props.t("amount")}</th> */}
                                        <th scope="col" style={{ width: "5%", textAlign: "center", padding: "17px" }}>{props.t("action")}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                  {transferOrderItems.map((row, index) => (
                                    <tr key={row.productId}>
                                      <TransfertRow row={row} index={index} isEdit={id ? true : false } isWatch={isWatch} isValidate= {isValidate} updateRow= {handleUpdateRow} deleteRow={onClickDelete}/>
                                    </tr>
                                  ))}
                                </tbody>
                            </table>
                            <div className="bttns">
                                <Link to="/warehouse/transfert-order" onClick={() => onClear()}>
                                  <button className="btn-secondary" style={{ padding: "6px 35px 6px 35px", fontSize: "18px", margin: "8px", textAlign: "center", borderRadius: "8px" }} type="button">{props.t("cancel")}</button>
                                </Link>
                                {!isWatch ?
                                  <button 
                                    className="btn-two" 
                                    onClick={() => saveOrder()}
                                    disabled={ (selectedDestination && selectedDestination.value) && selectedDate && (transferOrderItems.length > 0) ? false : true }
                                  >
                                    {isValidate ? props.t("validate") : isEdit ? props.t("update") :  props.t("save")}
                                  </button>
                                  :
                                  <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />
          </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NewTransfertOrder))
