import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import { 
  getDeposits as onGetDeposits,
  getPayment as onGetPayment,
  confirmPayment as onConfirmPayment,
  getPaymentMode as onGetPaymentMode,
  getUsers as onGetUsers,
} from '../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import makeAnimated from "react-select/animated"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { createSelector } from "reselect"
import Select from "react-select"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import ConfirmModal from '../../../components/Global/ConfirmModal'


function Deposits(props) {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        deposits: setting.deposits,
        payment: setting.payment,
        paymentModes: setting.paymentModes,
        users: setting.users,
        confirmPaymentSuccess: setting.confirmPaymentSuccess,
        error: setting.error,
      })
  )
  const { deposits, payment, confirmPaymentSuccess, users, paymentModes, error } = useSelector(SettingsProperties)
  const [isLoading, setIsLoading] = useState(true)
  const [confirmModal, setConfirmModal] = useState(false)
  const [ currentPayment, setCurrentPayment] = useState(null)
  const [ targetOption, setTargetOption] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [ paymentOption, setPaymentOption ] = useState(null)
  const [ usersOption, setUsersOption ] = useState(null)
  const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
  const [ selectedUser, setSelectedUser ] = useState(null)
  const [periods, setPeriods] = useState([]);
  const [filterPayment, setFilterPayment] = useState(null)
  const fp = useRef(null);



  const loadPayments = useCallback((pageNumber, pageSize) => {
    
    let reqObject = {}

    if ((pageNumber !== null) && (pageNumber != undefined)) reqObject.pageNumber = pageNumber;
    if ((pageSize !== null) && (pageSize != undefined)) reqObject.pageSize = pageSize;
    if (filterPayment !== null) reqObject.methods = filterPayment;
    if (selectedUser !== null) reqObject.users = selectedUser && selectedUser.value;
    if (periods.length > 0) reqObject.startDate = moment(periods[0]).format("YYYY-MM-DD");
    if (periods.length > 0) reqObject.endDate = moment(periods[1]).format("YYYY-MM-DD");
    if (searchInput !== null) reqObject.term = searchInput;

    dispatch(onGetDeposits(reqObject))
  })

  const loadPaymentMode = useCallback(() => {
      dispatch(onGetPaymentMode())
  })

  const loadUsers = useCallback(() => {
    dispatch(onGetUsers())
  })

  useEffect(() =>{
    loadPayments()
    loadUsers()
    loadPaymentMode()
  },[props.isActive])

  useEffect(() => {

    if (deposits && deposits.page > 0) {
      setIsLoading(false)
    }
  },[deposits])

  useEffect(() => {
    if (confirmPaymentSuccess && !error) {
      loadPayments()
      setConfirmModal(false)
      setCurrentPayment(null)
    }
  },[confirmPaymentSuccess, payment])

  useEffect(() => {
    if (paymentModes && paymentModes.length > 0) {
        const payment_lists = paymentModes.filter(mode => mode.value !== 'PATIENT_CREDIT').map(mode => ({
            label: mode.title,
            value: mode.value
        }));
        setPaymentOption(payment_lists);
    }
  }, [paymentModes]);

  useEffect(() => {
    if (users && users.items && users.items.length > 0) {
      const user_lists = users.items.map( user => {
        return { label: user.fullName, value: user.id }
      })
      setUsersOption(user_lists)
    }
  },[users])

  useEffect(() => {
    if (selectedPaymentM && selectedPaymentM.length > 0) {
      let filter_payment = null
      selectedPaymentM.forEach(row => {
        filter_payment = filter_payment === null ? row.value : filter_payment+","+row.value
      });
      setFilterPayment(filter_payment)
    }
    loadPayments()
  },[searchInput, selectedPaymentM, selectedUser, periods])

  const onClickConfirm = (row) => {
    setCurrentPayment(row);
    setConfirmModal(true);
  }

  const handleConfirmPayment = () => {
    if (currentPayment) {
      dispatch(onConfirmPayment( currentPayment.id ))
    }
  }

  function handleSearch () {
    // loadConsultationList(1, 10, searchInput)
  }


  const columns = useMemo(
    () => [
      {
        header: props.t("date_heure"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>
              {moment(cell.row.original.createAt).format('DD / MM/ YYYY HH:mm')}
          </span>;
        },
      },
      {
        header: props.t("ref_no"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.transactionRef}</span>
        }
      },
      {
        header: props.t("patient_name"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.firstName} {cell.row.original.lastName}</span>
        }
      },
      {
        header: props.t("amount"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.amount}</span>
        }
      },
      {
        header: props.t("balance"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.amountAfterTransaction}</span>
        }
      },
      {
        header: props.t("deposit_mode"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.transactionMode}</span>
        }
      },
      // {
      //   header: props.t("action"),
      //   style: "action",
      //   cell: (cellProps) => {
      //     return (
      //       <div className="d-flex justify-content-start">
      //           <ul className="list-inline hstack mb-0">
      //             {cellProps.row.original.confirmedBy == '' ?
      //               <li className="list-inline-item edit" title={props.t("confirm_payment")}>
      //                 <Link
      //                   to="#"
      //                   className="text-dark d-inline-block edit-item-btn" style={{fontSize: "15px" }}
      //                   onClick={() => onClickConfirm(cellProps.row.original)}
      //                 >
      //                   <i class="fa fa-check" aria-hidden="true"></i>
      //                 </Link>
      //               </li>
      //               :
      //               <></>
      //             }
      //           </ul>
      //       </div>
      //     );
      //   },
      // },
    ],
    [deposits, onClickConfirm]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <ConfirmModal show={confirmModal} onConfirmClick={handleConfirmPayment} onCloseClick={() =>setConfirmModal(false)} />

          <div className="patients-list-content">
            <div className="patients-list px-2 pb-5 ">
              <div className="container-fluid ">
                <div className="heading-parent-sec ">
                    <div className="heading-child-one-sec pt-4">
                        {/* <h6>{props.t("deposits")}</h6> */}
                    </div>
                    <div className="heading-child-two-sec pt-4">
                    </div>
                </div>

                <div className="card border-0 row p-2">
                  {/* <Row>
                    <Col xl={3} sm={4} className="">
                        <div className='mb-2'>
                            <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                            <div className='d-flex align-items-center'>
                                <Flatpickr
                                    className='flatpick-custom'
                                    id="inputGroupSelect01"
                                    ref={fp}
                                    placeholder={props.t("select_date_range")}
                                    defaultValue={periods}
                                    value={ periods }
                                    options={{
                                        altInput: true,
                                        altFormat: "d / m/ Y",
                                        maxDate: new Date(),
                                        mode: "range",
                                    }}
                                    onChange={(e) => {
                                        if (e.length > 1) {
                                            setPeriods(e)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={2} sm={4}>
                      <div className='mb-2'>
                          <Label htmlFor="user-field" className="form-label">{props.t("done_by")}</Label><br/>
                          <Select
                              name='user'
                              id='user-field'
                              value={selectedUser}
                              isMulti={false}
                              isClearable={false}
                              onChange={(e) => {
                                  setSelectedUser(e);
                              }}
                              options={usersOption}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                          />
                      </div>
                    </Col>
                    <Col xl={2} sm={4}>
                      <div className='mb-2'>
                          <Label htmlFor="user-field" className="form-label">{props.t("payment_mode")}</Label><br/>
                          <Select
                              name='user'
                              id='user-field'
                              value={selectedPaymentM}
                              isMulti={true}
                              isClearable={false}
                              onChange={(e) => {
                                  setSelectedPaymentM(e);
                              }}
                              options={paymentOption}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                          />
                      </div>
                    </Col>
                    <Col xl={3} sm={4}>
                        <div className='mb-2'>
                            <Label htmlFor="status-field" className="form-label">{props.t("search")}</Label><br/>
                            <div className="d-flex align-items-center">
                                <div className="search-box me-2 mb-1 col-10">
                                    <Input
                                        className="custom-border form-control"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                </div>
                                <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                    <i className="fa fa-search p-0"></i>
                                </button>
                                <button 
                                  type="button" 
                                  className="btn btn-warning mx-1" 
                                  onClick={() => { 
                                    if (!fp?.current?.flatpickr) return; 
                                    fp.current.flatpickr.clear(); 
                                    setPeriods([]);
                                    setSearchInput('')
                                    setFilterPayment(null)
                                    setSelectedUser(null)
                                    setSelectedPaymentM(null)
                                  }} 
                                >
                                    {props.t("clear")}
                                </button>
                            </div>
                        </div>
                    </Col>
                  </Row> */}
                  <div className="row">
                    <TableContainerWarehouse
                      columns={columns}
                      data={((deposits && deposits.items) || [])}
                      isGlobalFilter={false}
                      customPageSize={10}
                      divClass="card table-card table-warehouse table-responsive"
                      tableClass="table"
                      theadClass="thead-sec table-light"
                      tdClass="td"
                      tableSize={ deposits && deposits.size }
                      tablePage={ deposits && deposits.page }
                      tableTotal= { deposits && deposits.total_items }
                      tableTotalPage= { deposits && deposits.total_page }
                      onNextPage= {loadPayments}
                      onSearch= {loadPayments}
                      SearchPlaceholder={props.t("search_payment")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Deposits))
