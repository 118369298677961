import React from 'react'

const GlobalLoader = () => {
  return (
    <div style={{ width:"100%", height:"100%", position: "fixed" }} className=' page-loader d-flex justify-content-center align-items-center'>
        <div className="loader"></div>
    </div>
  )
}

export default GlobalLoader