import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const PaymentsMode = (props) => {
  return (
    <div>PaymentsMode</div>
  )
}

export default withRouter(withTranslation()(PaymentsMode))