import React from 'react'
import { withTranslation  } from 'react-i18next'
import withRouter from "../components/Common/withRouter"


const Footer = (props) => {
  return (
      <footer className="footer-copyright">
        <div className="container">
            <div className="row">
                <p>{props.t("copiright")}</p>
            </div>
        </div>
    </footer>
  )
}

export default withRouter(withTranslation()(Footer));
