import React from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'

const RecortExpend = (props) => {
  return (
    <div>ReportExpend</div>
  )
}

export default withRouter(withTranslation()(RecortExpend))
