import React from 'react'

const SinpleActionButton = ({title, action, img, ico}) => {

  return (
    <React.Fragment>
        <button className="btn btn-std mx-2" type="button" onClick={() => action()}>
          <i className={ico ? `fas ${ico} mx-1`: "fas fa-user-md mx-1"}></i>
          {title}
        </button>
    </React.Fragment>
  )
}

export default SinpleActionButton