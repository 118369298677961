import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getCategories as onGetCategories,
  getAllCategories as onGetAllCategories,
  getCategory as onGetCategory,
  createCategory as onCreateCategory,
  updateCategory as onUpdateCategory,
  deleteCategory as onDeleteCategory,
  searchCategory as onSearchCategory,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const Categories = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      categories: setting.categories,
      all_ategories: setting.all_categories,
      category: setting.category,
      category_success: setting.category_success,
      error: setting.error
    })
  )
  const { categories, all_ategories, category, category_success, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [selectedCategories, setselectedCategories] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() =>{
    loadCategories()
    loadAllCategories()
  },[dispatch])

  useEffect(() => {
    if (categories && categories.page) {
      setIsLoading(false)
    }
  },[categories])

  useEffect(() =>{
    if (all_ategories && all_ategories.length > 0) {
      const categories_list = all_ategories.map( category => {
        return { label: category.name, value: category.id }
      })
      setCategoriesOptions(categories_list)
    }
  },[all_ategories])


  useEffect(() => {
    if (category_success && !error) {
      setIsEdit(false)
      setselectedCategories(null)
      validation.resetForm()
      setModal(false)
      setCurrentCategory(null)
      loadCategories()
      loadAllCategories()
    }
  },[category_success, error, category])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentCategory(null);
      setselectedCategories(null);
    } else {
      setModal(true);
    }
  }, [modal])
  
  const searchBeds = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchCategory({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchCategory({term: term, page: 1, size: 10}))
    }
  },[])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentCategory && currentCategory.id) || '',
      name: (currentCategory && currentCategory.name) || '',
      code: (currentCategory && currentCategory.code) || '',
      parentId: (currentCategory && currentCategory.parent && currentCategory.parent.id) || '',
      description: (currentCategory && currentCategory.description) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("you_need_provide_bedNumber")),
      code: Yup.string().required(props.t("you_need_provide_bedCharge")),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateCategoryForm = {
          id: currentCategory && currentCategory.id,
          name: values.name,
          code: values.code,
          description: values.description,
          parentId: selectedCategories.value
        }
        const id = currentCategory.id

        dispatch(onUpdateCategory({ data: updateCategoryForm, id: id }))
      } else {
        const newCategoryForm = {
          name: values.name,
          code: values.code,
          description: values.description,
          parentId: selectedCategories && selectedCategories.value || null
        }
        dispatch(onCreateCategory(newCategoryForm))
      }
      
    },
  })

  const loadCategories = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetCategories({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetCategories())
    }
  })

  const loadAllCategories = useCallback(() => {
    dispatch(onGetAllCategories())
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectBed = (arg) => {
    const selectedBed = arg
    setCurrentCategory({
      id: selectedBed.id,
      name: selectedBed.name,
      code: selectedBed.code,
      wards: selectedBed.wards
    })
    setselectedCategories({ label: selectedBed.parent && selectedBed.parent.name, value: selectedBed.wards && selectedBed.parent.id })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (category) => {
    setCurrentCategory(category);
    setDeleteModal(true);
  }

  function handleDeleteCategory() {
    if (currentCategory) {
      dispatch(onDeleteCategory( currentCategory.id ))
      loadCategories()
      setDeleteModal(false)
    }
  }

  function handleSelectWard(selectedCategories) {
    setselectedCategories(selectedCategories)
  }

  const columns = useMemo(
    () => [
      
      {
        header: props.t("name"),
        accessorKey: "name",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("code"),
        accessorKey: "code",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("parent"),
        accessorKey: "wards",
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{(cell.row.original.parent != null )? cell.row.original.parent.name : ''}</span>
        }
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => { const categoryData = cellProps.row.original; handleSelectBed(categoryData); }}
                    >
                      <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                    </Link>
                  </li>
                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const categoryData = cellProps.row.original; onClickDelete(categoryData); }}
                    >
                      <img src="../assets/images/delete.png" alt=""></img>
                    </Link>
                  </li>
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectBed]
  );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCategory} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                          <BreadCrumb title={props.t("categories")} pageTitle={props.t("settings")} />
                          </div>
                          <div className="col-sm-auto new-mode-btn">
                            <SinpleActionButton title={props.t("create_new_category")} img="../assets/images/user.png" action={handleOpen} />
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((categories && categories.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ categories && categories.size }
                                    tablePage={ categories && categories.page }
                                    tableTotal= { categories && categories.total_items }
                                    tableTotalPage= { categories && categories.total_page }
                                    onNextPage= {loadCategories}
                                    onSearch= {searchBeds}
                                    SearchPlaceholder={props.t("search_category")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_category") : props.t("create_new_category")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="name-field"
                                      className="form-label"
                                    >
                                      {props.t("name")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="name"
                                      id="name-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("name")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name && validation.errors.name ? true : false
                                      }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                      <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="code-field"
                                      className="form-label"
                                    >
                                      {props.t("code")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="code"
                                      id="code-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("code")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.code || ""}
                                      invalid={
                                        validation.touched.code && validation.errors.code ? true : false
                                      }
                                    />
                                    {validation.touched.code && validation.errors.code ? (
                                      <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className='mb-3'>
                                    <Label
                                      htmlFor="parentId-field"
                                      className="form-label"
                                    >
                                      {props.t("parent")}
                                    </Label>
                                    <Select
                                      name='parentId'
                                      id='parentId-field'
                                      value={selectedCategories}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectWard(e);
                                      }}
                                      options={categoriesOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <div>
                                        <Label htmlFor="description-field" className="form-label">
                                            {props.t("description")}
                                        </Label>
                                        <textarea 
                                            name="description"
                                            className="form-control" 
                                            id="description-field" 
                                            placeholder={props.t("enter_description")}
                                            rows={3}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.description || ""}
                                            invalid={
                                                validation.touched.description && validation.errors.description ? true : false
                                            }
                                        >
                                        </textarea>
                                    </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setselectedCategories(null); setCurrentCategory(null); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>

                      </Modal>
                  </div>
              </div>
          </div>
        </>
      }

    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Categories))