import React from 'react'
import { withTranslation  } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'

const Dashboard = (props) => {

  document.title = props.t("app_title")
  return (
    <>
        {/* Dashboard-cards Start */}
            <div className="dashboard">
                <div className="container-fluid ">
                    <div className="row bg-img">
                        <div className="patients-card">
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Total Patients</p>
                                    <h6>21,00</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Normal Patients</p>
                                    <h6>104</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Insurance Patients</p>
                                    <h6>24</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Staff Patients</p>
                                    <h6>15</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/first_aid.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Total Doctors</p>
                                    <h6>21</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Daily Patients</p>
                                    <h6>200</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Monthly Patients</p>
                                    <h6>2500</h6>
                                </div>
                            </div>
                            <div className="dashboard-card ">
                                <div className="patient-img">
                                    <img className="img-fluid" src="./assets/images/crutch.png" alt="" />
                                </div>

                                <div className="details">
                                    <p>Yearly Patients</p>
                                    <h6>1,50,000</h6>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            {/* Dashboard-cards End */}

            {/* table chart */}
            <div className="patient-activity mt-5 mb-3">
                <div className="container">
                    <div className="row g-4 pt-4 px-4 ">
                        <div className="card table-card">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h6 className="py-3 px-4">Patient Activity <span className="mx-1"> <i
                                            className="fa fa-exclamation-circle  text-muted" aria-hidden="false"></i></span>
                                </h6>
                                <i className="fa fa-ellipsis px-4"></i>
                            </div>
                            <canvas id="patient"></canvas>
                        </div>
                    </div>
                </div>
            </div>
             {/* table chart  */}

             {/* Dashboard-Today-Appointment-Activity Start */}
            <div className="appointment py-2">
                <div className="container">
                    <div className="row g-4 pt-4 px-4 ">
                        <div className="card table-card">
                                <h4 className="py-3 px-3">Today Appointment Activity</h4>
                            <table className="table">
                                <thead className="table-light">
                                    <tr>
                                        <th>S.No. </th>
                                        <th>Patients Name</th>
                                        <th>Patients Code</th>
                                        <th>Mobile No.</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td className="tbl-content">
                                            <div className="img d-flex align-items-center">
                                                <img src="./assets/images/user-avatar.png" alt=""
                                                    className="img-fluid  me-2" />
                                                Harmonica Ginger
                                            </div>
                                        </td>
                                        <td>#012345</td>
                                        <td>+251-1234568</td>
                                        <td className="action d-flex m-0">
                                            <a href="opd-patient-details.html">
                                                <i className="fa fa-eye"></i>
                                            </a>
                                            <div className="t-bl mx-2"></div>
                                            <a href="opd-take-vitals.html">
                                                <p className="ms-1 ">
                                                    Take Vitals
                                                </p>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td className="tbl-content">
                                            <div className="img d-flex align-items-center">
                                                <img src="./assets/images/16.png" alt="" className="img-fluid  me-2" />
                                                Ronald Weasly
                                            </div>
                                        </td>
                                        <td>#012345</td>
                                        <td>+251-1234568</td>
                                        <td className="action d-flex">
                                            <a href="opd-patient-details.html">
                                                <i className="fa fa-eye"></i>

                                            </a>
                                            <div className="t-bl mx-2"></div>
                                            <a href="opd-take-vitals.html">
                                                <p className="ms-1 ">
                                                    Take Vitals
                                                </p>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td className="tbl-content">
                                            <div className="img d-flex align-items-center">
                                                <img src="./assets/images/16.png" alt="" className="img-fluid  me-2" />
                                                Ronald Weasly
                                            </div>
                                        </td>
                                        <td>#012345</td>
                                        <td>+251-1234568</td>
                                        <td className="action d-flex">
                                            <a href="opd-patient-details.html">
                                                <i className="fa fa-eye"></i>
                                            </a>
                                            <div className="t-bl mx-2"></div>
                                            <a href="opd-take-vitals.html">
                                                <p className="ms-1 ">
                                                    Take Vitals
                                                </p>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td className="tbl-content">
                                            <div className="img d-flex align-items-center">
                                                <img src="./assets/images/16.png" alt="" className="img-fluid  me-2" />
                                                Ronald Weasly
                                            </div>

                                        </td>
                                        <td>#012345</td>
                                        <td>+251-1234568</td>
                                        <td className="action d-flex">
                                            <a href="opd-patient-details.html">
                                                <i className="fa fa-eye"></i>

                                            </a>
                                            <div className="t-bl mx-2"></div>
                                            <a href="opd-take-vitals.html">
                                                <p className="ms-1 ">
                                                    Take Vitals
                                                </p>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td className="tbl-content">
                                            <div className="img d-flex align-items-center ">
                                                <img src="./assets/images/16.png" alt="" className="img-fluid  me-2 " />
                                                Ronald Weasly
                                            </div>
                                        </td>
                                        <td>#012345</td>
                                        <td>+251-1234568</td>
                                        <td className="action d-flex">
                                            <a href="opd-patient-details.html">
                                                <i className="fa fa-eye"></i>

                                            </a>
                                            <div className="t-bl mx-2"></div>
                                            <a href="opd-take-vitals.html">
                                                <p className="ms-1 ">
                                                    Take Vitals
                                                </p>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            {/* Dashboard-Today-Appointment-Activity End */}
    </>
  )
}

export default withRouter(withTranslation()(Dashboard));
